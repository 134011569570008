import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class CreateSessionDialogService {

  constructor(private t: TranslocoService) { }

  public getUnsavedChangesWarningDialogData() {
    return {
      data: {
        header: this.t.translate('createsessionlayout.warningdialog.unsavedchanges.header'),
        contents: [
          this.t.translate('createsessionlayout.warningdialog.unsavedchanges.create_session_data_loss'),
          this.t.translate('createsessionlayout.warningdialog.unsavedchanges.do_you_want_to_proceed')
        ],
        headerMarginBottom: '1rem',
        buttonMarginTop: '1.5rem'
      }
    };
  }

  public getCancelSessionWarningDialogData() {
    return {
      data: {
        header: this.t.translate('createsessionlayout.warningdialog.cancelsession.header'),
        contents: [
          this.t.translate('createsessionlayout.warningdialog.cancelsession.session_data_loss')
        ],
        headerMarginBottom: '1rem',
        buttonMarginTop: '1.5rem'
      }
    };
  }

  public getTestCenterWarningDialogData() {
    return {
      data: {
        header: this.t.translate('sessiondetails.warningdialog.testcenter.header'),
        contents: [
          this.t.translate('sessiondetails.warningdialog.testcenter.change_test_center'),
          this.t.translate('sessiondetails.warningdialog.testcenter.do_you_want_to_proceed')
        ],
        headerMarginBottom: '1rem',
        contentMarginBottom: '1rem',
        buttonMarginTop: '0.5rem'
      }
    };
  }

  public getExamLanguageWarningDialogData() {
    return {
      data: {
        header: this.t.translate('sessiondetails.warningdialog.examlanguage.header'),
        contents: [
          this.t.translate('sessiondetails.warningdialog.examlanguage.change_exam_language'),
          this.t.translate('sessiondetails.warningdialog.examlanguage.do_you_want_to_proceed')
        ],
        headerMarginBottom: '1rem',
        contentMarginBottom: '1rem',
        buttonMarginTop: '0.5rem'
      }
    };
  }
}
