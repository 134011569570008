import { Injectable } from '@angular/core';
import { AllowedRoles, ISessionScheduleList, SessionStatus, SessionStatusIds } from '../models/session';
import { TimeProviderService as TimeProviderServiceOld } from './time-provider.service';
import { TimeProviderService, UserDetailService } from '@certiport/login-library';

@Injectable({
  providedIn: 'root'
})
export class SessionValidationService {
  public validateLoggedInUser: boolean = true; //this will be set to false for mobile session confirm page as user is not logged in
  public minConfirmSessionMinutes: number = 0;
  public maxConfirmSessionMinutes: number = 0;
  public minCancellationLeadTime: number = 0;
  public minStartSessionLeadTime: number = 0;
  public maxStartSessionLateTime: number = 0;
  public maxEditTime: number = 0;

  constructor(
    private timeProviderOld: TimeProviderServiceOld,
    private timeProvider: TimeProviderService,
    private userDetailService: UserDetailService,
  ) { }


  shouldConfirmSessionBeDisabled(session: ISessionScheduleList) {
    if (session.sessionStatusId != SessionStatusIds.confirmSession) {
      return true;
    }

    const sessionDateTime: Date = session.startDateTime;

    const currentDateTimeUTC = this.timeProviderOld.UTCDateTime();
    const sessionStartDateTimeUTC = this.timeProviderOld.convertToUTCDateTime(sessionDateTime);
    const examConfirmMaxTime = new Date(sessionStartDateTimeUTC.getTime() - (this.maxConfirmSessionMinutes * 60000));
    const examConfirmMinTime = new Date(sessionStartDateTimeUTC.getTime() - (this.minConfirmSessionMinutes * 60000));

    if (this.validateLoggedInUser && !this.isLoggedInUserTheSessionProctor(session.proctorId)) {
      return true;
    }

    return (currentDateTimeUTC > examConfirmMinTime || currentDateTimeUTC < examConfirmMaxTime);
  }

  private isLoggedInUserTheSessionProctor(proctorId: number) {
    const userRoles = this.userDetailService.getUserDetail().userRoles;
    const loggedInUserId = this.userDetailService.getUserDetail().userId;
    const isLoggedInUserHasProctorRole = userRoles.includes(AllowedRoles.proctor);

    if (!isLoggedInUserHasProctorRole || loggedInUserId != proctorId) {
      return false;
    }

    return true;
  }

  shouldCancelSessionBeDisabled(session: ISessionScheduleList) {
    if (session.sessionStatus === SessionStatus.cancelled || session.sessionStatusId >= SessionStatusIds.inprogress)
      return true;

    const currentDateTimeUTC = new Date(this.timeProvider.now);
    const sessionStartDateTimeUTC = session.startDateTime;
    const examCancelMinTime = new Date(sessionStartDateTimeUTC.getTime() - (this.minCancellationLeadTime * 60000));

    return currentDateTimeUTC > examCancelMinTime
  }

  shouldStartSessionBeDisabled(session: ISessionScheduleList) {
    const sessionDateTime: Date = session.startDateTime;
    const sessionStatus: string = session.sessionStatus;

    if (this.validateLoggedInUser && !this.isLoggedInUserTheSessionProctor(session.proctorId)) {
      return true;
    }

    if (sessionStatus === SessionStatus.confirmed) {
      return !this.isInSessionStartWindow(sessionDateTime);
    }
    else {
      return true;
    }
  }

  isInSessionStartWindow(sessionDateTime: Date): boolean {

    const currentDateTimeUTC = this.timeProviderOld.UTCDateTime();
    const sessionStartDateTimeUTC = this.timeProviderOld.convertToUTCDateTime(sessionDateTime);

    const examStartMinTime = new Date(sessionStartDateTimeUTC.getTime() - (this.minStartSessionLeadTime * 60000));
    const examStartMaxTime = new Date(sessionStartDateTimeUTC.getTime() + (this.maxStartSessionLateTime * 60000));

    return (currentDateTimeUTC >= examStartMinTime && currentDateTimeUTC < examStartMaxTime);
  }

  shouldEditSessionBeDisabled(sessionStatus: string, sessionDateTime: Date) {

    const invaidStatusesForEdit: string[] = [SessionStatus.inprogress, SessionStatus.cancelled, SessionStatus.complete];

    if (invaidStatusesForEdit.includes(sessionStatus)) {
      return true;
    }
    else//check and make sure that the time is not within 60 minutes of start, if it is, edit session should be disabled
    {
      const currentDateTimeUTC = this.timeProviderOld.UTCDateTime();
      const sessionStartDateTimeUTC = this.timeProviderOld.convertToUTCDateTime(sessionDateTime);
      const examDisableTime = new Date(sessionStartDateTimeUTC.getTime() - (this.maxEditTime * 60000));

      return currentDateTimeUTC > examDisableTime
    }
  }

  shouldResumeSessionBeDisabled(session: ISessionScheduleList) {
    const sessionStatus: string = session.sessionStatus;

    if (!this.isLoggedInUserTheSessionProctor(session.proctorId)) {
      return true;
    }

    if (sessionStatus === SessionStatus.inprogress) {
      return false;
    }
    else
      return true;
  }
}
