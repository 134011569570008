import { HttpStatusCode } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { TopazDialogMaxSize, TopazTimepickerComponent } from '@pearsonvue/topaz-angular-ui';
import { TestCenterService } from '../../services/test-center.service';

import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Time } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import {
  ActionType,
  ExamGroupErrorCodes,
  ICreateSession,
  ICreateSessionOutput,
  IExam,
  IExamGroup,
  IExamLanguage,
  IExamSelectionInfo,
  IPaymentType,
  IProgram,
  ISettingsInfo,
  ITimeZone,
  IUser,
  IUserTestCenter,
  InsufficientPaymentOutput,
  PaymentErrorCodes,
  PaymentInput,
  PaymentInputExamSession,
  PaymentValidationType,
  Stepper
} from '@cbb/models';
import { LoginStorageTokens } from '@certiport/login-library';
import { faArrowLeftLong, faCaretDown, faPlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { LoaderService, SidenavService, CtpDatePipe } from 'certiport-layout-library';
import { add, format } from 'date-fns';
import { EMPTY, Observable, Subscription, catchError, forkJoin, of, pairwise } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { DateFormats } from '../../app.constants';
import { CanComponentDeactivate } from '../../guards/can-deactivate.guard';
import { IOutage, IOutageInfo, ITimeConversionDto, ITimeConversionInput } from '../../models/outage';
import { ExamProductService } from '../../services/exam-product.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { OutagesService } from '../../services/outages.service';
import { PaymentService } from '../../services/payment.service';
import { SessionService } from '../../services/session.service';
import { CreateSessionWarningDialogComponent } from '../create-session/create-session-warning-dialog/create-session-warning-dialog.component';
import { CommonmethodsService } from './create-session-services/commonmethods.service';
import { CreateSessionDialogService } from './create-session-services/create-session-dialog.service';
import { LanguageComponent } from './language/language.component';
import { CreatesessiontoastService } from './create-session-services/createsessiontoast.service';
import { TimeSelectionComponent } from './exam-date-time-region/time-selection/time-selection.component';
import { OutageLogicService } from 'src/app/services/outage-logic.service';
import { SchedulingErrorCodeService } from 'src/app/services/scheduling-error-code.service';
import { IExpiredSiteLicense } from '../../models/expired-sitelicense';
import { PaymentTypesConstants } from 'src/app/payment-type-constants';
import { enUS } from 'date-fns/locale';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';
import { ExamDateTimeRegionComponent } from './exam-date-time-region/exam-date-time-region.component';
import { PaymentDefinitionComponent } from './payment-definition/payment-definition.component';

@Component({
  selector: 'app-create-session',
  templateUrl: './create-session.component.html',
  styleUrls: ['./create-session.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true },
    }
  ]
})
export class CreateSessionComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('timepicker') timepicker!: TopazTimepickerComponent;
  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild(ExamDateTimeRegionComponent) dateTimeComponent!: ExamDateTimeRegionComponent;
  @ViewChild(LanguageComponent) languageComponent!: LanguageComponent;

  Stepper = Stepper;
  ActionType = ActionType;
  activeStepper = Stepper.SessionDetails;
  stepperOrientation!: Observable<StepperOrientation>;
  readonly icons = { faArrowLeftLong, faPlus, faTrashCan, faCaretDown };

  loggedInUserId!: number;
  selectedTestCenter?: IUserTestCenter;

  testCenters$!: Observable<IUserTestCenter[]>;
  testCenters: IUserTestCenter[] = [];
  filterTestCenters: Observable<IUserTestCenter[]> | undefined;
  programs: IProgram[] = [];
  minCandidateCount: number = 0;
  maxCandidateCount: number = 50;
  get maxHours(): number { return Math.floor(+this.settingsCacheService.minScheduleLeadTime / 60) }; 
  settingsLoaded: boolean = false;
  private settingsCacheServiceSubscription: Subscription | null = null;

  isTouchUi = false;
  is24hrFormat = false;
  proctors: IUser[] = [];
  paymentMethods: IPaymentType[] = [];
  allPaymentMethods: IPaymentType[] = [];
  filteredproctors: Observable<IUser[]> | undefined;
  isUserAssignedPaymentSelected: boolean = false;


  examGroup: (IExamGroup | null) = null;

  subscriptions: Subscription[] = [];

  // This is a strongly typed form. This also makes interpolation on template work.
  sessionForm = this.fb.group<{
    sessionName: FormControl<string | null>,
    testCenter: FormControl<IUserTestCenter | null>,
    paymentMethod: FormControl<IPaymentType | null>,
    examGroupId: FormControl<string | null>,
    examLanguage: FormControl<IExamLanguage | null>,
    proctor: FormControl<IUser | null>,
    date: FormControl<string | null>,
    timeZone: FormControl<ITimeZone | null>,
    timepicker: FormControl<Time | null>
  }>({
    sessionName: this.fb.control<string | null>(null, {
      validators: [Validators.required, Validators.maxLength(250)]
    }),
    testCenter: this.fb.control<IUserTestCenter | null>(null, {
      validators: [this.autocompleteObjectValidator(), Validators.required]
    }),
    paymentMethod: this.fb.control<IPaymentType | null>({ value: null, disabled: true }),
    examGroupId: this.fb.control<string | null>({ value: '', disabled: true }),
    examLanguage: this.fb.control<IExamLanguage | null>({ value: null, disabled: true }, { validators: [this.autocompleteObjectValidator()] }),
    proctor: this.fb.control<IUser | null>({ value: null, disabled: true }, { validators: [this.autocompleteObjectValidator()] }),
    date: this.fb.control<string | null>({ value: null, disabled: true }),
    timeZone: this.fb.control<ITimeZone | null>({ value: null, disabled: true }, { validators: [this.autocompleteObjectValidator()] }),
    timepicker: this.fb.control<Time | null>({ value: null, disabled: true })
  });

  get maxDays(): number { return this.settingsCacheService.maxScheduleLeadTime; }
  examFormStructure = {
    examSelection: [null, [Validators.required]],
    numCandidates: [null, this.getNumCandidatesValidators()]
  };
  examsForm = this.fb.group({
    examForms: this.fb.nonNullable.array([this.fb.group({ ...this.examFormStructure })])
  });

  selectedStepIndex = 0;
  selectedExamTabIndex = 0;
  get maxExams() { return this.settingsCacheService.maxExamsPerSession };
  isOutages: boolean = false;
  outageInfo: IOutageInfo = {
    outages: [],
    whitelist: []
  };

  public allProgramsAndExams!: { exams: IExam[], programs: IProgram[] };
  isSessionSubmitted = false;
  isAddExamDisable = false;
  form: any;
  constructor(
    breakpointObserver: BreakpointObserver,
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private t: TranslocoService,
    private settingsCacheService: SettingsCacheService,
    private sessionService: SessionService,
    private examProductService: ExamProductService,
    private testCenterApiService: TestCenterService,
    private paymentService: PaymentService,
    private loaderService: LoaderService,
    private localStorageService: LocalStorageService,
    private outageLogic: OutageLogicService,
    private outageService: OutagesService,
    private createsessiontoastService: CreatesessiontoastService,
    private commonmethodsService: CommonmethodsService,
    private createSessionDialogService: CreateSessionDialogService,
    private sidenavService: SidenavService,
    private schedulingErrorCodeService: SchedulingErrorCodeService,
    private ctpDatePipe: CtpDatePipe
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 37.5rem)')
      .pipe(
        tap(matches => this.isTouchUi = !matches.matches),
        map(({ matches }) => (matches ? 'horizontal' : 'vertical'))
      );
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.isLoggedIn() && this.loggedInUserId > 0 && (this.sessionForm.dirty || this.examForms.dirty)) {
      return this.dialog.open(CreateSessionWarningDialogComponent, this.createSessionDialogService.getUnsavedChangesWarningDialogData())
        .afterClosed()
        .pipe(filter(value => value?.toLowerCase() === 'yes'));
    }

    return true;
  }

  private isLoggedIn(): boolean {
    if (this.localStorageService.getItem(LoginStorageTokens.Refresh)) {
      return true;
    }
    return false;
  }

  ngOnInit(): void {
    //This is currently depending on the load method being called elsewhere.
    this.settingsCacheServiceSubscription = this.settingsCacheService.load().pipe(
      map(loaded => {
        this.loaderService.hideLoader();
        this.settingsLoaded = loaded;
        this.initWithSettings();
      }),
      catchError(error => {
        this.loaderService.hideLoader();
        throw error;
      })
    ).subscribe();

    this.initSidenav();
    this.subscribeToSidenav();

    this.loggedInUserId = this.commonmethodsService.getLoggedInUserId();
    this.testCenters$ = this.getTestCenters();
    this.getPaymentTypes();
    this.getOutageInfo();

    this.testCenters$
      .pipe(switchMap(() => this.paymentService.getPaymentTypes()))
      .subscribe((paymentTypes: IPaymentType[]) => {
        this.allPaymentMethods = paymentTypes;
        this.getPaymentMethods(this.getControl('testCenter')?.value?.testCenterID!);
      });
    catchError(error => {
      if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
        this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
      }
      return EMPTY
    });
    this.subscribeTestCenterValueChanges();
    this.subscribeSessionNameValueChanges();
    this.subscribeExamLanguageValueChanges();
  }
  private initWithSettings(): void {
    this.minCandidateCount = this.settingsCacheService.minCandidateCount;
    this.maxCandidateCount = this.settingsCacheService.maxCandidateCount;
  }

  initSidenav() {
    this.sidenavService.sidenavSubject
      .next({
        heading: 'createsessionlayout.header.create_session',
        buttonName: '',
        showArrow: true
      });
  }

  subscribeToSidenav() {
    this.subscriptions.push(this.sidenavService
      .arrowClickSubject
      .subscribe(_ => this.onBack()));
  }

  private getTestCenters(): Observable<IUserTestCenter[]> {
    return this.testCenterApiService.getTestCentersForUser(this.loggedInUserId)
      .pipe(
        map((testCenters: IUserTestCenter[]) => {
          if (!testCenters?.length) {
            // TODO: Format below messages for transloco
            this.createsessiontoastService.showGenericErrorAndLogToConsole('Invalid testCenters return: ' + JSON.stringify(testCenters));
          }
          else if (testCenters.length === 1) {
            this.sessionForm.get('testCenter')?.patchValue(testCenters[0]);
          }
          this.InvokeTestCenterSearchFilter();
          this.testCenters = testCenters;
          return testCenters;
        }),
        catchError(error => {
          if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
            this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
          }
          else {
            // TODO: Format below messages for transloco
            this.createsessiontoastService.showGenericErrorAndLogToConsole('Failed to get test centers. ' + JSON.stringify(error));
          }
          return [];
        })
      );
  }

  private getPaymentTypes(): void {
    const paymentTypes$ = this.testCenters$.pipe(
      switchMap(() => this.paymentService.getPaymentTypes())
    ).subscribe((paymentTypes: IPaymentType[]) => {
      this.allPaymentMethods = paymentTypes;
      this.getPaymentMethods(this.sessionForm.value.testCenter?.testCenterID!);
    });
    this.addToSubscriptions(paymentTypes$);
    catchError(error => {
      if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
        this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
      }
      return EMPTY
    });


  }

  private checkPayment(): Observable<boolean> {
    const checkPaymentData = this.buildCheckPaymentData();

    return this.paymentService.checkPayment<boolean>(checkPaymentData).pipe(
      catchError(error => {
        if (error.status === HttpStatusCode.Forbidden || error.status === HttpStatusCode.Conflict) {
          if (error?.error?.portalError?.code?.toUpperCase() == PaymentErrorCodes.InsufficientPayment) {
            // build insufficient payment data
            const insufficientPaymentData = [] as InsufficientPaymentOutput[];
            checkPaymentData.examSessions.forEach(x => {
              insufficientPaymentData.push({
                examSeriesCode: x.examSeriesCode,
                examDescription: x.examName
              } as InsufficientPaymentOutput);
            });
            // show toast
            this.createsessiontoastService.showInsufficientPaymentToast(this.sessionForm.value.paymentMethod?.displayName!, PaymentValidationType.CheckPayment, insufficientPaymentData);
          }
          else if (error?.error?.portalError?.code?.toUpperCase() == PaymentErrorCodes.ExpiredSiteLicenseSLA) {
            const expiredSiteLicense = error?.error?.portalError?.message as IExpiredSiteLicense[];
            // show toast
            this.createsessiontoastService.showExpiredSiteLicenseToast(expiredSiteLicense);
          }
          else {
            // build insufficient payment data
            const insufficientPaymentData = [] as InsufficientPaymentOutput[];
            checkPaymentData.examSessions.forEach(x => {
              insufficientPaymentData.push({
                examSeriesCode: x.examSeriesCode,
                examDescription: x.examName
              } as InsufficientPaymentOutput);
            });
            // show toast
            this.createsessiontoastService.showInsufficientPaymentToast(this.sessionForm.value.paymentMethod?.displayName!, PaymentValidationType.CheckPayment, insufficientPaymentData);
          }
        }
        else if (error.status === HttpStatusCode.BadRequest) {
          if (error?.error?.portalError?.code.toUpperCase() == PaymentErrorCodes.SLANotAccepted) {
            const SlaProgramName = error?.error?.portalError?.message[0].programName;
            this.createsessiontoastService.slaNotAcceptedToast(SlaProgramName);
          }
        }
        else if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
          this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
        }
        else {
          this.createsessiontoastService.showGenericErrorAndLogToConsole('Failed to CheckPayment. Error: ' + JSON.stringify(error));
        }

        this.loaderService.hideLoader();
        this.isAddExamDisable = false;
        return of(false);
      }));
  }

  private buildCheckPaymentData(): PaymentInput {
    const sessionData = {} as PaymentInput;

    if (this.sessionForm.value.paymentMethod && this.sessionForm.value.testCenter && this.sessionForm.value.date) {

      // get time data
      const sessionDetails = this.sessionForm.getRawValue();
      const time = sessionDetails.timepicker as Time;
      const formattedTime = `${time.hours}:${time.minutes}`;

      // build initial session data
      sessionData.testCenterId = this.sessionForm.value.testCenter?.testCenterID;
      sessionData.startDate = format(Date.parse(sessionDetails.date!), DateFormats.MM_DD_YYYY);
      sessionData.startTime = formattedTime;
      sessionData.timeZoneId = sessionDetails.timeZone?.timeZoneId!;
      sessionData.paymentType = this.sessionForm.value.paymentMethod?.portalName;
      sessionData.examSessions = [] as PaymentInputExamSession[];

      // for each selected exam, add exam data
      const selectedExams = this.examForms.value;
      for (let i = 0; i < selectedExams.length; i++) {
        const exam = selectedExams[i].examSelection?.exam;
        const numCandidates = selectedExams[i].numCandidates;

        if (exam && (numCandidates || this.isUserAssignedPaymentSelected)) {
          sessionData.examSessions.push({
            examSeriesCode: exam.examSeriesCode,
            examName: exam.examName,
            candidateCount: numCandidates
          } as PaymentInputExamSession);
        }
      }
    }

    return sessionData;
  }

  private subscribeTestCenterValueChanges(): void {
    const testCenterChange$ = this.getControl('testCenter')?.valueChanges
      .pipe(startWith(''), pairwise())
      .subscribe(([prev, next]: [IUserTestCenter, IUserTestCenter]) => {
        this.toggleDisable();

        if (this.selectedTestCenter && next && next.testCenterID != undefined && this.selectedTestCenter.testCenterID !== next.testCenterID) {
          this.dialog.open(CreateSessionWarningDialogComponent, this.createSessionDialogService.getTestCenterWarningDialogData())
            .afterClosed()
            .subscribe((value: string) => {
              if (value && value.toLowerCase() === 'yes') {
                this.reset();
                this.populateProctorAndPaymentMethods(next);
                this.clearExamForms();
                this.commonmethodsService.testCenterChangeNotify();
                this.selectedTestCenter = next;
              } else {
                this.getControl('testCenter')?.reset();
                this.getControl('testCenter')?.setValue(this.selectedTestCenter);
              }
            });
        } else if (next) {
          if (next.testCenterID != undefined) {
            this.selectedTestCenter = next;
            this.populateProctorAndPaymentMethods(next);
          }
        }
      });

    this.addToSubscriptions(testCenterChange$);
  }

  private subscribeSessionNameValueChanges(): void {
    const sessionNameChange$ =
      this.getControl('sessionName')?.valueChanges.pipe(debounceTime(100), distinctUntilChanged())
        .subscribe(() => this.toggleDisable());

    this.addToSubscriptions(sessionNameChange$);
  }

  private subscribeExamLanguageValueChanges() {
    const examLanguageChange$ = this.commonmethodsService.loadExams$.subscribe(e => {
      this.initializeExamList();
    })

    this.addToSubscriptions(examLanguageChange$);
  }

  private clearExamForms() {
    this.examsForm = this.fb.group({
      examForms: this.fb.nonNullable.array([this.fb.group({ ...this.examFormStructure })])
    });
  }

  private initializeExamList() {
    this.subscriptions.push(
      this.getProgramsAndExams().subscribe((values) => {
        this.clearExamForms();
        this.allProgramsAndExams = values;
        this.CheckIsUserAssignedPaymentSelected();
      }));
  }

  private canScheduleSession(): Observable<{ canScheduleSession: boolean } | undefined> {
    if (this.getControl('date')?.valid && this.getControl('timeZone')?.valid && this.getControl('timepicker')?.valid) {
      const date = Date.parse(this.getControl('date')?.value!);
      const time = this.getControl('timepicker')?.value as Time;

      const formattedDate = format(date, DateFormats.MM_DD_YYYY, { locale: enUS });
      const formattedTime = `${time.hours}:${time.minutes}`;
      const timeZone = this.getControl('timeZone')?.value as ITimeZone;

      this.checkOutageInfo({ date: formattedDate, time: formattedTime, timeZoneId: timeZone.timeZoneId });

      return this.sessionService
        .canScheduleSession({ date: formattedDate, time: formattedTime, timeZoneId: timeZone.timeZoneId })
        .pipe(catchError((error) => {
          if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
            this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
          }
          else {
            this.createsessiontoastService.showGenericErrorAndLogToConsole('Failed to check if canScheduleSession' + JSON.stringify(error));
          }
          this.loaderService.hideLoader();
          return EMPTY;
        }));
    }

    return EMPTY;
  }

  private getExamGroups(): Observable<IExamGroup[] | null> {
    const testCenter = this.getControl('testCenter')?.value as IUserTestCenter;
    const examGroupId = +(this.getControl('examGroupId')?.value ?? 0);

    if (testCenter && examGroupId > 0) {
      return this.testCenterApiService.getExamGroup(testCenter.testCenterID, examGroupId)
        .pipe(
          catchError(err => {
            if (err?.status === HttpStatusCode.NotFound) {
              if (err?.error?.errorCode) {
                this.getControl('examGroupId')?.setErrors(err?.error?.errorCode == ExamGroupErrorCodes.NotAssociated ? { notAssociated: true } : { notFound: true });
              } else {
                this.getControl('examGroupId')?.setErrors({ notFound: true });
              }
            }
            else if (err?.status >= HttpStatusCode.InternalServerError && err?.error?.errorCode) {
              this.schedulingErrorCodeService.showErrorCodeToast(err?.error?.errorCode, err?.error?.traceId);
            }
            else {
              this.createsessiontoastService.showGenericErrorAndLogToConsole('ExamGroupInfo was invalid: ' + JSON.stringify(err));
            }
            return of(null);
          })
        );
    }
    return of([{} as IExamGroup]);
  }

  private populateProctorAndPaymentMethods(testCenter: IUserTestCenter) {
    this.selectedTestCenter = testCenter;
    this.getTestCenterProctors(testCenter.testCenterID);
    this.getPaymentMethods(testCenter.testCenterID);
  }

  private getPaymentMethods(testCenterId: number): void {
    this.paymentMethods = [];

    if (isNaN(testCenterId)) {
      return;
    }

    const testCenterInfo$ = this.testCenterApiService.getTestCenterInfo(testCenterId)
      .pipe(catchError((error) => {
        // TODO: Translate the message using Transloco
        if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
          this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
        }
        else {
          this.createsessiontoastService.showGenericErrorAndLogToConsole('Failed to get test center info for payment method determination. ' + JSON.stringify(error));
        }
        return EMPTY;
      }))
      .subscribe((testCenterInfo) => {
        if (!testCenterInfo) {
          this.createsessiontoastService.showGenericErrorAndLogToConsole('Invalid testCenterInfo return: ' + JSON.stringify(testCenterInfo));
        }

        this.paymentMethods = this.allPaymentMethods.filter(p => testCenterInfo?.hasUserLicenses ? true : p.paymentTypeId !== 3);
      });

    this.addToSubscriptions(testCenterInfo$);
  }

  private getTestCenterProctors(testCenterId: number): void {
    const sortByProctorLastName = (a: IUser, b: IUser) => (a?.lastName?.localeCompare(b?.lastName));
    const proctors$ = this.testCenterApiService.getTestCenterProctors(testCenterId)
      .pipe(
        map((unsortedProctors: IUser[]) => (unsortedProctors?.sort(sortByProctorLastName))),
        catchError((error) => {
          if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
            this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
          }
          else {
            this.createsessiontoastService.showGenericErrorAndLogToConsole('Failed to get test centers for proctor. ' + JSON.stringify(error));
          }
          return of([]);
        }))
      .subscribe((sortedProctors: IUser[]) => {
        if (!sortedProctors || sortedProctors.length === 0) {
          this.createsessiontoastService.showGenericErrorAndLogToConsole(
            {
              headerTag: 'createsession.sessiondetails.noProctorAssociatedHeader',
              contentTag: 'createsession.sessiondetails.noProctorAssociatedContent'
            }
          );
        } else if (sortedProctors.length === 1) {
          this.getControl('proctor')?.patchValue(sortedProctors[0]);
        }

        this.proctors = sortedProctors;

        this.InvokeProctorSearchFilter();
      });

    this.addToSubscriptions(proctors$);
  }

  private toggleDisable() {
    const isValid = (this.sessionForm.get('sessionName')?.valid && this.sessionForm.get('testCenter')?.value?.testCenterID) ?? false;

    this.filterControls(['sessionName', 'testCenter'], false)
      .forEach(f => isValid ? f.enable() : f.disable());
    this.getControl('paymentMethod')?.markAsPristine();
    this.commonmethodsService.markTimepickerNotify(isValid ? true : false, '');
  }

  private reset(): void {
    this.filterControls(['sessionName', 'testCenter', 'timeZone'], false).forEach(f => f.reset());
    this.commonmethodsService.markTimepickerNotify(false, 'reset');
  }

  private filterControls(names: string[], include: boolean = true): FormControl[] {
    const controls: any[] = [];
    const controlNames: string[] = [];

    Object.keys(this.sessionForm.controls).forEach(key => controlNames.push(key));

    controlNames
      .filter(n => include ? names.includes(n) : !names.includes(n))
      .forEach(f => controls.push(this.getControl(f)));

    return controls;
  }

  onStep(stepper: Stepper, actionType: ActionType): void {
    switch (stepper) {
      case Stepper.SessionDetails:
        if (actionType === ActionType.Next) {
          let isSessionFormValid = this.sessionForm.valid;

          if (isSessionFormValid) {
            this.loaderService.showLoader();
            this.CheckIsUserAssignedPaymentSelected();
            this.subscriptions.push(
              forkJoin({ scheduleSession: this.canScheduleSession(), examGroup: this.getExamGroups() })
                .pipe(
                  catchError(this.createsessiontoastService.genericErrorToast),
                  map(({ scheduleSession, examGroup }) => {
                    const canScheduleSession = scheduleSession?.canScheduleSession;
                    this.examGroup = examGroup?.at(0) ? examGroup.at(0)! : null;

                    if (!canScheduleSession) {
                      this.createsessiontoastService.openSessionDateTimeToast(this.maxHours);
                      isSessionFormValid = false;
                    } else {
                      isSessionFormValid &&= canScheduleSession;
                    }
                    if (this.isOutages) {
                      isSessionFormValid = false;
                      this.openSystemOutagesToast();
                    }
                    // Checks if examGroup is not null
                    isSessionFormValid &&= this.examGroup !== null;

                    if (this.sessionForm.get('timepicker')?.enabled) {
                      this.commonmethodsService.markTimepickerNotify(false, 'touched');
                    }

                    if (isSessionFormValid) {
                      this.stepper.next();
                      this.activeStepper = Stepper.ExamDetails;
                    }
                    this.loaderService.hideLoader();
                    return isSessionFormValid;
                  })
                ).subscribe()
            );
          } else {
            this.sessionForm.markAllAsTouched();

            let timepicker = this.getControl('timepicker');
            if (timepicker?.enabled) {
              if (timepicker?.value == null) {
                this.dateTimeComponent?.timeSelectionComponent?.setError('invalid-format');
              }
              this.commonmethodsService.markTimepickerNotify(false, 'touched');
            }
          }
        }

        break;

      case Stepper.ExamDetails:
        if (actionType === ActionType.Next) {

          if (this.checkIfTotalNumOfCandidatesExceeded()) {
            this.createsessiontoastService.showCandidateMaxExceededToast(this.maxCandidateCount);
          } else if (!this.examsForm.valid) {
            this.createsessiontoastService.showAddAnotherExamWarnToast(this.t.translate('createsession.examdetails.incomplete_exam_selection_on_next_error'));
          } else {
            this.loaderService.showLoader();
            this.addToSubscriptions(this.checkPayment().subscribe(result => {
              if (result) {
                this.stepper.next();
                this.activeStepper = Stepper.ReviewAndSubmit;
                this.loaderService.hideLoader();
              }
            }));
          }
        } else if (actionType === ActionType.Previous) {
          this.stepper.previous();
          this.activeStepper = Stepper.SessionDetails;
        }

        break;

      case Stepper.ReviewAndSubmit:
        if (actionType === ActionType.Previous) {
          this.stepper.previous();
          this.activeStepper = Stepper.ExamDetails;
        }

        break;
    }
  }

  onBack(): void {
    this.navigateToDashboard();
  }

  onCancelSession(): void {
    const warningDialogSubscription = this.dialog
      .open(CreateSessionWarningDialogComponent, this.createSessionDialogService.getCancelSessionWarningDialogData())
      .afterClosed()
      .pipe(filter(value => !!value && value.toLowerCase() === 'yes'))
      .subscribe(_ => {
        this.markFormAsPristine();
        this.navigateToDashboard();
      });

    this.addToSubscriptions(warningDialogSubscription);
  }

  private navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

  onSubmit(): void {
    if (this.sessionForm.valid && this.examsForm.valid) {
      this.isSessionSubmitted = true;
      this.loaderService.showLoader();
      const createSession = this.buildCreateSession();
      const saveSessionSubscription = this.sessionService.saveSession(createSession)
        .pipe(
          catchError((error) => {
            if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
              this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
            }
            else if ((error?.status === HttpStatusCode.Forbidden || error?.status === HttpStatusCode.Conflict) && error?.error?.portalError?.code?.toUpperCase() === PaymentErrorCodes.InsufficientPayment) {
              this.handleInsufficientPayment(error, createSession);
            }
            else if ((error?.status === HttpStatusCode.Forbidden || error?.status === HttpStatusCode.Conflict) && error?.error?.portalError?.code.toUpperCase() == PaymentErrorCodes.ExpiredSiteLicenseSLA) {
              const expiredSiteLicense = error?.error?.portalError?.message as IExpiredSiteLicense[];
              this.createsessiontoastService.showExpiredSiteLicenseToast(expiredSiteLicense);
            }
            else if ((error?.status === HttpStatusCode.Forbidden || error?.status === HttpStatusCode.Conflict) && error?.error?.portalError?.code.toUpperCase() == PaymentErrorCodes.InvalidSessionMatch) {
              this.createsessiontoastService.genericServerErrorToast();
            }
            else
              this.createsessiontoastService.genericErrorToast(error);
            this.isSessionSubmitted = false;
            this.loaderService.hideLoader();
            return EMPTY;
          })
        )
        .subscribe((createSessionOutput: (ICreateSessionOutput | undefined)) => {
          if (createSessionOutput) {
            this.storeSession(createSessionOutput);
            this.markFormAsPristine();
            this.router.navigate(['/session-confirmation']);
          }
          this.loaderService.hideLoader();
        });

      this.addToSubscriptions(saveSessionSubscription);
    }
  }

  private buildCreateSession(): ICreateSession {
    const sessionDetails = this.sessionForm.getRawValue();
    const exams = this.examsForm.getRawValue();
    const time = sessionDetails.timepicker as Time;

    return {
      sessionName: sessionDetails.sessionName!,
      testCenterId: sessionDetails.testCenter?.testCenterID!,
      testCenterName: sessionDetails.testCenter?.testCenterName!,
      paymentMethodId: sessionDetails.paymentMethod?.paymentTypeId!,
      paymentMethodName: sessionDetails.paymentMethod?.displayName!,
      examGroupId: +sessionDetails.examGroupId!,
      examGroupName: this.examGroup?.examGroupName ?? '',
      languageCode: sessionDetails.examLanguage?.languageCode!,
      proctorId: sessionDetails.proctor?.userID!,
      startDate: format(Date.parse(sessionDetails.date!), DateFormats.MM_DD_YYYY, { locale: enUS }),
      timeZoneID: sessionDetails.timeZone?.timeZoneId!,
      startTime: `${time.hours}:${time.minutes}`,
      creatorId: this.loggedInUserId,
      creatorEmail: '',
      sessionExams: exams.examForms!.map(e => {
        const exam = (e.examSelection! as IExamSelectionInfo)?.exam! as IExam;

        return {
          examName: exam.examName,
          seriesCode: exam.examSeriesCode,
          duration: exam.examDuration,
          directToITS: exam.directToITS,
          programId: exam.programID,
          autoDeskVersion: exam.autoDeskVersion,
          candidateCount: +e.numCandidates!,
        };
      })
    } as ICreateSession;
  }

  private handleInsufficientPayment(error: any, createSessionData: any): void {
    const e = error?.error;
    const insufficientPayments: InsufficientPaymentOutput[] = [];
    const paymentMethodName = this.sessionForm.value.paymentMethod?.displayName!;
    for (let i = 0; i < createSessionData.sessionExams.length; i++) {
      const examSeriesCode = createSessionData.sessionExams[i].seriesCode;
      const examName = createSessionData.sessionExams[i].examName;
      if (examSeriesCode && examName) {
        insufficientPayments.push({
          examDescription: examName,
          examSeriesCode: examSeriesCode
        });
      }
    }
    catchError((error) => {
      if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
        this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
      }
      return EMPTY
    });
    this.createsessiontoastService.showInsufficientPaymentToast(paymentMethodName, PaymentValidationType.LockPayment, insufficientPayments);
  }

  private storeSession(createSessionOutput: ICreateSessionOutput): void {
    localStorage.setItem('sessionId', createSessionOutput.sessionId.toString());
  }

  private markFormAsPristine(): void {
    this.sessionForm.markAsPristine();
    this.examForms.markAsPristine();
  }

  onExamAdd(tabNo: number): void {
    this.isAddExamDisable = true;
    this.loaderService.showLoader();
    if (this.checkIfTotalNumOfCandidatesExceeded()) {
      this.loaderService.hideLoader();
      this.isAddExamDisable = false;
      this.createsessiontoastService.showCandidateMaxExceededToast(this.maxCandidateCount);
      return;
    }

    if (tabNo >= this.maxExams) {
      this.loaderService.hideLoader();
      this.isAddExamDisable = false;
      this.createsessiontoastService.showWarnToast([this.t.translate('createsession.examdetails.exam_max_exceeded', { maxExams: this.maxExams })]);
      return;
    }

    if (!this.examsForm.valid) {
      this.loaderService.hideLoader();
      this.isAddExamDisable = false;
      this.createsessiontoastService.showAddAnotherExamWarnToast(this.t.translate('createsession.examdetails.complete_all_exam_details_error'));
      return;
    }

    this.addToSubscriptions(this.checkPayment().subscribe(result => {
      if (result) {
        this.loaderService.hideLoader();
        this.isAddExamDisable = false;
        this.selectedExamTabIndex = tabNo;
        this.examFormStructure = {
          examSelection: [null, [Validators.required]],
          numCandidates: [null, this.getNumCandidatesValidators()]
        };
        this.examForms.push(this.fb.group({ ...this.examFormStructure }));
        this.CheckIsUserAssignedPaymentSelected();
        this.createsessiontoastService.showSuccessToast([this.t.translate('createsession.examdetails.exam_details_added', { examNo: tabNo })]);
      }
    }));
  }

  private checkIfTotalNumOfCandidatesExceeded() {
    if (this.isUserAssignedPaymentSelected)
      return false;
    return this.examsForm.getRawValue().examForms
      .reduce((total, e) => (+e.numCandidates! + total), 0) > this.maxCandidateCount;
  }

  onExamRemove(tabNo: number): void {
    this.dialog.open(CreateSessionWarningDialogComponent, this.createsessiontoastService.getRemoveExamWarningDialogData())
      .afterClosed()
      .pipe(filter(value => value?.toLowerCase() === 'yes'))
      .subscribe(_ => this.examForms.removeAt(tabNo));
  }

  private getProgramsAndExams(): Observable<{ exams: IExam[], programs: IProgram[] }> {
    return forkJoin({
      exams: this.examProductService.getExams(this.getControl('examLanguage')?.value?.languageCode!),
      programs: this.getPrograms()
    });
  }

  private getPrograms(): Observable<IProgram[]> {
    const sortProgramsByName = (a: IProgram, b: IProgram) => a.programName.localeCompare(b.programName);

    return this.programs.length > 0
      ? of(this.programs)
      : this.examProductService.getPrograms()
        .pipe(map(programs => programs.sort(sortProgramsByName)));
  }

  onSessionEdit(): void {
    this.stepper.previous();
    this.stepper.previous();
    this.activeStepper = Stepper.SessionDetails;
  }

  onExamEdit(examTab: number): void {
    this.stepper.previous();
    this.selectedExamTabIndex = examTab;
    this.activeStepper = Stepper.ExamDetails;
  }

  onReview(): void {
    if (!this.examsForm.valid) {
      this.createsessiontoastService.showAddAnotherExamWarnToast(this.t.translate('createsession.examdetails.complete_all_exam_details_error'));
    }
  }

  getControl(name: string): AbstractControl | null {
    return this.sessionForm.get(name);
  }

  get examForms(): FormArray {
    return this.examsForm.get('examForms') as FormArray;
  }

  private openSystemOutagesToast() {
    this.createsessiontoastService.showGenericErrorAndLogToConsole(
      {
        headerTag: 'createsession.outages.header',
        contentTag: 'createsession.outages.content'
      }
    );
  }

  private addToSubscriptions(subscription: (Subscription | undefined)) {
    if (subscription) {
      this.subscriptions.push(subscription);
    }
  }

  private getOutageInfo(): void {
    this.outageService.getOutageInfo()
      .pipe(catchError((error) => {
        if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
          this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
        }
        else {
          this.createsessiontoastService.genericErrorToast(error);
        }
        return EMPTY;
      }))
      .subscribe((response) => this.outageInfo = response);
  }

  private checkOutageInfo(timeConversionInput: ITimeConversionInput): void {
    const testCenterId: number = this.getControl('testCenter')?.value?.testCenterID!;
    this.outageLogic.isSessionTimeAllowed(timeConversionInput, testCenterId, this.outageInfo).subscribe({
      next: (allowed) => {
        this.isOutages = !allowed;
      },
      error: (error) => {
        if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
          this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
        }
        else {
          this.createsessiontoastService.genericErrorToast(error);
        }
      }
    });
  }

  InvokeProctorSearchFilter() {
    this.filteredproctors = this.sessionForm.get('proctor')?.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' || value == null ? value : ((value as unknown) as IUser).firstName;
        return name ? this._filterProctors(name as string) : this.proctors.slice();
      }),
    );
  }

  private _filterProctors(value: string): IUser[] {
    const filterValue = value.toLowerCase();
    return this.proctors.filter(option => (option.firstName.toLowerCase().includes(filterValue) || option.lastName.toLowerCase().includes(filterValue)));
  }

  InvokeTestCenterSearchFilter() {
    this.filterTestCenters = this.sessionForm.get('testCenter')?.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' || value == null ? value : ((value as unknown) as IUserTestCenter).testCenterName;
        return name ? this._filterTestCenters(name as string) : this.testCenters.slice();
      }),
    );
  }

  private _filterTestCenters(value: string): IUserTestCenter[] {
    const filterValue = value.toLowerCase();
    return this.testCenters.filter(option => option.certiportID.includes(filterValue) || option.testCenterName.toLowerCase().includes(filterValue));
  }

  displayTestCenterName(testCenter: IUserTestCenter): string {
    return testCenter && testCenter.testCenterName ? testCenter.testCenterID + ' - ' + testCenter.testCenterName : '';
  }

  displayPaymentName(paymentType: IPaymentType): string {
    return paymentType && paymentType.displayName ? paymentType.displayName : '';
  }

  displayProctorName(user: IUser): string {
    return user && user.firstName ? user.firstName + ' ' + user.lastName : '';
  }

  private autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value != '' && typeof control.value === 'string') {
        return { 'invalidAutocompleteObject': { value: control.value } }
      }
      return null
    }
  }

  selectedExamChange() {
    const selectedExams = this.examForms.value;

    if (selectedExams[0].examSelection != null) {
      this.languageComponent.isExamSelected = true;
    }
  }

  CheckIsUserAssignedPaymentSelected() {
    let selectedPaymentType = this.sessionForm.value.paymentMethod?.displayName;
    this.isUserAssignedPaymentSelected = selectedPaymentType && selectedPaymentType.toLocaleLowerCase() == PaymentTypesConstants.USER_ASSIGNED_VOUCHER.toLocaleLowerCase() ? true : false;

    const formArray = this.examsForm.get('examForms') as FormArray;

    for (let i = 0; i < formArray.length; i++) {
      const examFormGroup = formArray.at(i) as FormGroup;
      const control = examFormGroup.get('numCandidates');
      if (this.isUserAssignedPaymentSelected) {
        control?.clearValidators();
        control?.setValue(null);
        control?.disable();
      }
      else {
        control?.enable();
        control?.setValidators([Validators.required, Validators.min(this.minCandidateCount), Validators.max(this.maxCandidateCount)]);
      }
      control?.updateValueAndValidity();
    }
  }

  getNumCandidatesValidators() {
    const validators = [];

    if (!this.isUserAssignedPaymentSelected) {
      validators.push(Validators.required, Validators.required, Validators.min(this.minCandidateCount), Validators.max(this.maxCandidateCount));
    }
    return validators;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s?.unsubscribe());
  }

  onStepClick() {
    if (this.stepper.selectedIndex == 0 && (this.activeStepper == Stepper.ExamDetails || this.activeStepper == Stepper.ReviewAndSubmit)) {
      this.activeStepper = Stepper.SessionDetails;
    }
    else if (this.stepper.selectedIndex == 1 && (this.activeStepper == Stepper.SessionDetails || this.activeStepper == Stepper.ReviewAndSubmit)) {
      this.activeStepper = Stepper.ExamDetails
    }
    else if (this.stepper.selectedIndex == 2 && (this.activeStepper == Stepper.SessionDetails || this.activeStepper == Stepper.ExamDetails)) {
      this.activeStepper = Stepper.ReviewAndSubmit;
    }
  }

  openPaymentDefinitionsDialog() {
    let maxWidth = '';
    if (window.innerWidth <= 1000) {
      maxWidth = (window.innerWidth * 0.9) + 'px';
    }
    else{
      maxWidth = TopazDialogMaxSize.MEDIUM;
    }

    const dialogRef = this.dialog.open(PaymentDefinitionComponent, {
      maxWidth: maxWidth
    });

    dialogRef.afterClosed().subscribe(() => {})
  }
}
