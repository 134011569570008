import { Component, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { ITimeZone } from '@cbb/models';
import { faCaretDown } from '@fortawesome/pro-regular-svg-icons';
import { TranslocoService } from '@jsverse/transloco';
import { catchError, EMPTY, Observable, startWith, map, take } from 'rxjs';
import { SessionService } from 'src/app/services/session.service';
import { CreatesessiontoastService } from '../../create-session-services/createsessiontoast.service';
import { HttpStatusCode } from '@angular/common/http';
import { SchedulingErrorCodeService } from 'src/app/services/scheduling-error-code.service';
@Component({
  selector: 'app-time-zone-selection',
  templateUrl: './time-zone-selection.component.html'
})
export class TimeZoneSelectionComponent implements OnInit {

  form!: FormGroup
  timeZones$!: Observable<ITimeZone[]>;
  timeZones: ITimeZone[] =[];
  filteredTimeZones: Observable<ITimeZone[]> | undefined;
  readonly icons = { faCaretDown };
  constructor(private t: TranslocoService, private rootFormGroup: FormGroupDirective,
    private sessionService: SessionService, private createsessiontoastService: CreatesessiontoastService, private schedulingErrorCodeService: SchedulingErrorCodeService) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
    this.timeZones$ = this.getTimeZones();
    this.timeZones$.subscribe(result=>{
      this.timeZones = result;
      this.InvokeTimeZoneFilter();
      this.setBrowserTimeZone();
    })
  }

  private getTimeZones(): Observable<ITimeZone[]> {
    return this.sessionService.getTimeZones()
      .pipe(catchError((error) => {
        if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
          this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
        }
        else
        this.createsessiontoastService.showGenericErrorAndLogToConsole('Failed to retrieve time zone data' + JSON.stringify(error));
        return EMPTY;
      }));
  }

  private InvokeTimeZoneFilter() {
    this.filteredTimeZones = this.form.get('timeZone')?.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' || value == null ? value : ((value as unknown) as ITimeZone).displayName;
        return name ? this._filterTimeZone(name as string) : this.timeZones.slice();
      }),
    );
  }

  private _filterTimeZone(value: string): ITimeZone[] {
    const filterValue = value.toLowerCase();
    return this.timeZones.filter(option => option.displayName.toLowerCase().includes(filterValue));
  }

  displayTimeZoneName(timeZone: ITimeZone): string {
    return timeZone && timeZone.displayName ? timeZone.timeZoneName : '';
  }

  private setBrowserTimeZone() {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.sessionService.getWindowsTimeZoneByBrowserTimeZone(browserTimezone).pipe(take(1)).subscribe({
      next: (response) => {
        if (this.timeZones.some(t => t.timeZoneId == response?.timeZoneId && t.displayName == response?.displayName)) {
          response.timeZoneName = this.removeOffsetsFromTimeZone(response.displayName);
          this.form.get('timeZone')?.setValue(response as ITimeZone);
        }
      },
      error: (errorResponse) => {
        return EMPTY;
      }
    });
  }

  removeOffsetsFromTimeZone(timeZoneDisplayName: string): string {
    //Regular expression to match offset in the format (UTC+/-HH:MM)
    const offsetRegex = /\(UTC[+-]\d{2}:\d{2}\)/;
    // Replace offset with an empty string
    timeZoneDisplayName = timeZoneDisplayName.replace(offsetRegex, '').trim();

    return timeZoneDisplayName;
  }
}
