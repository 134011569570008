<topaz-banner useIcon disableScrolling>
  <div banner-content *transloco="let t">
    <h5>
      {{t('expiringSLAPrograms.header')}}
    </h5>
    <ul class="expiringSLA-content">
      <li *ngFor="let expirations of slaExpirationsResponse?.expirations">
        {{t('expiringSLAPrograms.programname', { programName: expirations.programName,date: expirations.expirationDate | ctpDate:'MMMM d, y'})}}
      </li>
    </ul>
    <p class="expiringSLA-footer">{{ t('expiringSLAPrograms.footer') }}</p>
  </div>
</topaz-banner>
