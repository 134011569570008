<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" *transloco="let t;">
  <mat-tab *ngFor="let sessionExam of session.sessionExams; let i = index"
    label="{{t('editSessionExam.tabLabel')}} {{(i+1)}}">
    <div class="grid-container">
      <div [ngClass]="{'grid-col-span-2': examCount == 1 }">
        <mat-label class="mat-label">{{t('editSessionExam.examName')}}</mat-label><br>
        <div class="exam-name-container">
          <h4 class="exam-name-data">{{sessionExam.examName}} {{sessionExam.autodeskVersion}}</h4>
        </div>
      </div>
      <div *ngIf="examCount > 1">
        <button mat-button color="warn" class="remove-button"
          (click)="removeExam(sessionExam.sessionExamId)">{{t('editSessionExam.remove')}}</button>
      </div>
      <div class="candidate-count grid-col-span-2" *ngIf="!isUserAssignedPayment">
        <mat-form-field>
          <mat-label>{{t('editSessionExam.NoOfCandidates')}}</mat-label>
          <input type="number" numberOnly matInput [(ngModel)]="sessionExam.candidateCount" required
            #candidateCountControl="ngModel" [min]="minCandidateCount" [max]="maxCandidateCount">
          <mat-error *ngIf="candidateCountControl.errors?.['required'] || candidateCountControl.errors?.['min']">
            {{t('editSessionExam.candidateCountRequired')}}
          </mat-error>
          <mat-error *ngIf="candidateCountControl.errors?.['max']">
            {{t('editSessionExam.candidateCountMinMaxSave',{maxCandidateCount:maxCandidateCount })}}
          </mat-error>

        </mat-form-field>       
      </div>
      <div *ngIf="isUserAssignedPayment">
        <div>
          <mat-label class="mat-label">{{t('viewSessionExam.NoOfCandidates')}}
          <fa-icon
            *ngIf="isUserAssignedPayment"
            [icon]="icons.faInfoCircle"
            matIconSuffix
            matTooltip="{{ t('user-assigned-voucher-notes') }} {{ t('session_max_candidate') }}"
            matTooltipPosition="before"
          ></fa-icon></mat-label>
        </div>
        <div>
          <span>{{sessionExam.candidateCount}}</span>
        </div>
      </div>
      <div>
        <div>
          <mat-label class="mat-label">{{t('accessCode')}}</mat-label>
        </div>
        <div>
          <span>{{sessionExam.examCode}}</span>
        </div>
      </div>
      <div>
        <div>
          <mat-label class="mat-label">{{t('editSessionExam.examLength')}}</mat-label>
        </div>
        <div>
          <span>
            {{sessionExam.duration}} {{t('examDurationUnit')}}
          </span>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
<topaz-toaster></topaz-toaster>