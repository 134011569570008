import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class TopazWrapperService {

  constructor(private t: TranslocoService) { }

  translate(key: string, args?: object): string {
    return this.t.translate(key, args);
  }
}
