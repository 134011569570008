import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TopazComponentCDKPortal, TopazOverlayRef, TopazTimepickerComponent } from '@pearsonvue/topaz-angular-ui';
import { SessionService } from '../../../services/session.service';
import { faCalendar, faCaretDown } from '@fortawesome/pro-regular-svg-icons';
import { DatePipe, Time, ViewportScroller } from '@angular/common';
import { add, format } from 'date-fns';
import { TranslocoService } from '@jsverse/transloco';
import { AbstractControl, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { ITimeZone } from '../../../models/timezone';
import { ISession } from '../../../models/session';
import { ExamGroupErrorCodes, IUser } from '../../../models/portal';
import { TestCenterService } from 'src/app/services/test-center.service';
import { IBannerData } from '../../../models/bannerdata';
import { catchError, map, Observable, of, switchMap, Subscription, startWith } from 'rxjs';
import { LoaderService } from 'certiport-layout-library';
import { EditViewSidesheetService } from '../../../services/edit-view-sidesheet.service';
import { BannerEditSessionComponent } from '../banner-edit-session/banner-edit-session.component';
import { SessionStatusUpdateService } from '../../../services/session-status-update.service';
import { UserDetailService } from '@certiport/login-library';
import { DateFormats } from 'src/app/app.constants';
import { IOutageInfo, ITimeConversionInput } from '../../../models/outage';
import { OutagesService } from '../../../services/outages.service';
import { OutageLogicService } from 'src/app/services/outage-logic.service';
import { HttpStatusCode } from '@angular/common/http';
import { IExpiredSiteLicense } from 'src/app/models/expired-sitelicense';
import { PaymentErrorCodes } from '@cbb/models';
import { enUS } from 'date-fns/locale';
import { PaymentTypesConstants } from 'src/app/payment-type-constants';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';

@Component({
  selector: 'app-edit-session',
  templateUrl: './edit-session.component.html',
  styleUrls: ['./edit-session.component.scss']
})
export class EditSessionComponent implements OnInit, AfterViewInit {

  minDate: Date;
  maxDate: Date;
  sessionId!: number;
  is24hrFormat = false;
  examGroupErrorMsg = '';
  timePickerLabel = '';
  showBanner = false;
  examGroupIdValidationSuccess = true;
  proctors: IUser[] = [];
  filteredproctors: Observable<IUser[]> | undefined;
  timeZones: ITimeZone[] = [];
  filteredTimeZones: Observable<ITimeZone[]> | undefined;
  selectedTime = { hours: 0, minutes: 0 };
  bannerRefrence!: TopazComponentCDKPortal;
  session = { sessionName: "" } as ISession;
  settingsLoaded: boolean = false;

  @ViewChild(BannerEditSessionComponent) private bannerEditSession!: BannerEditSessionComponent

  @ViewChild('timePicker') timePicker!: TopazTimepickerComponent;
  icons = {
    faCalendar,faCaretDown
  };
  @Output() saveClick = new EventEmitter();
  isOutages: boolean = false;
  outageInfo: IOutageInfo = {
    outages: [],
    whitelist: [],
  };

  editReturnObj: any;

  constructor(private formBuilder: FormBuilder, private ref: TopazOverlayRef, private sessionService: SessionService,
    private settingsCache: SettingsCacheService,
    private testCenterApiService: TestCenterService,
    private t: TranslocoService,
    private loaderService: LoaderService,
    private editViewSidesheetService: EditViewSidesheetService,
    private userDetailService: UserDetailService,
    private sessionStatusUpdateService: SessionStatusUpdateService,
    private outageLogic: OutageLogicService,
    private outageService: OutagesService  ) {
    this.minDate = new Date();
    this.maxDate = this.minDate;
  }

  examCount!: number;
  isUserAssignedPayment: boolean = false;

  ngOnInit(): void {
    this.settingsCache.loaded.pipe(
      map(loaded => {
        this.settingsLoaded = loaded;
        if (loaded) {
          this.initialize();
        }
      })
    ).subscribe();
  }

  private initialize(): void {
    this.timePickerLabel = this.translate("editSession.time");
    this.sessionId = this.ref.data;

    this.loaderService.showLoader();
    this.getSession();
    this.getSettings();
    this.getOutageInfo();
  }

  ngAfterViewInit(): void {
    this.editViewSidesheetService.bannerEditSession = this.bannerEditSession;
  }

  ngOnDestroy(): void {
  }

  editSessionForm = this.formBuilder.group({
    sessionName: ['', [Validators.required, Validators.maxLength(250), this.validateValue.bind(this)]],
    examGroupId: ['', [this.validateExamGroupId.bind(this)]],
    proctor: this.formBuilder.control<IUser | null>({ value: null, disabled: false }, [this.autocompleteObjectValidator(), Validators.required]),//[0, [Validators.required, this.validateValue.bind(this)]],
    timeZone: this.formBuilder.control<ITimeZone | null>({ value: null, disabled: false }, [this.autocompleteObjectValidator(), Validators.required]),//[0, [Validators.required, this.validateValue.bind(this)]],
    date: [new Date(), [Validators.required, this.validateDate.bind(this)]],
    timePicker: [{ value: this.selectedTime }, Validators.required]
  });

  validateValue(control: AbstractControl): { [key: string]: boolean } | null {
    if (!control.value) {
      return { invalidValue: true };
    }
    return null;
  }

  validateDate(control: AbstractControl): { [key: string]: boolean } | null {

    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/[0-9]{4}$/;

    if (dateRegex.test(control.value) || control.value === null)
      return { invalidDateFormat: true };

    let date: Date = new Date();
    let currentDate = format(date, DateFormats.MM_DD_YYYY, { locale: enUS });

    if (Date.parse(control.value) < Date.parse(currentDate)) {
      return { isPastDate: true }
    }

    let numberOfDaysToAdd: number = 30;
    currentDate = format(date.setDate(date.getDate() + numberOfDaysToAdd), DateFormats.MM_DD_YYYY, {locale: enUS });

    if (Date.parse(control.value) > Date.parse(currentDate)) {
      return { isFutureDate: true }
    }

    return null;
  }

  validateExamGroupId(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value) {
      if (control.value.toString().length > 8) {
        return { maxLengthExceed: true };
      }
    }
    return null;
  }


  formControls = {
    sessionName: this.getControl('sessionName'),
    examGroupId: this.getControl('examGroupId'),
    proctor: this.getControl('proctor'),
    timeZone: this.getControl('timeZone'),
    date: this.getControl('date'),
    timePicker: this.getControl('timePicker')
  };

  getControl(name: string) {
    return this.editSessionForm.get(name);
  }

  private getSettings(): void {
    this.maxDate = add(Date.now(), { days: this.settingsCache.maxScheduleLeadTime });
    this.editViewSidesheetService.settingsLoaded = true;
  }

  getSession() {
    this.sessionService.getSession(this.sessionId).subscribe(
      (data: any) => {

        this.session = data;
        this.examCount = this.session.sessionExams.length;
        this.editSessionForm.patchValue({
          sessionName: this.session.sessionName,
          examGroupId: (this.session.examGroupId == 0) ? '' : this.session.examGroupId.toString(),
          date: this.session.startDateTimeDisplay,
        });
        this.getProctors();
        this.getTimeZones();

        //TODO: TECH DEBT: The start display time should be correct coming from the server and should not need adjustment.
        // However, when fixing this, note the side effect in setDateString (at time of writing).
        this.setDateString(this.session.startDateTimeDisplay)

        this.setTimePickerValue();

        this.editViewSidesheetService.editSessionobjModified = this.session;
        this.editViewSidesheetService.editSessionobjOriginal = JSON.parse(JSON.stringify(this.session));
        this.editViewSidesheetService.sessionDataLoaded = true;
        this.isUserAssignedPayment = this.session.paymentMethodName.toLocaleLowerCase() === PaymentTypesConstants.USER_ASSIGNED_VOUCHER.toLocaleLowerCase() ? true : false;
        this.editViewSidesheetService.hideLoader();
      },
      error => {
        this.editViewSidesheetService.hasError = true;
        this.editViewSidesheetService.hideLoader();
        this.onError(error);
      }
    );
  }

  getProctors() {
    this.testCenterApiService.getTestCenterProctors(this.session.testCenterId).subscribe(
      (data: any) => {
        this.proctors = data;

        var proctor = this.proctors.filter(p => p.userID == this.session.proctorId);
        if (proctor.length > 0) {
          let loggedInUserId = this.getLoggedInUserId();

          if (loggedInUserId == this.session.proctorId) {
            proctor[0].lastName = `${proctor[0].lastName} (${this.translate("editSession.you")})`
          }
          this.editSessionForm.patchValue({
            proctor: (proctor[0])  as IUser
          });
        }

        this.InvokeProctorSearchFilter(true, proctor[0]?.displayName);
        this.editViewSidesheetService.proctorsLoaded = true;
        this.editViewSidesheetService.hideLoader();
      },
      error => {
        this.editViewSidesheetService.hasError = true;
        this.editViewSidesheetService.hideLoader();
        this.onError(error);
      });
  }

  selecetedTimeZoneId: any;
  getTimeZones() {
    this.sessionService.getTimeZones().subscribe(
      (data: any) => {
        this.timeZones = data;

        var timeZone = this.timeZones.filter(t => t.timeZoneId == this.session.timeZoneId);
        if (timeZone.length > 0) {
          const displayName = this.session.timeZoneDisplayName;
          this.editSessionForm.patchValue({
            timeZone: ({ timeZoneId: this.session.timeZoneId, displayName: displayName, timeZoneName: this.removeOffsetsFromTimeZone(displayName)}) as ITimeZone
          });
        }

        this.InvokeTimeZoneSearchFilter(true);
        this.selecetedTimeZoneId = this.session.timeZoneId;
        this.editViewSidesheetService.timeZonesLoaded = true;
        this.editViewSidesheetService.hideLoader();
      },
      error => {
        this.editViewSidesheetService.hasError = true;
        this.editViewSidesheetService.hideLoader();
        this.onError(error);
      });
  }

  setDateString(date: Date) {
    const datepipe: DatePipe = new DatePipe('en-US')
    this.session.startDate = <string>datepipe.transform(date, 'dd-MMM-YYYY');

    this.closeBanner();
  }

  setTimePickerValue() {
    var timeString = this.session.startDateTimeDisplay.toString().split('T')[1].split(':');
    var hours = parseInt(timeString[0]);
    var minutes = parseInt(timeString[1]);

    this.selectedTime = { hours: hours, minutes: minutes };
    this.setTimeString(this.selectedTime)
  }

  setTimeString(value: Time | null) {
    if (value !== null) {
      this.session.startTime = `${value.hours}:${value.minutes}`
      this.closeBanner();
    }
  }

  validateForm() {
    this.bannerEditSession.hide();
    this.editSessionForm.markAllAsTouched();

    if (this.editSessionForm.valid) {
      this.canScheduleSession().subscribe((isValidDatetime: boolean) => {
        if (isValidDatetime) {
          let examGroupId = this.getExamGroupId();

          if (examGroupId > 0 && examGroupId.toString().length <= 8) {
            this.validateExamGroupIdWithAPI().subscribe((examGroupIdValidationSuccess: boolean) => {
              if (examGroupIdValidationSuccess) {
                this.editViewSidesheetService.editSaveClick();
              }
            });
          }
          else {
            this.editViewSidesheetService.editSaveClick();
          }
        }
      });
    }
    else {
      this.showErrorBanner(this.translate("editSession.errorBannerHeader"), '');
    }
  }

  private canScheduleSession(): Observable<boolean> {
    const formattedDate = format(new Date(this.session.startDate), DateFormats.MM_DD_YYYY, { locale: enUS });
    const formattedTime = this.session.startTime;
    const timeZone = (this.editSessionForm.controls.timeZone?.value as unknown as ITimeZone).timeZoneId;
    this.checkOutageInfo({ date: formattedDate, time: formattedTime, timeZoneId: timeZone });

    return this.sessionService.canScheduleSession({ date: formattedDate, time: formattedTime, timeZoneId: timeZone })
      .pipe(
        switchMap((res: any) => {
          let isValidDatetime = res.canScheduleSession;
          if (!isValidDatetime) {
            this.showErrorBanner(this.t.translate("sessiondetails.error.session_schedule_maxhours_range", { maxHours: Math.floor(+ this.settingsCache.minScheduleLeadTime / 60) }), '');
          }
          if (this.isOutages) {
            this.showErrorBanner(this.t.translate('createsession.outages.header'), this.t.translate('createsession.outages.content'));
            isValidDatetime = false;
          }
          return of(isValidDatetime)
        }),
        catchError(err => {
          this.showErrorBanner(this.translate("editSession.errorBannerHeader"), '');
          return of(false)
        })
      )
  }

  validateExamGroupIdWithAPI(): Observable<boolean> {

    var examGroupIdControl = this.editSessionForm.controls.examGroupId;
    let examGroupId = this.getExamGroupId();

    return this.testCenterApiService.getExamGroup(this.session.testCenterId, examGroupId).pipe(
      switchMap((examGroup: any) => {
        this.session.examGroupName = examGroup[0].examGroupName;
        examGroupIdControl.setErrors(null);
        this.examGroupIdValidationSuccess = true;
        this.closeBanner();
        return of(true)
      }),
      catchError(err => {
        if (err?.error?.errorCode == ExamGroupErrorCodes.NotFound) {
          this.examGroupErrorMsg = this.translate("editSession.examGroupIdNotFound"),
            examGroupIdControl.setErrors({ ...examGroupIdControl.errors, apiError: true });
        }
        else if (err?.error?.errorCode == ExamGroupErrorCodes.NotAssociated) {
          this.examGroupErrorMsg = this.translate("editSession.examGroupIdForTestCenterNotFound"),
            examGroupIdControl.setErrors({ ...examGroupIdControl.errors, apiError: true });
        }
        this.examGroupIdValidationSuccess = false;
        this.showErrorBanner(this.translate("editSession.errorBannerHeader"), '');
        return of(false)
      })
    )
  }

  save() {
    this.loaderService.showLoader();
    let userId = this.getLoggedInUserId();

    this.session.sessionName = this.editSessionForm.controls.sessionName.value || '';
    this.session.examGroupId = this.getExamGroupId();
    if (this.session.examGroupId == 0) {
      this.session.examGroupName = ''
    }

    this.session.proctorId = (this.editSessionForm.controls.proctor?.value as unknown as IUser).userID || 0;
    this.session.timeZoneId = (this.editSessionForm.controls.timeZone?.value as unknown as ITimeZone).timeZoneId || 0;
    this.session.updatedById = userId;
    this.session.updatedDateTime = new Date();

    this.sessionService.editSession(this.session).subscribe(
      result => {

        this.editReturnObj = result;

        this.loaderService.hideLoader();
        this.ref.close({
          sessionName: this.session.sessionName,
          sessionAutoConfirmed: this.editReturnObj?.sessionAutoConfirmed,
          hasError: false
        })
        window.scrollTo(0, 0);
        this.sessionStatusUpdateService.loadSessionData.next();
      },
      error => {
        this.loaderService.hideLoader();
        this.onError(error);
      }
    );

  }


  updateSession() {
    this.session.sessionName = this.editSessionForm.controls.sessionName.value || '';
    this.session.examGroupId = this.getExamGroupId();
    this.session.proctorId = (this.editSessionForm.controls.proctor?.value as unknown as IUser).userID || 0;
    this.session.timeZoneId = (this.editSessionForm.controls.timeZone?.value as unknown as ITimeZone).timeZoneId || 0;
  }

  getLoggedInUserId() {
    let userId = this.userDetailService.getUserDetail().userId;
    if (isNaN(this.userDetailService.getUserDetail().userId)) {
      userId = 0;
    }
    return userId;
  }

  getExamGroupId() {
    let examGroupId = Number(this.editSessionForm.controls.examGroupId.value);

    return examGroupId;
  }

  onError(error: any) {
    let errorResponse: any = error?.error?.lockPaymentErrorResponse;

    //TODO: Remove this when no longer needed. (checkPaymentErrorResponse was renamed to lockPaymentErrorResponse on the back end.)
    if (!errorResponse) {
      errorResponse =  error?.error?.checkPaymentErrorResponse;
    }

    if (errorResponse?.portalError?.code.toUpperCase() == PaymentErrorCodes.InsufficientPayment) {
      this.showErrorBannerForCheckPayment(errorResponse?.portalError?.message);

    }else if (errorResponse?.portalError?.code.toUpperCase() == PaymentErrorCodes.ExpiredSiteLicenseSLA) {
      const expiredSiteLicense = errorResponse?.portalError?.message as IExpiredSiteLicense[];
      this.showexpiredSiteLicense(expiredSiteLicense);
    }else if (error?.status >= HttpStatusCode.InternalServerError) {
      this.showErrorBanner(this.t.translate('serverError.header', {errorCode: error.error.errorCode, traceId: error.error.traceId}),this.t.translate('serverError.contactAdmin'));

    } else {
      this.showErrorBanner(this.translate("genericErrorHeader"), this.translate("genericErrorContent"));
    }
  }

  translate(toTranslate: string): string {
    return this.t.translate(toTranslate);
  }

  get timePickerFormCtrl() {
    return this.timePicker?.timeFormControl;
  }

  onTimepickerClick() {
    if (!this.formControls.timePicker?.touched && !this.timePickerFormCtrl?.touched) {
      this.markTimepickerFormControlAs('touched');
      this.formControls.timePicker?.markAsTouched();
    }
  }

  onTimepickerInput() {
    if (this.timePickerFormCtrl?.invalid || this.formControls.timePicker?.invalid) {
      this.formControls.timePicker?.setErrors({ 'invalid': true });
      this.timePickerFormCtrl?.setErrors({ 'invalid': true });
    }
  }


  markTimepickerFormControlAs(status: string) {
    switch (status.toLowerCase()) {
      case 'reset':
        this.timePickerFormCtrl?.reset(null);
        break;

      case 'touched':
        this.timePickerFormCtrl?.markAsTouched();
        break;

      case 'untouched':
        this.timePickerFormCtrl?.markAsUntouched();
        break;
    }
  }

  closeSideSheet() {
    this.editViewSidesheetService.closeEditSideSheet();
  }

  showErrorBanner(header: string, content: string) {

    let bannerData: IBannerData = {
      bannerType: 'warn',
      header: header,
      contents: [content],
      disableCloseButton: true
    };
    this.editViewSidesheetService.showErrorBanner(bannerData);
  }

  showErrorBannerForCheckPayment(data: any) {

    let header = this.t.translate('checkPaymentInsufficientPaymentErrorHeader', { paymentMethodName: this.session.paymentMethodName });
    let bannerContent: string[] = [];

    bannerContent.push("linebreak");
    for (let i = 0; i < data.length; i++) {
      bannerContent.push(this.t.translate('checkPaymentInsufficientPaymentErrorContentExamSeriesCode', { examSeriesCode: data[i].examSeriesCode }));
      bannerContent.push(this.t.translate('checkPaymentInsufficientPaymentErrorContentExamDescription', { examDescription: data[i].examDescription }));
      bannerContent.push("linebreak");
    }

    bannerContent.push(this.t.translate('checkPaymentInsufficientPaymentErrorFooter'));

    let bannerData: IBannerData = {
      bannerType: 'warn',
      header: header,
      contents: bannerContent,
      disableCloseButton: true,
      metadata: { isCheckPaymentError: true }
    };
    this.editViewSidesheetService.showErrorBanner(bannerData);
  }

  private showexpiredSiteLicense(expiredSiteLicense: IExpiredSiteLicense[]) {
    if (expiredSiteLicense.length > 0) {

      const header = this.translate('editSession.errorBannerHeader');
      let isMultipleErrors: boolean = false;
      isMultipleErrors = expiredSiteLicense.length === 1 ? false : true;
      let bannerData: IBannerData = {
        bannerType: 'warn',
        header: expiredSiteLicense.length === 1 ? '' : header,
        contents: [],
        disableCloseButton: true,
        metadata: { isExpiredSiteLicense: true, expiredSiteLicense, isMultipleErrors }
      };
      this.editViewSidesheetService.showErrorBanner(bannerData);
    }
  }

  closeBanner(): void {
    if (this.editSessionForm.valid && this.examGroupIdValidationSuccess) {
      this.editViewSidesheetService.closeBanner();
    }
  }

  private getOutageInfo() {
    this.outageService.getOutageInfo().subscribe({
      next: (response) => {
        this.outageInfo = response;
      },
      error: (error) => {
        if (error.status == HttpStatusCode.InternalServerError) {
          this.onError(error);
        }
        else {
          this.showErrorBanner(this.translate("genericErrorHeader"), this.translate("genericErrorContent"));
        }
      }
    });
  }

  private checkOutageInfo(timeConversionInput: ITimeConversionInput) {
    this.outageLogic.isSessionTimeAllowed(timeConversionInput, this.session.testCenterId, this.outageInfo).subscribe({
      next: (allowed) => {
        this.isOutages = !allowed;
      },
      error: (error) => {
        if (error.status == HttpStatusCode.InternalServerError) {
          this.onError(error);
        }
        else {
          this.showErrorBanner(this.translate("genericErrorHeader"), this.translate("genericErrorContent"));
        }
      }
    });
  }

  InvokeTimeZoneSearchFilter(setSelectedValue: boolean = false)
  {
    this.filteredTimeZones = this.editSessionForm.get('timeZone')?.valueChanges.pipe(
      startWith(''),
      map(value => {
        let name = typeof value === 'string' || value == null ? value : ((value as unknown)as ITimeZone).displayName;
        name = setSelectedValue ? this.session.timeZoneDisplayName : name;
        setSelectedValue = false;
        return name ? this._filterTimeZone(name as string) : this.timeZones.slice();
      }),
    );
  }

  FilterPreSelectedData()
  {
    this.filteredTimeZones = this.filteredTimeZones?.pipe(
      map(s=>{
        return this._filterTimeZone(this.session.timeZoneDisplayName);
      })
    )
  }

  private _filterTimeZone(value: string): ITimeZone[] {
    const filterValue = value.toLowerCase();
    return this.timeZones.filter(option => option.displayName.toLowerCase().includes(filterValue));
  }

  displayTimeZoneName(timeZone: ITimeZone): string {
    return timeZone && timeZone.displayName ? timeZone.timeZoneName : '';
  }

  InvokeProctorSearchFilter(setSelectedValue: boolean = false, proctorName: string)
  {
    this.filteredproctors = this.editSessionForm.get('proctor')?.valueChanges.pipe(
      startWith(''),
      map(value => {
        let name = typeof value === 'string' || value == null ? value : ((value as unknown)as IUser).displayName;
        name = setSelectedValue ? proctorName : name;
        setSelectedValue = false;
        return name ? this._filterProctors(name as string) : this.proctors.slice();
      }),
    );
  }

  private _filterProctors(value: string): IUser[] {
    const filterValue = value.toLowerCase();
    return this.proctors.filter(option => (option.displayName.toLowerCase().includes(filterValue)));
  }

  displayProctorName(user: IUser): string {
    return user && user.firstName ? user.firstName + ' ' + user.lastName : '';
  }

  private autocompleteObjectValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value != '' && typeof control.value === 'string') {
        return { 'invalidAutocompleteObject': { value: control.value } }
      }
      return null
    }
  }

  removeOffsetsFromTimeZone(timeZoneDisplayName: string): string {
    //Regular expression to match offset in the format (UTC+/-HH:MM)
    const offsetRegex = /\(UTC[+-]\d{2}:\d{2}\)/;
    // Replace offset with an empty string
    timeZoneDisplayName = timeZoneDisplayName.replace(offsetRegex, '').trim();

    return timeZoneDisplayName;
  }
}
