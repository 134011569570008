<p>communication-preferences-edit works!</p>

<!-- The elements in the comments below have most of the text needed for this component. -->
<!-- <mat-card>
	<div>
		<h2>{{ t("pref.communicationPreferences") }}</h2>
		<h3>{{ t("pref.commPrefNote") }}</h3>
		<div>
			<div>{{ t("pref.mobileNumber") }}</div>
			<div>{{ t("pref.textMessagesOptIn") }}</div>
			<div>{{ t("generic.yes") }}</div>
			<div>{{ t("generic.no") }}</div>
			<div>{{ t("pref.acceptTxtMsgTerms") }}</div>
		</div>
		<div>
			<div>{{ t("pref.marketingOptIn") }}</div>
			<div>{{ t("pref.subscribeToComm") }}</div>
		</div>
		<button>{{ t("generic.cancel") }} Cancel</button>
		<button>{{ t("generic.save") }}</button>
	</div>
</mat-card> -->
