import { Inject, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { SignalRListenerCallBackMethod } from '../models/signalRListenerCallBackMethod';
import { ConfigService } from './config-service';
import { SignalRHub } from './SignalRHub';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  environment: any;
  subscribeNotifyReInvokeListener$!: Subscription;
  private signalRListenerCallBackMethodCollection : SignalRListenerCallBackMethod[] =[];
  constructor(private _signalRHub: SignalRHub, private configService: ConfigService) {
    this._signalRHub.initialize(this.configService.schedulingServiceURL + '/SchedulerHub');
  }
  public start(myGroupId: string): Promise<void> {
    return this._signalRHub.start()
      .then(() => {
        this.subscribe(myGroupId);
      })
      //TODO: In the event of a connection error, generate an appropriate event so that the UI can respond.
      .catch((err: any) => console.log('SignalR connection error:' + err));
  }

  public startGroupIds(myGroupIds: string[]): Promise<void> {
    return this._signalRHub.start()
      .then(() => {
        this.subscribeGroupIds(myGroupIds);
        this.subscribeNotifyReInvokeListener();
      })
      .catch((err: any) => console.log('SignalR connection error:' + err));
  }


  public stop(): Promise<void> {
    return this._signalRHub.stop();
  }

  public addCreateSessionListener(listener: (msg: string) => void, myGroupIds: string[]): void {
    this.signalRListenerCallBackMethodCollection.push({callBackMethod: listener, myGroupIds: myGroupIds} as SignalRListenerCallBackMethod);
    this._signalRHub.on('CreateSession', listener);
  }

  public removeCreateSessionListener(listener: (msg: string) => void): void {
    this._signalRHub.off('CreateSession', listener)
  }

  private subscribe(groupId: string): Promise<void> {
    return this._signalRHub.invoke('Subscribe', groupId);
  }

  private subscribeGroupIds(groupIds: string[]): Promise<void[]> {
    const subscriptionGroupId: Promise<void>[] = groupIds.map(groupId => {
      return this._signalRHub.invoke('Subscribe', groupId);
    });
    return Promise.all(subscriptionGroupId);
  }

  private subscribeNotifyReInvokeListener() {
    this.subscribeNotifyReInvokeListener$ = this._signalRHub.NotifyReInvokeListener().subscribe(() => {
      this.signalRListenerCallBackMethodCollection.map((listenerDetails: any) => {
        this._signalRHub.on('CreateSession', listenerDetails.listener);
        this.subscribeGroupIds(listenerDetails.myGroupIds);
      })
    });
  }
}
