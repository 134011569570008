<topaz-toast useIcon [disableCloseButton]="expiredSiteLicense.disableCloseButton">
  <div toast-content *transloco="let t">
    <h5>
      {{ t('siteLicenseExpiredSLA.Header', { programName: expiredSiteLicense.programName,expirationDate:expiredSiteLicense.expirationDate }) }}

    </h5>

    <h6>{{ t('checkPaymentInsufficientPaymentErrorFooter') }}</h6>

  </div>
</topaz-toast>
