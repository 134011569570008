import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedStateService {

  private invokeSmsSignupPopup: boolean = false;

  constructor() { }

  setInvokeSmsSignupPopup(value: boolean): void {
    this.invokeSmsSignupPopup = value;
  }

  getInvokeSmsSignupPopup(): boolean {
    return this.invokeSmsSignupPopup;
  }
}
