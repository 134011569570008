<div id="warning-dialog-container">
  <div cdkTrapFocus
       aria-modal="true"
       role="dialog"
        [attr.aria-label]= "  t.translate('CreateSessionCancellation.modal-title')"
       [attr.aria-describ]= "  t.translate('CreateSessionCancellation.modal-description')"
       id="warning-dialog-container">
    <h3 id="modal-title" topazTitle="3">{{ warningDialogData.header }}</h3>
    <p class="content" [style.marginBottom]="warningDialogData.contentMarginBottom" *ngFor="let content of warningDialogData.contents">
      <!--TODO: CBB-41212 content might need to be translated below-->
      {{ content }}
    </p>
    <div class="button" [style.marginTop]="warningDialogData.buttonMarginTop" mat-dialog-actions [align]="'end'">
      <button mat-flat-button class="topaz-button-small" id="noButton" (click)="onClick('no')">{{ t.translate('generic.no') }}</button>
      <button mat-flat-button color="warn" class="topaz-button-small" (click)="onClick('yes')">{{t.translate('generic.yes')}}</button>
    </div>
  </div>
</div>

