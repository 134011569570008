import { Injectable } from '@angular/core';
import { ToastService, ToastServiceConfig } from '@pearsonvue/topaz-angular-ui';
import { IToastServiceConfig } from '../models/toast-config';

@Injectable({
  providedIn: 'root'
})
export class ToastConfigService {

  constructor(private toastService: ToastService) { }

  setDefaultConfig() {
    this.setToastDefaultConfig();
  }

  setAutoDismissOff() {
    const config: ToastServiceConfig = {
      enableAutoDismiss: false
    };
    this.toastService.initialize(config);
  }

  setCustomConfig(toastServiceConfig: IToastServiceConfig) {
    const config: ToastServiceConfig = {
      enableSingleToast: toastServiceConfig.enableSingleToast,
      enableAutoDismiss: toastServiceConfig.enableAutoDismiss,
      autoDismissDelay: toastServiceConfig.autoDismissDelay,
      maximumDisplayedToasts: toastServiceConfig.maximumDisplayedToasts
    };
    this.toastService.initialize(config);
  }

  private setToastDefaultConfig(): void {
    const config: ToastServiceConfig = {
      enableSingleToast: true,
      enableAutoDismiss: true,
      autoDismissDelay: 5000,
      maximumDisplayedToasts: 1
    };
    this.toastService.initialize(config);
  }
}
