import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOutage, IOutageInfo, ITimeConversionDto, ITimeConversionInput } from '../models/outage';
import { ConfigService } from './config-service';

@Injectable({
  providedIn: 'root'
})
export class OutagesService {

  environment: any;

  constructor(private httpClient: HttpClient, private configService: ConfigService,) {
  }

  getOutages(): Observable<IOutage[]> {
    const url = `${this.configService.schedulingServiceURL}/Outage`;
    return this.httpClient.get<IOutage[]>(url, { withCredentials: true });
  }

  getOutageInfo(): Observable<IOutageInfo> {
    const url = `${this.configService.schedulingServiceURL}/outages`;
    return this.httpClient.get<IOutageInfo>(url, { withCredentials: true });
  }

  ConvertTimeToUTC(timeConversionInput: ITimeConversionInput): Observable<ITimeConversionDto> {
    const url = `${this.configService.schedulingServiceURL}/TimeZone/ConvertTimeToUTC`;
    return this.httpClient.post<ITimeConversionDto>(url, timeConversionInput,{ withCredentials: true });
  }

}
