import { Time } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { CommonmethodsService } from '../create-session-services/commonmethods.service';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';
import { TopazWrapperService } from 'src/app/services/topaz-wrapper.service';

@Component({
  selector: 'app-sessionreview',
  templateUrl: './sessionreview.component.html',
  styleUrls: ['./sessionreview.component.scss']
})
export class SessionreviewComponent implements OnInit {

  @Input() sessionForm!: FormGroup;
  @Input() examForms!: FormArray;
  @Input() maxHours!: number;
  startDateTime!: Date;
  loggedInUserId!: number;
  @Output() sessionEdit = new EventEmitter();
  @Output() examEdit = new EventEmitter<number>();
  @Input() isUserAssignedPaymentSelected: boolean = false;

  minAssociateCandidateLeadTime: number = 0;

  constructor(private t: TranslocoService,
    private commonmethodsService: CommonmethodsService,
    private settingsCache: SettingsCacheService,
    private topazWrapper: TopazWrapperService) { }

  ngOnInit(): void {
    this.loggedInUserId = this.commonmethodsService.getLoggedInUserId();
    this.minAssociateCandidateLeadTime = this.settingsCache.minAssociateCandidateLeadTime / 60;
  }

  get bannerHeader() : string {
    if (this.isUserAssignedPaymentSelected)
      return this.topazWrapper.translate('createsession.review.candidate_joining_header');
    else
      return this.topazWrapper.translate('createsession.review.noteheader');
  }

  get bannerContent() : string {
    if (this.isUserAssignedPaymentSelected)
      return this.topazWrapper.translate('createsession.review.candidate_joining_content', { minAssociateCandidateLeadTime: this.minAssociateCandidateLeadTime });
    else
      return this.topazWrapper.translate('createsession.review.updatenumbercandidates', { minhour: this.maxHours });
  }

  onSessionEdit(): void {
    this.sessionEdit.emit();
  }

  onExamEdit(examTab: number): void {
    this.examEdit.next(examTab)
  }

  getStartDateTime() {
    let dateString: string | null | undefined = this.sessionForm.get('date')?.value;
    let time = this.sessionForm.get('timepicker')?.value as Time;
    if (dateString !== null && dateString !== undefined && time) {
      let date: Date = new Date(dateString);
      let time = this.sessionForm.get('timepicker')?.value as Time;

      date.setHours(date.getHours() + time.hours);
      date.setMinutes(date.getMinutes() + time.minutes);

      this.startDateTime = date;
    }
    return this.startDateTime;
  }
}
