import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[preventBlacklistCharacters]'
})
export class PreventBlacklistCharactersDirective {

  constructor() { }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const sanitizedValue = inputElement.value.replace(/</g, '').replace(/>/g, '');
    if (inputElement.value !== sanitizedValue) {
      inputElement.value = sanitizedValue;
      inputElement.dispatchEvent(new Event('input'));
    }
  }
}
