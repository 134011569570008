<topaz-toast id="insufficient-payment-container" useIcon
    [disableCloseButton]="insufficientPaymentDetails.disableCloseButton">
    <div toast-content *transloco="let t">
        <h5>
            {{ insufficientPaymentDetails.paymentValidationType !== 'CheckPayment'
            ? t('insufficientPaymentHeader', { paymentMethodName: insufficientPaymentDetails.paymentMethodName })
            : t('checkPaymentInsufficientPaymentErrorHeader', { paymentMethodName: insufficientPaymentDetails.paymentMethodName }) }}
        </h5>
        <div class="insufficient-payment-content"
            *ngFor="let insufficientPayment of insufficientPaymentDetails.insufficientPayments">
            <p>{{ t('checkPaymentInsufficientPaymentErrorContentExamSeriesCode') }} <span>{{
                    insufficientPayment?.examSeriesCode }}</span></p>
            <p>{{ t('checkPaymentInsufficientPaymentErrorContentExamDescription') }} <span>{{
                    insufficientPayment?.examDescription }}</span></p>
        </div>

        <h6>{{ t('checkPaymentInsufficientPaymentErrorFooter') }}</h6>
    </div>
</topaz-toast>