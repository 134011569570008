import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { SessionConfirmationComponent } from './components/session-confirmation-layout/session-confirmation/session-confirmation.component';
import { ViewSessionsComponent } from './components/view-sessions/view-sessions.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { CreateSessionComponent } from './components/create-session/create-session.component';
import { PreferencesComponent } from './components/preferences/preferences.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    canActivate: [AuthGuard],
    children:
      [
        { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
        { path: 'new-session', component: CreateSessionComponent, canDeactivate: [CanDeactivateGuard]  },
        { path: 'dashboard', component: DashboardComponent },
        { path: 'dashboard/:sid', component: DashboardComponent },
        { path: 'session-confirmation', component: SessionConfirmationComponent},
        { path: 'session-schedule', component: ViewSessionsComponent },
        { path: 'preferences', component: PreferencesComponent },
        { path: '', redirectTo: '/sso', pathMatch: 'full' },
      ]
    },
    { path: '**', redirectTo: '' } //This is the fallback route, it has to be last in the list
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
