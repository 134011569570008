
<div class="container">
  <topaz-banner-root></topaz-banner-root>

  <div *ngIf="sessioninfoLoaded">
    <div class="mat-card-container" *transloco="let t">
      <mat-card attr.aria-label="{{ t('generic.session_name') }}">
        <div class="session-name">
          <h3>{{ t('generic.session_name') }}</h3>
          <h4>{{smsSessionInfo.sessionName}}</h4>
        </div>

        <div class="session-actions">
          <button mat-icon-button [mat-menu-trigger-for]="optionMenu"
                  [title]="t('scheduleTable.sessionOptions', {sessionName:smsSessionInfo.sessionName})"
                  attr.aria-label="{{t('scheduleTable.sessionOptions', {sessionName:smsSessionInfo.sessionName})}}">
            <fa-icon [icon]="icons.faEllipsis"></fa-icon>
          </button>

          <mat-menu #optionMenu="matMenu">
            <button mat-menu-item [disabled]="smsSessionInfo.disableConfirmSession" (click)="updateSessionStatusToConfirmed()" >
              {{ t('scheduleTableOptions.confirmSession') }}
            </button>
            <button mat-menu-item [disabled]="smsSessionInfo.disableStartSession">
              {{ t('scheduleTableOptions.startSession') }}
            </button>
            <button mat-menu-item>
              {{ t('scheduleTableOptions.viewDetails') }}
            </button>
            <button mat-menu-item [disabled]="smsSessionInfo.disableEditSession">
              {{ t('scheduleTableOptions.edit') }}
            </button>
            <button mat-menu-item>
              {{ t('scheduleTableOptions.resendConfirmationEmail') }}
            </button>
            <button mat-menu-item [disabled]="smsSessionInfo.disableCancelSession" (click)="updateSessionStatusToCancelled()" >
              {{ t('scheduleTableOptions.cancelSession') }}
            </button>
          </mat-menu>
        </div>

        <mat-card-content>
          <div class="grid-layout">
            <label>{{ t('smsConfirmSession.sessionStatus') }}</label>
            <div class="topaz-capsule-container" [ngSwitch]="smsSessionInfo.sessionStatusId">
              <topaz-capsule color="primary" *ngSwitchCase="1">
                <div class="scheduled-backcolor">
                  <fa-icon class="scheduled-color" [icon]="icons.faFilePen"></fa-icon>
                  <span class="scheduled-color">{{t('scheduleTableStatus.scheduled')}}</span>
                </div>
              </topaz-capsule>

              <topaz-capsule color="alert" *ngSwitchCase="2">
                <div class="confirmation-req-backcolor">
                  <fa-icon class="confirmation-req-color" [icon]="icons.faMessageLines"></fa-icon>
                  <span class="confirmation-req-color">{{t('scheduleTableStatus.confirmSession')}}</span>
                </div>
              </topaz-capsule>

              <topaz-capsule color="accent" *ngSwitchCase="3">
                <div class="confirmed-backcolor">
                  <fa-icon class="confirmed-color" [icon]="icons.faCheckCircle"></fa-icon>
                  <span class="confirmed-color">{{t('scheduleTableStatus.confirmed')}}</span>
                </div>
              </topaz-capsule>

              <topaz-capsule color="accent" *ngSwitchCase="4">
                <div class="in-progress-backcolor">
                  <fa-icon class="in-progress-color" [icon]="icons.faLaptop"></fa-icon>
                  <span class="in-progress-color">{{t('scheduleTableStatus.inprogress')}}</span>
                </div>
              </topaz-capsule>

              <topaz-capsule color="warn" *ngSwitchCase="5">
                <div class="cancelled-backcolor">
                  <fa-icon class="cancelled-color" [icon]="icons.faBan"></fa-icon>
                  <span *ngIf="!showSystemCancelledBanner" class="cancelled-color">{{t('scheduleTableStatus.userCancellationSession')}}</span>
                  <span *ngIf="showSystemCancelledBanner" class="cancelled-color">{{t('scheduleTableStatus.systemCancellationSession')}}</span>
                </div>
              </topaz-capsule>

              <topaz-capsule *ngSwitchCase="6">
                <div class="completed-backcolor">
                  <fa-icon class="completed-color" [icon]="icons.faCheckCircle"></fa-icon>
                  <span class="completed-color">{{t('scheduleTableStatus.completeSession')}}</span>
                </div>
              </topaz-capsule>
            </div>

            <label>{{ t('smsConfirmSession.sessionStartDateTime') }}</label>
            <span>{{startDateTimeDisplay}}</span>

            <label>{{ t('smsConfirmSession.candidateCount') }}</label>
            <span>{{smsSessionInfo.numberOfCandidates}}</span>

            <label>{{ t('smsConfirmSession.examCount') }}</label>
            <span>{{smsSessionInfo.numberOfExams}}</span>
          </div>

          <div class="button-panel">
            <button *ngIf="!smsSessionInfo.disableConfirmSession" (click)="updateSessionStatusToConfirmed()" mat-flat-button color="primary" aria-label="standard-primary">
              {{ t('scheduleTableOptions.confirmSession') }}
            </button>

            <button *ngIf="!smsSessionInfo.disableCancelSession" (click)="updateSessionStatusToCancelled()" mat-button id="warnTextBtn" color="warn" aria-label="warn">
              {{ t('generic.cancel_session') }}
            </button>
          </div>
        </mat-card-content>
      </mat-card>

      <div class="button-panel">
        <button class="create-session-button" *ngIf="showCreateSessionButton" mat-stroked-button color="primary" aria-label="secondary">
          {{ t('smsConfirmation.createSession') }}
        </button>
      </div>
    </div>
  </div>
</div>

