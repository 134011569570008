export interface ICreateSession {
  sessionName: string;

  testCenterId: number;
  testCenterName: string;

  paymentMethodId: number;
  paymentMethodName: string;

  examGroupId: number;
  examGroupName: string;

  languageCode: string;

  proctorId: number;

  startDate: string;
  timeZoneID: number;
  startTime: string;

  creatorId: number;
  creatorEmail: string;

  sessionExams: ICreateSessionExam[]
}

export interface ICreateSessionExam {
  examName: string;
  seriesCode: string;
  duration: number;
  candidateCount: number;
  autoDeskVersion: string;
}

export interface ICreateSessionOutput {
  sessionId: number;
}

export interface ISession {
  sessionId: number;
  sessionName: string;
  testCenterId: number;
  testCenterName: string;
  languageCode: string;
  languageName: string;
  timeZoneId: number;
  startDateTime: Date;
  startDateTimeDisplay: Date;
  startDate: string,
  startTime: string,
  windowsTimeZone: string;
  timeZoneDisplayName: string;
  timeZoneName: string;
  creatorId: number;
  creator: string,
  creatorEmail: string
  proctorId: number;
  proctor: string,
  paymentMethodId: number;
  paymentMethodName: string;
  examGroupId: number;
  examGroupName: string
  updatedById: number;
  updatedDateTime: Date;
  sessionStatusId: number;
  sessionStatus: string;
  sessionExams: ISessionExams[]
}

export interface ISessionExams {
  sessionExamId: number;
  sessionId: number;
  examCode: string;
  examName: string;
  seriesCode: string;
  candidateCount: number;
  duration: number;
  autodeskVersion:string;
}

export interface ITest {
  sessionId: number;
  sessionExamId: number;
}

export interface ISessionScheduleList {
  sessionId: number;
  sessionName: string;
  sessionStatus: string;
  sessionStatusId: number;
  sessionstartDateTime: Date;
  numberOfCandidates: number;
  numberOfExams: number;
  creator: string;
  proctor: string;
  startDateTime: Date;
  startDateTimeDisplay: Date;
  timeZoneDisplayName: string;
  disableConfirmSession: boolean;
  disableCancelSession: boolean;
  updatedById: number | null;
  disableStartSession: boolean;
  disableEditSession: boolean;
  disableResumeSession: boolean;
  proctorId: number;
}

export enum AllowedRoles {
  proctor = 3,
  orgadmin = 23,
  orgmember = 76,
  teacher = 312
}

export interface IUpdateSessionStatus {
  sessionId: number;
  sessionCode: string;
  statusName: string;
  updatedById: number;
  reason: string;
}

export enum SessionStatus {
  scheduled = "Scheduled",
  confirmSession = "Confirm Session",
  confirmed = "Confirmed",
  inprogress = "In-progress",
  cancelled = "Cancelled",
  complete = "Complete",
  expiredNotStarted ="Expired-not started"
}

export enum SessionStatusIds {
  scheduled = 1,
  confirmSession = 2,
  confirmed = 3,
  inprogress = 4,
  cancelled = 5,
  complete = 6,
  expiredNotStarted = 7
}
