<ng-container *ngIf="settingsLoaded">
<div class="view-sessions-container" *transloco="let t">
	<div class="view-sessions-header">

		<span class="desktop">
			<div class="header">
				<h1 autofocus>{{ t('session.view.schedule') }}</h1>
			</div>
		</span>
		<span class="mobile">
				<h1 autofocus class="view-session-mobile-header">{{ t('session.view.schedule') }}</h1>
		</span>
		<button mat-flat-button class="mb-btn-createsession" color="primary" (click)="handleCreateSession()">{{ t('session.view.create.session') }}</button>
	</div>
	<div class="view-sessions-search mt-1">
		<topaz-search
			placeholder="{{ t('session.schedule.search') }}"
			(ngModelChange)="handleSearch($event)" [formControl]="search"
			clearButtonAriaLabel="{{ t('session.view.clear.search') }}"
			searchButtonAriaLabel="{{ t('session.view.perform.search') }}">
		</topaz-search>
	</div>

	<mat-card role="group" class="view-session-mat-card">
		<div class="card-content-spacer">
			<mat-card-content>
				<mat-tab-group (selectedTabChange)="handleSelectedTabChange($event)"
											 class="mat-tab-grp"
											 mat-stretch-tabs="false" mat-align-tabs="start">
					<mat-tab label="{{ t('session.view.upcoming') }}"></mat-tab>
					<mat-tab label="{{ t('session.view.past.sessions') }}"></mat-tab>
				</mat-tab-group>

				<div *ngIf="isSessionLoaded" role="region" aria-labelledby="schedule-list">
					<app-session-schedule-table
						[noDataText]="noDataText"
						(updateSessionSchedule)="getSessionScheduleData()"
						[searchValue]="searchValue"
						[sessionScheduleData]="sessionScheduleData"
						[hideOptions]="hideOptions">
					</app-session-schedule-table>
				</div>
			</mat-card-content>
		</div>
	</mat-card>

</div>
</ng-container>
