import { Injectable } from '@angular/core';

//The purpose of this service is to provide a thin wrapper around JavaScript localStorage functionality.
// This service facilitates unit testing and does not need to be unit tested itself.

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  public clear(): void {
    localStorage.clear();
  }

  public getItem(key: string): string | null {
    return localStorage.getItem(key);
  }

  public setItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }
}
