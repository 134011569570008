import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { SideSheetService, ToastService, TopazOverlayRef } from '@pearsonvue/topaz-angular-ui';
import { LoaderService } from 'certiport-layout-library';
import { Subject } from 'rxjs';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { BannerEditSessionComponent } from '../components/edit-session-layout/banner-edit-session/banner-edit-session.component';
import { EditSessionComponent } from '../components/edit-session-layout/edit-session/edit-session.component';
import { ToastCustomComponent } from '../components/toast-custom/toast-custom.component';
import { SessionDetailViewComponent } from '../components/view-session-layout/session-detail-view/session-detail-view.component';
import { IBannerData } from '../models/bannerdata';
import { IConfirmDialogData } from '../models/confirmdialogdata';
import { ISession } from '../models/session';
import { IToastData } from '../models/toastdata';


@Injectable({
  providedIn: 'root'
})
export class EditViewSidesheetService {

  hasError = false;
  settingsLoaded = false;
  sessionDataLoaded = false;
  proctorsLoaded = false;
  timeZonesLoaded = false;
  isEditSessionSaveCalled = false;
  editSideSheet!: TopazOverlayRef;
  viewSideSheet!: TopazOverlayRef;
  editSessionobjOriginal!: ISession;
  editSessionobjModified!: ISession;
  bannerEditSession!: BannerEditSessionComponent

  constructor(
    private sideSheet: SideSheetService,
    private dialog: MatDialog,
    private toastService: ToastService<IToastData>,
    private transloco: TranslocoService,
    private loaderService: LoaderService,) {
  }

  private editSave = new Subject();

  editSaveClick() {
    this.isEditSessionSaveCalled = true;
    this.editSave.next(new EventEmitter);
  }
  onEditSaveClick() {
    return this.editSave.asObservable();
  }

  openEditSessionSidesheet(sessionId: number, actionTriggeredFrom?: ActionTriggeredFrom): void {
    const ref = this.sideSheet.open(EditSessionComponent, sessionId, {
      preventAutomaticClose: true
    });

    this.editSideSheet = ref;

    ref.beforeClosed().subscribe((e) => {
      var dialogContents: string[] = [];
      dialogContents.push(this.transloco.translate("editSession.confirmDialogContentLine1"));
      dialogContents.push(this.transloco.translate("editSession.confirmDialogContentLine2"));

      var confirmDialogData = {
        header: this.transloco.translate("editSession.confirmDialogHeader"),
        contents: dialogContents,
        buttonType: "warn",
        showCloseButton: true,
        isCancelDialog: false

      } as IConfirmDialogData;

      if (this.isSessionObjUnchanged() && !this.isEditSessionSaveCalled) {
        ref.close(null, e.type, true)
        return;
      }
      else if (e.type === 'backdropClick' || e.type === 'closeButtonClick') {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: confirmDialogData
        })

        dialogRef.afterClosed().subscribe({
          next: (v) => {
            if (v === 'yes') {
              ref.close(null, e.type, true)
            }
          }
        })
      }
      if (e.type === 'close') {

        this.isEditSessionSaveCalled = false;
        ref.close(e, e.type, true);

        const sessionAutoConfirmed = e.data?.sessionAutoConfirmed;

        if (sessionAutoConfirmed) {
          const header = this.transloco.translate("editSession.saveAndConfirmSuccessHeader");
          this.toastService.open(ToastCustomComponent, { toastType: 'success', header, contents: [] });
        }
        else {
          this.toastService.open(ToastCustomComponent, {
            toastType: 'success',
            header: this.transloco.translate("editSession.saveSuccessHeader"),
            contents: [this.transloco.translate("editSession.saveSuccessContent", { sessionName: e.data?.sessionName })]
          });
        }
      }
    });
  }


  isSessionObjUnchanged() {
    let changed = JSON.stringify(this.editSessionobjOriginal) === JSON.stringify(this.editSessionobjModified)
    return changed;
  }

  editSidesheetConfirmDialog() {
    var dialogContents: string[] = [];
    dialogContents.push(this.transloco.translate("editSession.confirmDialogContentLine1"));
    dialogContents.push(this.transloco.translate("editSession.confirmDialogContentLine2"));

    var confirmDialogData = {
      header: this.transloco.translate("editSession.confirmDialogHeader"),
      contents: dialogContents,
      buttonType: "warn"

    } as IConfirmDialogData;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialogData
    })

    dialogRef.afterClosed().subscribe({
      next: (v) => {
        if (v === 'yes') {
          this.editSideSheet.close(null, undefined, true)
        }
      }
    })
  }

  closeEditSideSheet() {

    if (this.isSessionObjUnchanged()) {
      this.editSideSheet.close(null, undefined, true)
      return;
    }

    this.editSidesheetConfirmDialog();

  }

  closeSideSheets() {
    if (this.editSideSheet != undefined) this.editSideSheet.close(null, undefined, true)
    if (this.viewSideSheet != undefined) this.viewSideSheet.close(null, undefined, true)
  }

  openViewSessionSidesheet(sessionId: number, actionTriggeredFrom?: ActionTriggeredFrom) {
    this.viewSideSheet = this.sideSheet.open(SessionDetailViewComponent, sessionId);
    this.viewSideSheet.afterClosed().subscribe();
  }

  hideLoader() {
    if (this.hasError || (this.settingsLoaded && this.sessionDataLoaded && this.proctorsLoaded && this.timeZonesLoaded)) {
      this.loaderService.hideLoader();
    }
  }

  showErrorBanner(bannerData: IBannerData) {
    this.bannerEditSession.show(bannerData);
    this.scrollToTop();
  }

  closeBanner(): void {
    this.bannerEditSession.hide();
  }

  scrollToTop() {
    let divEditSessionBanner = document.getElementById("divEditSessionBanner");
    divEditSessionBanner?.scrollIntoView();
  }
}

enum ActionTriggeredFrom {
  Dashboard = 1,
  ViewSession = 2,
}
