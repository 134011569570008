import { Component, OnInit, ViewChild } from '@angular/core';
import { BannerComponent, BannerService } from '@pearsonvue/topaz-angular-ui';
import { IBannerData } from '../../models/bannerdata';

@Component({
  selector: 'app-banner-custom',
  templateUrl: './banner-custom.component.html',
  styleUrls: ['./banner-custom.component.scss']
})
export class BannerCustomComponent implements OnInit {

  @ViewChild(BannerComponent, { static: true }) public banner!: BannerComponent<IBannerData>

  data!: IBannerData;
  showBanner = true;
  bannerContentLength = 0;

  constructor(private bannerService: BannerService<IBannerData>) { }

  ngOnInit(): void {
    this.data = this.bannerService.getBannerData(this.banner) as unknown as IBannerData;

    if (!this.data.hasOwnProperty('useIcon')) {
      this.data.useIcon = true;
    }

    if (this.data?.metadata) {
      this.bannerContentLength = this.data.contents.length;
    }
  }

  actionEvent() {
    this.bannerService.close("startSession", this.data);
  }
}
