import { Component, Input, OnInit } from '@angular/core';
import { ISessionExams } from '../../../models/session';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-view-session-exams',
  templateUrl: './view-session-exams.component.html',
  styleUrls: ['./view-session-exams.component.scss']
})
export class ViewSessionExamsComponent implements OnInit {
  public icons = {
    faInfoCircle
  }
  @Input() isUserAssignedPayment: boolean = false;
  constructor() { }
  @Input() sessionExams: ISessionExams[] = [];

  ngOnInit(): void {
  }
}
