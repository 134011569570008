<mat-card *transloco="let t;">
	<div class="comm-pref-container">

		<div class="comm-pref-header">
			<div class="comm-pref-header-row">
				<div class="comm-pref-header-cell">
					<h2>{{ t("pref.communicationPreferences") }}</h2>
					<h3>{{scopeNote}}</h3>
				</div>

				@if (inEditMode) {
					<div class="pref-button-container comm-pref-header-cell">
						<button mat-flat-button class="topaz-button-small" (click)="handleClickMainCancel()">{{ t("generic.cancel") }}</button>
						<div class="button-spacer"></div>
						<button mat-flat-button color="primary" class="topaz-button-small">{{ t("generic.save") }}</button>
					</div>
				}
				@else {
					<div class="pref-button-container">
						<button mat-button color="primary" class="" (click)="handleClickEdit()">{{ t("generic.edit") }}</button>
					</div>
				}
			</div>
		</div>

		<div class="comm-pref-table">
			<div class="comm-pref-row">
				<div class="comm-pref-col-header">{{ t("pref.mobileNumber") }}</div>
				<div class="comm-pref-col-header">
					{{ t("pref.textMessagesOptIn") }}
					<fa-icon
						[icon]="icons.faCircleInfo"
						matTooltip="{{ t('pref.optInTooltip') }}"
						[matTooltipPosition]="'above'">
					</fa-icon>
				</div>
			</div>

			<div class="comm-pref-row">
				<div class="comm-pref-col-data">{{ mobilePhone }}</div>
				<div class="comm-pref-col-data">{{ textOptIn != null ? t(textOptIn ? 'generic.yes' : 'generic.no') : ''}}</div>
			</div>
		</div>

		<app-text-msg-preferences-view
			[inEditMode]="inEditMode"
			[firstConfirmationTextMinutes]="firstConfirmationTextMinutes"
			[secondConfirmationTextMinutes]="secondConfirmationTextMinutes"
			[confirmationOptIn]="confirmationOptIn"
			></app-text-msg-preferences-view>
	</div>
</mat-card>
