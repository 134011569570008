import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastComponent, ToastService } from '@pearsonvue/topaz-angular-ui';
import { TopazToastType } from '@pearsonvue/topaz-angular-ui/lib/components/toast/types/toast.type';
import { SessionService } from 'src/app/services/session.service';
import { ISession, SessionStatus } from '../models/session';
import { SchedulingErrorCodeService } from './scheduling-error-code.service';
import { SessionStatusUpdateService } from './session-status-update.service';
import { TimeProviderService } from './time-provider.service';
import { Observable, catchError,  map, of } from 'rxjs';
import { SettingsCacheService } from './settings-cache.service';

@Injectable({
  providedIn: 'root'
})
export class StartSessionService {

  launchedSessionWindows: (Window | null)[] = [];

  constructor(private sessionService: SessionService,
    private transloco: TranslocoService,
    private toastService: ToastService,
    private settingsCache: SettingsCacheService,
    private sessionStatusUpdateService: SessionStatusUpdateService,
    private timeProvider: TimeProviderService,
    private schedulingErrorCodeService: SchedulingErrorCodeService
  ) { }

  startSession(sessionId: number) : Observable<ISession>  {
    return this.sessionService.getSession(sessionId).pipe(
      map((sessionDetails) => {
        this.cleanupDates(sessionDetails);
        if (sessionDetails.sessionStatus === SessionStatus.confirmed) {
          this.checkCanSessionBeStart(sessionDetails);
        } else {
          this.showToast('', this.transloco.translate("startSession.statusError", { sessionStatus: sessionDetails.sessionStatus }), "warn");
        }
        return sessionDetails;
      }),
      catchError((error) => {
        if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
          this.schedulingErrorCodeService.showErrorCodeToast(error.error.errorCode, error.error.traceId);
        }
        else {
          this.showToast(this.transloco.translate("genericErrorHeader"), this.transloco.translate("genericErrorContent"), "warn");
        }
        return of();
      })
    );
  }

  cleanupDates(sessionDetails: ISession): void {
    sessionDetails.startDateTime = this.cleanupDate(sessionDetails.startDateTime);
  }

  cleanupDate(date: Date): Date {
    if (typeof date === 'string') {
      let t: string = date as unknown as string;
      if (!t.includes('Z')) {
        t += 'Z';
        date = new Date(Date.parse(t))
      }
    }
    return date;
  }

  private checkCanSessionBeStart(sessionDetails: ISession) {
    this.ValidateSessionStartTime(sessionDetails)
  }

  private ValidateSessionStartTime(sessionDetails: ISession) {

    let startTimes = this.getStartTimes(sessionDetails);
    var currentTime: number = this.timeProvider.currentTime().valueOf();
    if (currentTime >= startTimes.earliestStartTime && currentTime <= startTimes.latestStartTime) {
      this.updateSessionStatus(sessionDetails);
    } else {
      this.showToast('', this.transloco.translate("startSession.error", {
        minStartSessionLeadTime: startTimes.minStartSessionLeadTime,
        maxStartSessionLateTime: startTimes.maxStartSessionLateTime
      }), "warn");
    }
  }

  private getStartTimes(sessionDetails: ISession) {
    const minStartSessionLeadTime: number = this.settingsCache.minStartSessionLeadTime;
    const maxStartSessionLateTime: number = this.settingsCache.maxStartSessionLateTime;

    const earliestStartTime: number = sessionDetails.startDateTime.valueOf() - minStartSessionLeadTime * 60 * 1000;
    const latestStartTime: number = sessionDetails.startDateTime.valueOf() + maxStartSessionLateTime * 60 * 1000;

    return {
      earliestStartTime,
      latestStartTime,
      minStartSessionLeadTime,
      maxStartSessionLateTime
    };
  }

  private updateSessionStatus(sessionInfo: any) {
    this.sessionStatusUpdateService.updateStartSessionStatus(sessionInfo).subscribe({
      next: (response) => {
        if (response) {
          this.openExamSessionWindow(sessionInfo.sessionId);
        }
      },
      error: () => {
        this.showToast(this.transloco.translate("genericErrorHeader"), this.transloco.translate("genericErrorContent"), "warn");
      }
    });
  }

  openExamSessionWindow(sessionId: number) {
    const windowName = `sessionTab_${sessionId}`;

    const launchedSessionWindow = this.findLaunchedSessionWindow(windowName);

    if (!launchedSessionWindow) {
      const sessionWindow = window.open(this.sessionService.getProtorUiUrl(sessionId), windowName);
      this.launchedSessionWindows.push(sessionWindow);
    } else {
      launchedSessionWindow.focus();
    }
  }

  // try catch added to avoid cross-origin expection for dev environment.
  private findLaunchedSessionWindow(windowName: string) {
    try {
      return this.launchedSessionWindows.find(x => x?.name === windowName);
    }
    catch (error) {
      return null;
    }
  }

  private showToast(toastHeader: string, toastContent: string, toastType: string) {
    this.toastService.open(ToastComponent, {
      toastType: toastType as TopazToastType,
      toastHeader: toastHeader,
      toastContent: toastContent
    });
  }
}
