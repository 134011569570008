import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IExamGroup, ITestCenter, IUser, IUserTestCenter } from '../models/portal';
import { ConfigService } from './config-service';

@Injectable({
  providedIn: 'root'
})
export class TestCenterService {

  private environment: any;
  constructor(private configService: ConfigService, private httpClient: HttpClient) {
   }

   public getTestCenterProctors(testCenterId: number): Observable<IUser[]> {
    const url = `${this.configService.testCenterServiceURL}/TestCenters/${testCenterId}/Users/true&false`;
    return this.httpClient.get<IUser[]>(url, { withCredentials: true });
  }

  public getTestCenterInfo(testCenterId: number): Observable<ITestCenter> {
    const url = `${this.configService.testCenterServiceURL}/TestCenters/${testCenterId}`;
    return this.httpClient.get<ITestCenter>(url, { withCredentials: true });
  }

  public getTestCentersForUser(userId: number): Observable<IUserTestCenter[]> {
    const url = `${this.configService.testCenterServiceURL}/TestCenters?userId=${userId}`;
    return this.httpClient.get<IUserTestCenter[]>(url, { withCredentials: true });
  }

  public getExamGroup(testCenterId: number, examGroupId: number): Observable<IExamGroup[]> {
    const url = `${this.configService.testCenterServiceURL}/TestCenters/${testCenterId}/ExamGroups/${examGroupId}`;
    return this.httpClient.get<IExamGroup[]>(url, { withCredentials: true });
  }
}
