import { Injectable } from '@angular/core';
import { ToastWrapperService } from './toast-wrapper.service';
import { ToastCustomComponent } from '../components/toast-custom/toast-custom.component';
import { TopazToastType } from '@pearsonvue/topaz-angular-ui/lib/components/toast/types/toast.type';
import { ToastConfigService } from './toast-config.service';

export interface IToastManagerService {
  showTemporaryToast(toastHeader: string, toastContent: string, toastType: string): void;
  showStickyToast(toastHeader: string, toastContent: string, toastType: string): void;
}

export interface IToastData {
  toastType: TopazToastType;
  header: string;
  contents: string[];
  disableCloseButton: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class ToastManagerService implements IToastManagerService  {

  constructor(private toastWrapperService: ToastWrapperService, private toastConfigService: ToastConfigService) { }

  showTemporaryToast(toastHeader: string, toastContent: string, toastType: string): void {
    this.toastConfigService.setDefaultConfig();

    const data: IToastData = {
      toastType: toastType as TopazToastType,
      header: toastHeader,
      contents: [toastContent],
      disableCloseButton: true
    };

    this.toastWrapperService.open(ToastCustomComponent, data);
  }

  showStickyToast(toastHeader: string, toastContent: string, toastType: string): void {
    const config = {
      enableSingleToast: false,//Just in case we have another error up.
      enableAutoDismiss: false,
      autoDismissDelay: 5000,//Arbitrary
      maximumDisplayedToasts: 5//Arbitrary
    };

    this.toastConfigService.setCustomConfig(config);

    const data: IToastData = {
      toastType: toastType as TopazToastType,
      header: toastHeader,
      contents: [toastContent],
      disableCloseButton: false
    };

    this.toastWrapperService.open(ToastCustomComponent, data);

    //This is a temporary solution. (We don't want the custom configuration to persist.)
    this.toastConfigService.setDefaultConfig();
  }
}
