import { Inject, Injectable } from '@angular/core';
import { AutoLogoutTimerService, AutoLogoutWarningDlgService, IUserDetail, RefreshService, TimeProviderService, UserDetailService } from '@certiport/login-library';
import { Subscription } from 'rxjs';
import { ConfigService } from './config-service';

/**
 *
 */

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutRefreshService {
  private newUserDetailSubscription: Subscription | null = null;
  private timeoutSubscription: Subscription | null = null;
  private warningSubscription: Subscription | null = null;
  private logoutRequestSubscription: Subscription | null = null;
  private tickRequestSubscription: Subscription | null = null;

  private refreshInProcess: boolean = false;
  private refreshRequestTimeStamp: number = 0;
  private readonly RefreshDelayInMilliseconds = 5000;
  private readonly RefreshBlackoutTimeBeforeWarning = 1000;

  constructor(
    private userDetailService: UserDetailService,
    private autoLogoutTimerService: AutoLogoutTimerService,
    private autoLogoutWarningDlgService: AutoLogoutWarningDlgService,
    private timeProvider: TimeProviderService,
    private refreshService: RefreshService,
    private configService: ConfigService
    ) { }

  public setup(): void {
    this.autoLogoutTimerService.initialize();

    this.newUserDetailSubscription = this.userDetailService.newUserDetail.subscribe((userDetail) => {
      if (userDetail.userId) {
        this.resetAutoLogoutTimer(userDetail);
      }
    });

    this.timeoutSubscription = this.autoLogoutTimerService.timeout.subscribe(() => {
      this.autoLogoutTimerService.stop();
    });

    this.warningSubscription = this.autoLogoutTimerService.warning.subscribe(() => {
      this.autoLogoutWarningDlgService.showDialog();
    });

    this.logoutRequestSubscription = this.autoLogoutWarningDlgService.logoutRequest.subscribe(() => {
      this.autoLogoutTimerService.stop();
    });

    this.tickRequestSubscription = this.autoLogoutTimerService.tick.subscribe(() => {
      this.handleTick();
    });
  }

  private handleTick(): void {
    const userDetail: IUserDetail = this.userDetailService.getUserDetail();

    //Check for a refresh that needs to occur.

    //If
    // a refresh request is not in process,
    // AND if it has been at least a minute since the last refresh request,
    // AND if we are not in the warning period
    // THEN initiate a refresh request.

    //Note that if the refresh fails, the user will be logged out via another mechanism. This service does not currently need to log the user out.

    const now = this.timeProvider.now;

    const lastRefreshTime =
      userDetail.loginExpiry.valueOf() - this.configService.RefreshBlackoutTimeBeforeWarning;

    if (!this.refreshInProcess && this.refreshRequestTimeStamp && now <= lastRefreshTime) {
      let proposedRefreshTime = now + (this.configService.refreshTokenPriorInMinutes * 60 * 1000);

      if (userDetail.loginExpiry.valueOf() < proposedRefreshTime) {
        this.refreshInProcess = true;
        this.refreshService.RefreshToken().subscribe({
          next: (x) => {
            this.refreshRequestTimeStamp = 0;
            this.refreshInProcess = false;
          },
          error: () => {
            this.refreshRequestTimeStamp = 0;
            this.refreshInProcess = false;
          }
        });
      }
    }
  }

  private resetAutoLogoutTimer(userDetail: IUserDetail): void {
    const now = this.timeProvider.now;
    const expiry = userDetail.loginExpiry.valueOf() - now;
    const warnExpiry = expiry - this.configService.autoLogoutWarningWindowMinutes * 60 * 1000;
    this.autoLogoutTimerService.start(expiry, warnExpiry);
  }

  public teardown(): void {
    if (this.newUserDetailSubscription) {
      this.newUserDetailSubscription.unsubscribe();
    }

    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe();
    }

    if (this.warningSubscription) {
      this.warningSubscription.unsubscribe();
    }

    if (this.logoutRequestSubscription) {
      this.logoutRequestSubscription.unsubscribe();
    }

    if (this.tickRequestSubscription) {
      this.tickRequestSubscription.unsubscribe();
    }

    this.autoLogoutTimerService.uninitialize();
  }

  //This method is to be called when a user event occurs indicating that the user is still active, and the login should be refreshed.
  public pokeUserEvent(): void {
    if (!this.refreshRequestTimeStamp) {
      this.refreshRequestTimeStamp = this.timeProvider.now;
    }
  }
}
