import { Injectable } from '@angular/core';
import { ToastService } from '@pearsonvue/topaz-angular-ui';
//import { IToastData } from '../models/toast-custom';
import { ComponentType } from '@angular/cdk/portal';
import { TopazToastData } from '@pearsonvue/topaz-angular-ui/lib/components/toast/types/toast-data.interface';

//NOTE: This class is strictly to be a wrapper around the ToastService. As such, it doesn't need unit tests.
// It facilitates unit tests. This service should contain *no* logic.

@Injectable({
  providedIn: 'root'
})
export class ToastWrapperService {

  constructor(private toastService: ToastService) {}

  open(toast: ComponentType<unknown>, data: TopazToastData): void {
    this.toastService.open(toast, data);
  }
}
