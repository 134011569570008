import { InsufficientPaymentOutput } from './payment';

export interface InsufficientPaymentDetails {
    toastType: string,
    disableCloseButton?: boolean,
    paymentMethodName: string,
    paymentValidationType: string,
    insufficientPayments: InsufficientPaymentOutput[]
}

export enum PaymentValidationType {
    CheckPayment, LockPayment, UnlockPayment
}