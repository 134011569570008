import { Time } from '@angular/common';
import { IExamLanguage } from 'src/app/models/exam-language';
import { IUser, IUserTestCenter } from 'src/app/models/portal';
import { ITimeZone } from 'src/app/models/timezone';

export interface IUserSessionDetails {
  sessionName:   string;
  testCenter:    IUserTestCenter;
  paymentMethod: string;
  examGroupId:   number;
  examLanguage:  IExamLanguage;
  proctor:       IUser;
  date:          string;
  timeZone:      ITimeZone;
  timepicker:    Time;
}
