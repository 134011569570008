import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';

import { IWarningDialogData } from '../../../models/IWarningDialogData';

@Component({
  selector: 'app-create-session-warning-dialog',
  templateUrl: './create-session-warning-dialog.component.html',
  styleUrls: ['./create-session-warning-dialog.component.scss']
})
export class CreateSessionWarningDialogComponent {

  constructor(
    public t: TranslocoService, // How come private is working at other places?
    public dialogRef: MatDialogRef<CreateSessionWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public warningDialogData: IWarningDialogData) {
  }

  onClick(name: string) {
    if (name) {
      this.dialogRef.close(name);
    }
  }
}
