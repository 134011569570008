// more TBD
export enum PaymentErrorCodes {
  'InsufficientPayment' = 'INSUFFICIENT_PAYMENT',
  'ExpiredSiteLicenseSLA' = 'EXPIRED_SLA',
  'SLANotAccepted' = 'SLA_NOT_ACCEPTED',
  'InvalidSessionMatch' = 'INVALID_SESSION_MATCH'
}

export enum PaymentTypes {
    'SiteLicense_Inventory' = 'Site License / Inventory', 'Voucher' = 'Voucher', 'UserLicense' = 'User License'
}

export interface PaymentInput {
    testCenterId: number;
    paymentType: string;
    startTime: string;
    startDate: string;
    timeZoneId: number;
    examSessions: PaymentInputExamSession[];
}

export interface PaymentInputExamSession {
    examAccessCode: string;
    examSeriesCode: string;
    examName: string;
    candidateCount: number;
}

export interface LockPaymentInput extends PaymentInput {
    sessionId: number;
}

export interface UnlockPaymentInput {
    sessionId: number;
    examSessions: UnlockPaymentInputExamSession[];
}

export interface UnlockPaymentInputExamSession {
    examAccessCode: string;
}

export interface InsufficientPaymentOutput {
    examSeriesCode: string;
    examDescription: string;
}

export interface IPaymentType {
    paymentTypeId: number;
    displayName: string;
    portalName:string;
}
