import { Injectable } from '@angular/core';
import { ToastComponent, ToastService } from '@pearsonvue/topaz-angular-ui';
import { TranslocoService } from '@jsverse/transloco';
import { SessionService } from 'src/app/services/session.service';
import { TopazToastType } from '@pearsonvue/topaz-angular-ui/lib/components/toast/types/toast.type';
import { IUpdateSessionStatus, SessionStatus } from 'src/app/models/session';
import { catchError, finalize, map, Observable, of, Subject } from 'rxjs';
import { ToastCustomComponent } from '../components/toast-custom/toast-custom.component';
import { IToastData } from '../models/toastdata';
import { ToastConfigService } from './toast-config.service';
import { LoaderService } from 'certiport-layout-library';
import { UserDetailService } from '@certiport/login-library';
import { TimeProviderService } from './time-provider.service';
import { SchedulingErrorCodeService } from './scheduling-error-code.service';
import { HttpStatusCode } from '@angular/common/http';
import { SettingsCacheService } from './settings-cache.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStatusUpdateService {

  private updateSessionStatusInfo = { reason: '' } as IUpdateSessionStatus;
  loadSessionData = new Subject<void>();

  constructor(private toastService: ToastService,
    private toastServiceUpdateSession: ToastService<IToastData>,
    private sessionService: SessionService,
    private transloco: TranslocoService,
    private settingsCache: SettingsCacheService,
    private toastConfigService: ToastConfigService,
    private userDetailService: UserDetailService,
    private loaderService: LoaderService,
    private timeProvider: TimeProviderService,
    private schedulingErrorCodeService: SchedulingErrorCodeService
  ) { }

  confirmSession(sessionInfo: any) {
    this.validateAndUpdateConfirmSession(sessionInfo);
  }

  private validateAndUpdateConfirmSession(sessionInfo: any) {
    this.ValidateConfirmStatus(sessionInfo)
  }

  private ValidateConfirmStatus(sessionInfo: any) {
    let minConfirmSessionMinutes = this.settingsCache.minConfirmationLeadTime;
    let maxConfirmSessionMinutes = this.settingsCache.maxConfirmSessionMinutes;

    let latestConfirmTime = new Date(sessionInfo.startDateTime.valueOf() - minConfirmSessionMinutes * 60 * 1000);
    let earliestConfirmTime = new Date(sessionInfo.startDateTime.valueOf() - maxConfirmSessionMinutes * 60 * 1000);

    let currentTime = new Date();
    currentTime = this.timeProvider.currentTime();

    if (currentTime <= latestConfirmTime && currentTime >= earliestConfirmTime)
      this.updateSessionStatus(sessionInfo, SessionStatus.confirmed);
    else {
      this.showToast(this.transloco.translate("confirmSession.errorHeader"), this.transloco.translate("confirmSession.errorContent", {
        maxMinutes: maxConfirmSessionMinutes,
        minMinutes: minConfirmSessionMinutes
      }), "warn");
    }

  }

  cancelSession(sessionInfo: any) {
    sessionInfo.reason = "Canceled by Admin"; //When the session is cancelled from this frontend app it will always be cancelled by Admin
    this.ValidateCancelStatus(sessionInfo);
  }

  private ValidateCancelStatus(sessionInfo: any) {

    let minCancellationLeadTime = this.settingsCache.minCancellationLeadTime;
    let sessionStartDateTime = this.convertToUTCTime(sessionInfo.startDateTime);
    sessionStartDateTime.setMinutes(sessionStartDateTime.getMinutes() - minCancellationLeadTime);

    let currentTime = new Date();
    currentTime = this.convertToUTCTime(currentTime);

    if (sessionStartDateTime > currentTime)
      this.updateSessionStatus(sessionInfo, SessionStatus.cancelled);
    else {
      this.showToast(this.transloco.translate("cancelSession.errorHeader"), this.transloco.translate("cancelSession.errorContent", {
        minMinutes: minCancellationLeadTime
      }), "warn");
    }
  }

  private updateSessionStatus(sessionInfo: any, sessionStatus: SessionStatus) {
    this.loaderService.showLoader();
    this.updateSessionStatusInfo.sessionId = sessionInfo.sessionId;
    this.updateSessionStatusInfo.statusName = sessionStatus;
    this.updateSessionStatusInfo.updatedById = this.userDetailService.getUserDetail().userId;
    if (sessionInfo.reason !== undefined) {
      this.updateSessionStatusInfo.reason = sessionInfo.reason;
    }
    this.sessionService.updateSessionStatus(this.updateSessionStatusInfo)
      .pipe(
        finalize(() => this.loaderService.hideLoader())
      )
      .subscribe({
        next: (response) => {
          if (response.responseMessage == 'Ok') {
            this.showConfirmMessageAndLoadData(sessionInfo, sessionStatus);
          } else {
            this.showUpdateSessionToast(this.transloco.translate("genericErrorHeader"), this.transloco.translate("genericErrorContent"), "warn");
          }
        },
        error: (error) => {
          if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
            this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
          }
          else {
            this.showUpdateSessionToast(this.transloco.translate("genericErrorHeader"), this.transloco.translate("genericErrorContent"), "warn");
          }
        }
      });
  }

  private showConfirmMessageAndLoadData(sessionInfo: any, sessionStatus: SessionStatus) {
    if (sessionStatus === SessionStatus.cancelled)
      this.showToast('', this.transloco.translate("cancelSession.successMessage", {
        sessionName: sessionInfo.sessionName
      }), "success");
    else if (sessionStatus === SessionStatus.confirmed)
      this.showToast('', this.transloco.translate("confirmedSession.successMessage", {
        sessionName: sessionInfo.sessionName
      }), "success");
    this.loadSessionData.next();
  }

  private showToast(toastHeader: string, toastContent: string, toastType: string) {
    this.toastService.open(ToastComponent, {
      toastType: toastType as TopazToastType,
      toastHeader: toastHeader,
      toastContent: toastContent
    });
  }

  private showUpdateSessionToast(toastHeader: string, toastContent: string, toastType: string) {

    this.toastConfigService.setAutoDismissOff();

    this.toastServiceUpdateSession.open(ToastCustomComponent, {
      toastType: toastType as TopazToastType,
      header: toastHeader,
      contents: [toastContent],
      disableCloseButton: false
    });
  }

  private convertToUTCTime(date: Date) {
    var utcDateTime = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return utcDateTime;
  }

  updateStartSessionStatus(sessionInfo: any): Observable<boolean> {
    let userId = this.userDetailService.getUserDetail().userId ? this.userDetailService.getUserDetail().userId : 0;
    this.updateSessionStatusInfo.sessionId = sessionInfo.sessionId;
    this.updateSessionStatusInfo.statusName = SessionStatus.inprogress;
    this.updateSessionStatusInfo.updatedById = userId;

    return this.sessionService.updateSessionStatus(this.updateSessionStatusInfo).pipe(
      map((response) => {
        if (response.responseMessage == 'Ok') {
          this.loadSessionData.next();
          return true;
        } else {
          this.showUpdateSessionToast(this.transloco.translate("genericErrorHeader"), this.transloco.translate("genericErrorContent"), "warn");
          return false;
        }
      }),
      catchError((error) => {
        if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
          this.schedulingErrorCodeService.showErrorCodeToast(error.error.errorCode, error.error.traceId);
        }
        else {
          this.showUpdateSessionToast(this.transloco.translate("genericErrorHeader"), this.transloco.translate("genericErrorContent"), "warn");
        }

        return of(false)
      }));
  }
}
