import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { faEllipsis, faLaptop, faFilePen, faCheckCircle, faMessageLines } from '@fortawesome/pro-solid-svg-icons';
import { AllowedRoles, ISessionScheduleList, SessionStatus } from 'src/app/models/session';
import { EmailService } from 'src/app/services/email.service';
import { CancelSessionService } from 'src/app/services/cancel-session.service';
import { SessionStatusUpdateService } from 'src/app/services/session-status-update.service';
import { StartSessionService } from 'src/app/services/start-session.service';
import { EditViewSidesheetService } from '../../../services/edit-view-sidesheet.service';
import { UserDetailService } from '@certiport/login-library';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SessionStorageService } from '../../../services/session-storage.service';
import { SessionsManagerService } from 'src/app/services/sessions-manager.service';
import { tap } from 'rxjs';

@Component({
  selector: 'app-next-session',
  templateUrl: './next-session.component.html',
  styleUrls: ['./next-session.component.scss']
})
export class NextSessionComponent implements OnInit, OnChanges {
  @Input() nextSessionData: ISessionScheduleList[] = [];
  @Output() startSession = new EventEmitter<number>();
  proctorTableColumns: string[] = ['sessionStatus', 'dateAndTime', 'numberOfCandidates', 'numberOfExams', 'option'];
  orgAdminTableColumns: string[] = ['sessionStatus', 'dateAndTime', 'creator', 'proctor', 'option'];
  displayedColumns: string[] = ['sessionStatus', 'dateAndTime', 'option'];
  public matDataSource = new MatTableDataSource<ISessionScheduleList>(this.nextSessionData);
  private readonly orgAdminOrOrgMemberRoles: number[] = [AllowedRoles.orgadmin, AllowedRoles.orgmember];
  userRoles: number[] = [];
  sessionName: string = '';
  isConfirmSessionRequired: boolean = false;
  sessionDetails: any;

  isMobileUI: boolean = false;

  disableConfirmSession: boolean = true;
  disableCancelSession: boolean = true;
  disableStartSession: boolean = true;
  disableEditSession: boolean = true;
  disableResumeSession: boolean = true;

  public readonly icons = {
    faEllipsis,
    faLaptop,
    faFilePen,
    faCheckCircle,
    faMessageLines
  }
  public selection = new SelectionModel<ISessionScheduleList>(true, []);

  constructor(
    private editViewSidesheetService: EditViewSidesheetService,
    private sessionStatusUpdateService: SessionStatusUpdateService,
    private emailService: EmailService,
    private cancelSessionService: CancelSessionService,
    private startSessionService: StartSessionService,
    private userDetailService: UserDetailService,
    private breakpointObserver: BreakpointObserver,
    private sessionStorage: SessionStorageService,
    private sessionsManagerService: SessionsManagerService,) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe('(max-width: 37.5rem)')
      .subscribe(x => this.isMobileUI = x.matches);

    this.sessionsManagerService.sessionActionProsChange.pipe(
      tap((session) => {
        if (this.nextSessionData.length > 0 && this.nextSessionData[0].sessionId === session?.sessionId) {
          if (session) {
            this.nextSessionData = [session];
            this.getNextSessionSchedule();
          }
        }
      })
    ).subscribe();

    this.getNextSessionTable();
    this.getNextSessionSchedule();
    this.loadViewSession();
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['nextSessionData'].currentValue != changes['nextSessionData'].previousValue) {
      this.getNextSessionSchedule();
    }
  }

  loadViewSession() {
    const key = 'sessionid';
    const sessionid = +(this.sessionStorage.getItem(key) ?? 0);

    if (sessionid > 0) {
      this.sessionStorage.removeItem(key);
      this.viewSession(sessionid);
    }
  }

  getNextSessionTable() {
    this.userRoles = this.userDetailService.getUserDetail().userRoles;
    const isOrgAdminOrOrgMember = this.userRoles.some(r => this.orgAdminOrOrgMemberRoles.includes(r));
    if (isOrgAdminOrOrgMember) {
      this.displayedColumns = this.orgAdminTableColumns;
    }
    else {
      this.displayedColumns = this.proctorTableColumns;
    }
  }

  getNextSessionSchedule() {
    this.matDataSource.data = this.nextSessionData;
    this.sessionDetails = this.nextSessionData[0];
    this.sessionName = this.nextSessionData.map(x => x.sessionName).toString();

    this.disableConfirmSession = this.sessionDetails?.disableConfirmSession;
    this.disableCancelSession = this.sessionDetails?.disableCancelSession;
    this.disableStartSession = this.sessionDetails?.disableStartSession;
    this.disableEditSession = this.sessionDetails?.disableEditSession;
    this.disableResumeSession = this.sessionDetails?.disableResumeSession;
  }

  confirmSession() {
    this.sessionStatusUpdateService.confirmSession({ sessionId: this.sessionDetails.sessionId, sessionName: this.sessionName, startDateTime: this.sessionDetails.startDateTime });
  }

  viewSession(sessionId: number) {
    this.editViewSidesheetService.openViewSessionSidesheet(sessionId);
  }

  editSession(sessionId: number) {
    this.editViewSidesheetService.openEditSessionSidesheet(sessionId);
  }

  handleStartSession(sessionId: number) {
    this.startSession.emit(sessionId);
  }

  isSessionExist() {
    return this.matDataSource.data.length && this.matDataSource.filteredData.length;
  }

  viewDetails() {
    alert('TODO: View details');
  }

  isNoSessionExist(): boolean {
    return !this.matDataSource.data.length || !this.matDataSource.filteredData.length;
  }

  resendConfirmationEmail(sessionId: number) {
    this.emailService.resendConfirmatioEmail(sessionId);
  }

  cancelSession() {
    this.cancelSessionService.cancelSessionConfirmation({ sessionId: this.sessionDetails.sessionId, sessionName: this.sessionName, startDateTime: this.sessionDetails.startDateTime })
  }

  resumeSession(sessionId: number) {
    this.startSessionService.openExamSessionWindow(sessionId)
  }
}
