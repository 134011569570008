import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LockPaymentInput, PaymentInput, IPaymentType, UnlockPaymentInput } from '../models/payment';
import { ConfigService } from './config-service';
import { ICheckSlaExpirationsResponse } from '../models/check-slaexpirations';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private environment: any;
  constructor(private configService: ConfigService, private httpClient: HttpClient) {    
   }

   public checkPayment<T>(paymentInput: PaymentInput): Observable<T> {
    const url = `${this.configService.paymentServiceUrl}/CheckPayment`;
    return this.httpClient.post<T>(url, paymentInput, { withCredentials: true });
  }

  public lockPayment<T>(paymentInput: LockPaymentInput): Observable<T> {
    const url = `${this.configService.paymentServiceUrl}/LockPayment`;
    return this.httpClient.post<T>(url, paymentInput, { withCredentials: true });
  }

  public unlockPayment<T>(paymentInput: UnlockPaymentInput): Observable<T> {
    const url = `${this.configService.paymentServiceUrl}/UnlockPayment`;
    return this.httpClient.post<T>(url, paymentInput, { withCredentials: true });
  }
  
  getPaymentTypes(): Observable<IPaymentType[]> {
    const url = `${this.configService.paymentServiceUrl}/PaymentTypes`;
    return this.httpClient.get<IPaymentType[]>(url, { withCredentials: true });
  }

  CheckSlaExpiration(testCenterId: Number): Observable<ICheckSlaExpirationsResponse> {
    const url = `${this.configService.paymentServiceUrl}/CheckSlaExpiration?testCenterId=${testCenterId}`;
    return this.httpClient.get<ICheckSlaExpirationsResponse>(url, { withCredentials: true });
  }
}
