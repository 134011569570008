import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { BannerService, ToastService, TopazBannerType } from '@pearsonvue/topaz-angular-ui';
import { BannerCustomComponent } from '../components/banner-custom/banner-custom.component';
import { ToastCustomComponent } from '../components/toast-custom/toast-custom.component';
import { IBannerData } from '../models/bannerdata';
import { IToastData } from '../models/toastdata';
import { ToastManagerService } from './toast-manager.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulingErrorCodeService {

  constructor(private transloco: TranslocoService, private bannerService: BannerService<IBannerData>,
    private toastService: ToastService<IToastData>,
    private toastManager: ToastManagerService) { }

  showErrorCodeBanner(errorCode: number, traceId: string) {
    const errHeader = this.transloco.translate('serverError.header', { errorCode: errorCode, traceId: traceId });
    const errContent = this.transloco.translate('serverError.contactAdmin');
    this.openErrorBanner('warn', errHeader, errContent);
  }

  private openErrorBanner(bannerDisplayType: TopazBannerType, bannerHeader: string, bannerContent: string): void {
    this.bannerService.open(BannerCustomComponent, {
      bannerType: bannerDisplayType,
      header: bannerHeader,
      contents: [bannerContent],
      disableCloseButton: false
    });
  }

  showErrorCodeToast(errorCode: number, traceId: string) {
    const errHeader = this.transloco.translate('serverError.header', { errorCode: errorCode, traceId: traceId });
    const errContent = this.transloco.translate('serverError.contactAdmin');
    this.toastManager.showStickyToast(errHeader, errContent, 'warn');
  }

  showGenericErrorToast() {
    const errHeader = this.transloco.translate('genericErrorHeader');
    const errContent = this.transloco.translate('genericErrorContent');
    this.toastManager.showStickyToast(errHeader, errContent, 'warn');
  }
}
