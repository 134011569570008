<div *transloco="let t">
  <mat-card class="topaz-table-container">
    <div class="next-session-layout">
      <div class="next-session-title">
        <h2 autofocus>{{ t('nextSessionTable.title') }}</h2>
      </div>
      <div class="next-session-name">
        <span class="span-session-name">{{sessionName}}</span>
      </div>
    </div>
    <table cdk-table [dataSource]="matDataSource" class="topaz-table full-width cpttable">
      <caption class="table-caption">
        Table of elements
      </caption>
      <ng-container cdkColumnDef="sessionStatus">
        <th scope="col" cdk-header-cell *cdkHeaderCellDef>
          {{ t('scheduleTable.sessionStatus') }}
        </th>
        <td cdk-cell *cdkCellDef="let element" [ngSwitch]="element.sessionStatus">
          <span class="topaz-table-mobile-header word-wrap">{{ t('scheduleTable.sessionStatus') }}</span>
          <div class="capsule-div" *ngSwitchCase="'Confirmed'">
            <topaz-capsule color="accent" class="cptcapsule">
              <fa-icon class="session-status-icon session-status-icon" [icon]="icons.faCheckCircle"></fa-icon>
              {{ t('scheduleTableStatus.confirmed') }}
            </topaz-capsule>
          </div>
          <div class="capsule-div" *ngSwitchCase="'Confirm Session'">
            <topaz-capsule color="alert" class="cptcapsule">
              <fa-icon class="session-status-icon confirmed-Session-icon" [icon]="icons.faMessageLines"></fa-icon>
              <span class="confirmed-Session-label"> {{ t('scheduleTableStatus.confirmSession') }}</span>
            </topaz-capsule>
          </div>
          <div class="capsule-div" *ngSwitchCase="'In-progress'">
            <topaz-capsule class="cptcapsule">
              <fa-icon class="session-status-icon inprogress-icon" [icon]="icons.faLaptop"></fa-icon>
              <span class="inprogress-label">{{ t('scheduleTableStatus.inprogress') }}</span>
            </topaz-capsule>
          </div>
          <div class="capsule-div" *ngSwitchDefault>
            <topaz-capsule color="primary" class="cptcapsule">
              <fa-icon class="session-status-icon" [icon]="icons.faFilePen"></fa-icon>
              {{ t('scheduleTableStatus.scheduled') }}
            </topaz-capsule>
          </div>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="dateAndTime">
        <th scope="col" cdk-header-cell *cdkHeaderCellDef>
          {{ t('scheduleTable.dateAndTime') }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          <span class="topaz-table-mobile-header word-wrap">{{ t('scheduleTable.dateAndTime') }}</span>
          {{element.startDateTimeDisplay | ctpDate:'MMMM d, y'}} {{ element.startDateTimeDisplay | ctpDate:'h:mm a'}}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="numberOfCandidates">
        <th scope="col" cdk-header-cell *cdkHeaderCellDef>
          {{ t('scheduleTable.numberOfCandidates') }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          <span class="topaz-table-mobile-header word-wrap">{{ t('scheduleTable.numberOfCandidates') }}</span>
          {{ element.numberOfCandidates }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="numberOfExams">
        <th scope="col" cdk-header-cell *cdkHeaderCellDef>
          {{ t('scheduleTable.numberOfExams') }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          <span class="topaz-table-mobile-header word-wrap">{{ t('scheduleTable.numberOfExams') }}</span>
          {{ element.numberOfExams }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="creator">
        <th scope="col" cdk-header-cell *cdkHeaderCellDef>
          {{ t('scheduleTable.creator') }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          <span class="topaz-table-mobile-header word-wrap">{{ t('scheduleTable.creator') }}</span>
          {{ element.creatorName }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="proctor">
        <th scope="col" cdk-header-cell *cdkHeaderCellDef>
          {{ t('scheduleTable.proctor') }}
        </th>
        <td cdk-cell *cdkCellDef="let element">
          <span class="topaz-table-mobile-header word-wrap">{{ t('scheduleTable.proctor') }}</span>
          {{ element.proctorName }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="option">
        <th scope="col" cdk-header-cell *cdkHeaderCellDef class="topaz-row-option">
          <span>{{ t('scheduleTable.options') }}</span>
        </th>
        <td cdk-cell *cdkCellDef="let row" class="topaz-row-option">
          <button mat-icon-button [mat-menu-trigger-for]="optionMenu" [title]="t('scheduleTable.sessionOptions', {sessionName:row.sessionName})" attr.aria-label="{{t('scheduleTable.sessionOptions', {sessionName:row.sessionName})}}">
            <fa-icon [icon]="icons.faEllipsis"></fa-icon>
          </button>

          <mat-menu #optionMenu="matMenu">
            <button mat-menu-item [disabled]="disableConfirmSession" (click)="confirmSession()">
              {{ t('scheduleTableOptions.confirmSession') }}
            </button>
            <button mat-menu-item *ngIf="!disableResumeSession" (click)="resumeSession(row.sessionId)">
              {{ t('scheduleTableOptions.ResumeSession') }}
            </button>
            <button *ngIf="!disableStartSession" mat-menu-item (click)="handleStartSession(sessionDetails.sessionId)" [disabled]="disableStartSession">
              {{ t('scheduleTableOptions.startSession') }}
            </button>
            <button mat-menu-item (click)="viewSession(row.sessionId)">
              {{ t('scheduleTableOptions.viewDetails') }}
            </button>
            <button mat-menu-item (click)="editSession(row.sessionId)" [disabled]="disableEditSession">
              {{ t('scheduleTableOptions.edit') }}
            </button>
            <button mat-menu-item (click)="resendConfirmationEmail(row.sessionId)">
              {{ t('scheduleTableOptions.resendConfirmationEmail') }}
            </button>
            <button mat-menu-item [ngClass]="{'topaz-warn': row.disableCancelSession === false}" (click)="cancelSession()" [disabled]="row.disableCancelSession">
              {{ t('scheduleTableOptions.cancelSession') }}
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
      <tr cdk-row *cdkRowDef="let row; columns: displayedColumns" [ngClass]="{ selected: selection.isSelected(row) }">
      </tr>
    </table>
    <div *ngIf="isNoSessionExist()" class="no-data-section"> {{ t('scheduleTable.noUpcomingSessions') }}</div>

    <div class="divider" *ngIf="isSessionExist()"></div>

    <div *ngIf="isSessionExist()">
      <div class="buttons-nextsession-layout" *ngIf="!isMobileUI">
        <div class="buttons">
          <button mat-flat-button [disabled]="disableStartSession" color="primary" (click)="handleStartSession(sessionDetails.sessionId)">{{ t('nextSessionTableButton.startSession') }}</button>
          <button mat-stroked-button color="primary" (click)="editSession(sessionDetails.sessionId)" [disabled]="disableEditSession">{{ t('nextSessionTableButton.editSession') }}</button>
          <div *ngIf="!disableConfirmSession; then showConfirmSession else showViewSession"></div>
          <ng-template #showConfirmSession><button mat-flat-button color="primary" (click)="confirmSession()">{{ t('nextSessionTableButton.confirmSession') }}</button></ng-template>
          <ng-template #showViewSession><button mat-stroked-button color="primary" #elseBlock (click)="viewSession(sessionDetails.sessionId)">{{ t('scheduleTableOptions.viewDetails') }}</button></ng-template>
        </div>
      </div>

      <div [ngSwitch]="sessionDetails.sessionStatus" *ngIf="isMobileUI">
        <div class="m-buttons" *ngSwitchCase="'Scheduled'">
          <button mat-stroked-button
                  color="primary"
                  class="topaz-button-small"
                  (click)="viewSession(sessionDetails.sessionId)">
            {{ t('scheduleTableOptions.viewDetails') }}
          </button>

          <button mat-stroked-button
                  color="primary"
                  class="topaz-button-small"
                  (click)="editSession(sessionDetails.sessionId)">
            {{ t('nextSessionTableButton.editSession') }}
          </button>
        </div>

        <div class="m-buttons" *ngSwitchCase="'Confirm Session'">
          <button mat-stroked-button
                  color="primary"
                  class="topaz-button-small"
                  (click)="editSession(sessionDetails.sessionId)">
            {{ t('nextSessionTableButton.editSession') }}
          </button>

          <button mat-flat-button
                  color="primary"
                  class="topaz-button-small"
                  *ngIf="!sessionDetails?.disableConfirmSession"
                  (click)="confirmSession()">
            {{ t('nextSessionTableButton.confirmSession') }}
          </button>

          <button mat-stroked-button
                color="primary"
                class="topaz-button-small"
                *ngIf="sessionDetails?.disableConfirmSession"
                (click)="viewSession(sessionDetails.sessionId)">
          {{ t('scheduleTableOptions.viewDetails') }}
          </button>
        </div>

        <div class="m-buttons" *ngSwitchCase="'Confirmed'">
          <button mat-stroked-button
                color="primary"
                class="topaz-button-small"
                (click)="viewSession(sessionDetails.sessionId)">
          {{ t('scheduleTableOptions.viewDetails') }}
          </button>

          <button mat-stroked-button
                  color="primary"
                  class="topaz-button-small"
                  *ngIf="disableStartSession"
                  [disabled]="disableEditSession"
                  (click)="editSession(sessionDetails.sessionId)">
            {{ t('nextSessionTableButton.editSession') }}
          </button>

          <button mat-flat-button
                  color="primary"
                  class="topaz-button-small"
                  *ngIf="!disableStartSession"
                  (click)="handleStartSession(sessionDetails.sessionId)">
            {{ t('nextSessionTableButton.startSession') }}
          </button>
        </div>
      </div>
    </div>
  </mat-card>
</div>
