import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { UserDetailService } from '@certiport/login-library';
import { TranslocoService } from '@jsverse/transloco';
import { LoaderService, SidenavService } from 'certiport-layout-library';
import { Observable, Subscription, map } from 'rxjs';
import { AllowedRoles, ISessionScheduleList, SessionStatus } from 'src/app/models/session';
import { ITestCenterSignalR } from 'src/app/models/testcenter-signalr';
import { SessionListService } from 'src/app/services/session-list.service';
import { SessionValidationService } from 'src/app/services/session-validation.service';
import { SessionService } from 'src/app/services/session.service';
import { SignalRService } from 'src/app/services/signal-r.service';
import { TimeProviderService } from 'src/app/services/time-provider.service';
import { SessionScheduleTableComponent } from '../dashboard/session-schedule-table/session-schedule-table.component';
import { HttpStatusCode } from '@angular/common/http';
import { SchedulingErrorCodeService } from 'src/app/services/scheduling-error-code.service';
import { ToastCustomComponent } from '../toast-custom/toast-custom.component';
import { ToastService } from '@pearsonvue/topaz-angular-ui';
import { IToastData } from '@cbb/models';
import { ToastConfigService } from 'src/app/services/toast-config.service';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';
import { SessionStatusUpdateService } from 'src/app/services/session-status-update.service';

@Component({
  selector: 'app-view-sessions',
  templateUrl: './view-sessions.component.html',
  styleUrls: ['./view-sessions.component.scss']
})
export class ViewSessionsComponent implements OnInit, OnDestroy {
  userId: number = 0;
  sessionScheduleData: ISessionScheduleList[] = [];;
  isSessionLoaded: boolean = false;
  userRoles: number[] = [];
  hideOptions: boolean = false;
  loadSessionScheduleSubscription: Subscription | null = null;
  subscriptions: Subscription[] = [];
  searchValue: string = "";
  currentTabIndex: number = 0;
  noDataText: string = "";
  search = new FormControl();
  settingsLoaded: boolean = false;
  loadSessionDataSubscription: Subscription | null = null;

  sidenavSubscription!: Subscription;
  testCenterSignalR: ITestCenterSignalR[] = [];
  signalRIds: string[] = [];
  @ViewChild(SessionScheduleTableComponent) sessionScheduleTableComponent!: SessionScheduleTableComponent;

  constructor(
    private route: Router,
    private sessionListService: SessionListService,
    private timeProviderService: TimeProviderService,
    private sessionValidationService: SessionValidationService,
    private userDetailService: UserDetailService,
    private transloco: TranslocoService,
    private loaderService: LoaderService,
    private sidenavService: SidenavService,
    private sessionService: SessionService,
    private signalR: SignalRService,
    private schedulingErrorCodeService: SchedulingErrorCodeService,
    private toastService: ToastService<IToastData>,
    private toastConfigService: ToastConfigService,
    private settingsCache: SettingsCacheService,
    private sessionStatusUpdateService: SessionStatusUpdateService,) { }

  ngOnInit(): void {
    //We load settings here not because this component uses settings directly, but because it is a top-level component (associated with a route).
    //Also, this component indirectly references components that do require settings.
    this.subscriptions.push(this.settingsCache.load().pipe(
      map(loaded => {
        this.settingsLoaded = loaded;
      })
    ).subscribe());

    this.initSidenav();
    this.subscribeToSidenav();
    this.loadSessionDataSubscription =
      this.sessionStatusUpdateService.loadSessionData.subscribe(() => this.upcomingSession());

    this.userId = this.userDetailService.getUserDetail().userId;
    this.getTestCenterSignalR(this.userId);
    if (this.sessionListService.isAllowedRole()) {
      this.loaderService.showLoader();
      this.loadSessionScheduleSubscription =
        this.getSessions(this.userId, this.timeProviderService.currentTime().toISOString()).subscribe((response) => {
          this.sessionScheduleData = response.filter(session => session.sessionStatus != SessionStatus.cancelled);
          this.cleanupDates(this.sessionScheduleData);
          this.mapSessionActivites();
          this.isSessionLoaded = true;
          this.noDataText = this.transloco.translate('scheduleTable.noUpcomingSessions');
          this.loaderService.hideLoader();
        }, (error) => {
          this.isSessionLoaded = true;
          this.loaderService.hideLoader();
        })
    }
  }

  initSidenav() {
    this.sidenavService.sidenavSubject
      .next({
        heading: 'session.view.schedule',
        buttonName: 'session.view.create.session',
        showArrow: false
      });
  }

  subscribeToSidenav() {
    this.sidenavSubscription = this.sidenavService
      .buttonClickSubject
      .subscribe(_ => this.handleCreateSession());
  }

  cleanupDates(sessionScheduleData: ISessionScheduleList[]) {
    sessionScheduleData.forEach((item) => {
      item.startDateTime = this.cleanupDate(item.startDateTime);
    });
  }

  cleanupDate(date: Date): Date {
    if (typeof date === 'string') {
      let t: string = date as unknown as string;
      if (!t.includes('Z')) {
        t += 'Z';
        date = new Date(Date.parse(t))
      }
      else {
        date = new Date(Date.parse(t))
      }
    }
    return date;
  }

  handleCreateSession(): void {
    this.route.navigate(['/new-session']);
  }

  handleSearch(value: string): void {
    this.searchValue = value;
  }

  mapSessionActivites(): void {
    this.sessionScheduleData.forEach((item) => {
      item.disableCancelSession = (this.sessionValidationService.shouldCancelSessionBeDisabled(item));
      item.disableConfirmSession = (item.sessionStatus !== SessionStatus.confirmSession || this.sessionValidationService.shouldConfirmSessionBeDisabled(item) || !this.isLoggedInUserTheSessionProctor(item.proctorId));
      item.disableStartSession = this.sessionValidationService.shouldStartSessionBeDisabled(item);
      item.disableEditSession = this.sessionValidationService.shouldEditSessionBeDisabled(item.sessionStatus, item.startDateTime);
      item.disableResumeSession = this.sessionValidationService.shouldResumeSessionBeDisabled(item);
      if (item.sessionStatus === SessionStatus.cancelled) {
        if (item.updatedById !== null && item.updatedById > 0) {
          item.sessionStatus = 'User Cancellation'
        } else {
          item.sessionStatus = 'System Cancellation'
        }
      }
    })
  }

  getSessionScheduleData() {
    this.handleSelectedTabChange({ index: this.currentTabIndex });
  }

  handleSelectedTabChange(event: any): void {
    this.isSessionLoaded = false;
    if (this.sessionListService.isAllowedRole()) {
      if (event.index === 0) {
        this.upcomingSession();
        this.currentTabIndex = 0;
        this.noDataText = this.transloco.translate('scheduleTable.noUpcomingSessions');
      } else {
        this.pastSession();
        this.currentTabIndex = 1;
        this.noDataText = this.transloco.translate('scheduleTable.noPastSessions');
      }
    }
  }

  private upcomingSession() {
    this.loaderService.showLoader();
    this.loadSessionScheduleSubscription = this.getSessions(this.userId, this.timeProviderService.currentTime().toUTCString()).subscribe({
      next: (response) => {
        this.sessionScheduleData = response.filter(session => session.sessionStatus != SessionStatus.cancelled);
        this.cleanupDates(this.sessionScheduleData);
        this.mapSessionActivites();
        this.hideOptions = false;
        this.isSessionLoaded = true;
        this.loaderService.hideLoader();
      },
      error: () => {
        this.isSessionLoaded = true;
        this.loaderService.hideLoader();
      }
    });
  }

  private pastSession() {
    this.loaderService.showLoader();
    this.loadSessionScheduleSubscription = this.getSessions(this.userId).subscribe({
      next: (response: ISessionScheduleList[]) => {
        this.sessionScheduleData = response.filter(session => this.handlePastSessionFilter(session));
        this.cleanupDates(this.sessionScheduleData);
        this.sessionScheduleData.sort((a, b) => b.startDateTime.getTime() - a.startDateTime.getTime());
        this.sessionScheduleData.forEach((schdule: ISessionScheduleList) => {
          if (schdule.sessionStatus === SessionStatus.cancelled) {
            if (schdule.updatedById !== null && schdule.updatedById > 0) {
              schdule.sessionStatus = 'User Cancellation'
            } else {
              schdule.sessionStatus = 'System Cancellation'
            }
          }
        });
        this.hideOptions = true;
        this.isSessionLoaded = true;
        this.loaderService.hideLoader();
      },
      error: () => {
        this.isSessionLoaded = true;
        this.loaderService.hideLoader();
      }
    });
  }

  handlePastSessionFilter(session: ISessionScheduleList): boolean {
    return (session.sessionStatus === SessionStatus.complete || session.sessionStatus === SessionStatus.cancelled)
  }

  getSessions(userID: number, startDate?: string): Observable<ISessionScheduleList[]> {
    return this.sessionListService.getSessions(userID, startDate);
  }

  isLoggedInUserTheSessionProctor(proctorId: number) {
    this.userRoles = this.userDetailService.getUserDetail().userRoles;
    const loggedInUserId = this.userDetailService.getUserDetail().userId;
    const isLoggedInUserHasProctorRole = this.userRoles.includes(AllowedRoles.proctor);

    if (!isLoggedInUserHasProctorRole || loggedInUserId != proctorId) {
      return false;
    }

    return true;
  }

  private getTestCenterSignalR(userId: number) {
    this.sessionService.getTestCenterSignalR(userId).subscribe({
      next: (response) => {
        this.testCenterSignalR = response;
        this.signalRIds = this.testCenterSignalR.map(t => t.signalRId);
        this.InvokeSignalR(this.signalRIds);
      },
      error: (errorResponse) => {
        if (errorResponse.status >= HttpStatusCode.InternalServerError && errorResponse?.error?.errorCode) {
          this.schedulingErrorCodeService.showErrorCodeToast(errorResponse?.error?.errorCode, errorResponse?.error?.traceId);
        }
        else {
          this.showToast();
        }
      }
    });
  }

  private InvokeSignalR(testCenterSignalRId: string[]) {
    this.signalR.startGroupIds(testCenterSignalRId)
      .then(() => {
        this.signalR.addCreateSessionListener((msg) => {
          this.onCreateSession(msg);
        }, testCenterSignalRId);
      });
  }

  private onCreateSession(msg: string): void {
    var sessionInfoString = JSON.stringify(msg);
    var sessionDetails: ISessionScheduleList = JSON.parse(sessionInfoString);;
    sessionDetails.startDateTime = this.cleanupDate(sessionDetails.startDateTime);
    this.sessionScheduleData.push(sessionDetails);
    this.sessionScheduleData.sort((a, b) => a.startDateTime.getTime() - b.startDateTime.getTime());
    this.mapSessionActivites();
    this.sessionScheduleTableComponent.getSessionSchedule();
  }

  private showToast() {
    this.toastConfigService.setAutoDismissOff();
    this.toastService.open(ToastCustomComponent, {
      toastType: 'warn',
      header: this.transloco.translate("genericErrorHeader"),
      contents: [this.transloco.translate("genericErrorContent")],
      disableCloseButton: false
    });
  }

  ngOnDestroy(): void {
    this.loadSessionScheduleSubscription?.unsubscribe();
    this.loadSessionDataSubscription?.unsubscribe();
    this.sidenavSubscription?.unsubscribe();
    this.signalR.removeCreateSessionListener(this.onCreateSession)
    this.signalR.stop();
  }
}
