import { Component } from '@angular/core';
import { IBannerData } from '../../../models/bannerdata';
import { IExpiredSiteLicense } from 'src/app/models/expired-sitelicense';

@Component({
  selector: 'app-banner-edit-session',
  templateUrl: './banner-edit-session.component.html',
  styleUrls: ['./banner-edit-session.component.scss']
})
export class BannerEditSessionComponent {

  showBanner = false;
  bannerContentLength = 0;
  bannerData!: IBannerData;
  expiredSiteLicense: IExpiredSiteLicense[] = [];

  constructor() { }

  actionEvent() {
    this.hide();
  }

  public show(bannerData: IBannerData) {
    this.bannerData = bannerData;
    this.bannerContentLength = bannerData.contents.length;
    this.expiredSiteLicense=bannerData?.metadata?.expiredSiteLicense;

    this.showBanner = true;
  }

  hide() {
    this.showBanner = false;
  }
}
