<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" *transloco="let t;">
  <mat-tab *ngFor="let sessionExam of sessionExams; let i = index" label="{{t('viewSessionExam.tabLabel')}} {{(i+1)}}">

    <div class="grid-container">
      <div class="grid-col-span-2">
        <mat-label>{{t('viewSessionExam.examName')}}</mat-label><br>
        <div>
          <h4 class="exam-name-data" autofocus>{{sessionExam.examName}} {{sessionExam.autodeskVersion}}</h4>
        </div>
      </div>
      <div>
        <div>
          <mat-label>{{t('viewSessionExam.NoOfCandidates')}}
          <fa-icon
            *ngIf="isUserAssignedPayment"
            [icon]="icons.faInfoCircle"
            matIconSuffix
            matTooltip="{{ t('user-assigned-voucher-notes') }} {{ t('session_max_candidate') }}"
            matTooltipPosition="before"
          ></fa-icon></mat-label>
        </div>
        <div>
          <span>{{sessionExam.candidateCount}}</span>
        </div>
      </div>
      <div>
        <div>
          <mat-label>{{t('accessCode')}}</mat-label>
        </div>
        <div>
          <span>{{sessionExam.examCode}}</span>
        </div>
      </div>
      <div>
        <div>
          <mat-label>{{t('viewSessionExam.examLength')}}</mat-label>
        </div>
        <div>
          <span>{{sessionExam.duration}} {{t('examDurationUnit')}}</span>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>

