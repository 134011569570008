import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ICheckSlaExpirations, ICheckSlaExpirationsResponse } from '../../../models/check-slaexpirations';
import { BannerComponent, BannerService } from '@pearsonvue/topaz-angular-ui';


@Component({
  selector: 'app-expiring-sla-programs-banner',
  templateUrl: './expiring-sla-programs-banner.component.html',
  styleUrls: ['./expiring-sla-programs-banner.component.scss']
})
export class ExpiringSlaProgramsBannerComponent implements OnInit{
  slaExpirationsResponse!: ICheckSlaExpirationsResponse | null;
  @ViewChild(BannerComponent<ICheckSlaExpirationsResponse>, { static: true }) public banner!: BannerComponent<ICheckSlaExpirationsResponse>;
  constructor(
    private bannerService: BannerService<ICheckSlaExpirationsResponse>
  ) {
  }

  ngOnInit(): void {
    this.slaExpirationsResponse = this.bannerService.getBannerData(this.banner);
  }
}
