import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TopazOverlayRef } from '@pearsonvue/topaz-angular-ui';
import { differenceInMinutes } from 'date-fns';
import { Subscription, map } from 'rxjs';
import { ISession } from '../../../models/session';
import { EditViewSidesheetService } from '../../../services/edit-view-sidesheet.service';
import { SessionService } from '../../../services/session.service';
import { IBannerData } from '@cbb/models';
import { TranslocoService } from '@jsverse/transloco';
import { BannerEditSessionComponent } from '../../edit-session-layout/banner-edit-session/banner-edit-session.component';
import { HttpStatusCode } from '@angular/common/http';
import { LoaderService } from 'certiport-layout-library';
import { PaymentTypesConstants } from 'src/app/payment-type-constants';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';

@Component({
  selector: 'app-session-detail-view',
  templateUrl: './session-detail-view.component.html',
  styleUrls: ['./session-detail-view.component.scss']
})
export class SessionDetailViewComponent implements OnInit, OnDestroy, AfterViewInit {

  sessionId: number;
  sessionInfo = { sessionName: "" } as ISession;
  minUpdateLeadTime !: number;
  isDisable = true;
  timeDiff!: number;
  startTimeMinutes = 0;
  settingsLoaded: boolean = false;
  private loadSettingsSubscription: Subscription | null = null;

  @ViewChild(BannerEditSessionComponent)  bannerEditSession!: BannerEditSessionComponent;
  isUserAssignedPayment: boolean = false;

  constructor(
    private sessionService: SessionService,
    private sidesheetRef: TopazOverlayRef,
    private editViewSidesheetService: EditViewSidesheetService,
    private settingsCache: SettingsCacheService,
    private transloco: TranslocoService,
    private loaderService: LoaderService) {
    this.sessionId = this.sidesheetRef.data;
  }

  ngOnInit(): void {
    this.loadSettingsSubscription = this.settingsCache.loaded.pipe(
      map(loaded => {
        this.settingsLoaded = loaded;
        this.initialize();
      })
    ).subscribe();
  }

  private initialize(): void {
    this.isDisable = true;
    this.getSessionInfo();
  }

  ngAfterViewInit(): void {
    this.editViewSidesheetService.bannerEditSession = this.bannerEditSession;
  }

  ngOnDestroy(): void {
    if (this.loadSettingsSubscription)
      this.loadSettingsSubscription.unsubscribe();
  }

  getSessionInfo() {
    this.loaderService.showLoader();
    this.sessionService.getSession(this.sessionId).subscribe(
      (data: ISession) => {
        this.sessionInfo = data;
        this.isUserAssignedPayment = this.sessionInfo.paymentMethodName.toLocaleLowerCase() === PaymentTypesConstants.USER_ASSIGNED_VOUCHER.toLocaleLowerCase() ? true : false;
        var timeString = this.sessionInfo.startDateTimeDisplay.toString().split('T')[1].split(':');
        this.startTimeMinutes = parseInt(timeString[1]);
        this.getSettings();
        this.loaderService.hideLoader();
      },
      error => {
        this.loaderService.hideLoader();
        if (error.status ==  HttpStatusCode.InternalServerError) {
          this.showErrorBanner(this.transloco.translate('serverError.header', {errorCode: error.error.errorCode, traceId: error.error.traceId}),this.transloco.translate('serverError.contactAdmin'));
        }
      });
  }

  getSettings() {
    this.minUpdateLeadTime = this.settingsCache.minUpdateLeadTime;
    this.isDisableEdit();
  }

  isDisableEdit() {
    let currentTime = new Date(new Date().getTime() + (new Date().getTimezoneOffset() * 60000));
    const timeDiff = differenceInMinutes(new Date(this.sessionInfo.startDateTime), new Date(currentTime));
    this.isDisable = !(timeDiff > this.minUpdateLeadTime);
  }

  public editSession(): void {
    this.sidesheetRef?.close(null, undefined, true);
    this.editViewSidesheetService.openEditSessionSidesheet(this.sessionId);
  }

  private showErrorBanner(header: string, content: string) {
    let bannerData: IBannerData = {
      bannerType: 'warn',
      header: header,
      contents: [content],
      disableCloseButton: true
    };
    this.editViewSidesheetService.showErrorBanner(bannerData);
  }
}
