import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IExamLanguage } from '@cbb/models';
import { TranslocoService } from '@jsverse/transloco';
import { Observable, tap, catchError, startWith, pairwise, Subscription, EMPTY } from 'rxjs';
import { ExamProductService } from 'src/app/services/exam-product.service';
import { CreateSessionWarningDialogComponent } from '../../create-session/create-session-warning-dialog/create-session-warning-dialog.component';
import { CommonmethodsService } from '../create-session-services/commonmethods.service';
import { CreateSessionDialogService } from '../create-session-services/create-session-dialog.service';
import { CreatesessiontoastService } from '../create-session-services/createsessiontoast.service';
import { HttpStatusCode } from '@angular/common/http';
import { SchedulingErrorCodeService } from 'src/app/services/scheduling-error-code.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html'
})
export class LanguageComponent implements OnInit {

  form!: FormGroup
  examLanguages$!: Observable<IExamLanguage[]>;
  subscriptions: Subscription[] = [];
  isExamLanguageSelected: boolean = false;
  isExamSelected: boolean = false;
  constructor(private t: TranslocoService, private rootFormGroup: FormGroupDirective,
    private examProductService: ExamProductService, private createsessiontoastService: CreatesessiontoastService,
    private dialog: MatDialog, private createSessionDialogService: CreateSessionDialogService, private commonMethodsSerive: CommonmethodsService, private schedulingErrorCodeService: SchedulingErrorCodeService) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
    this.examLanguages$ = this.getExamLanguages();
    this.subscribeExamLanguageValueChanges();
    this.subscribeTestChangeValue();
  }

  private getExamLanguages(): Observable<IExamLanguage[]> {
    return this.examProductService.getExamLanguages()
      .pipe(
        tap((examLanguages: IExamLanguage[]) => {
          if (examLanguages.length === 1) {
            this.getControl('examLanguage')?.patchValue(examLanguages[0]);
          }
        }),
        catchError((error) => {
          if (error?.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
            this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
          }
          else 
          this.createsessiontoastService.showGenericErrorAndLogToConsole('Failed to get languages. ' + JSON.stringify(error));
          return EMPTY;
        }))
  }

  private subscribeExamLanguageValueChanges() {
    const examLanguageChange$ = this.getControl('examLanguage')?.valueChanges
      .pipe(startWith(''), pairwise())
      .subscribe(([prev, next]: [IExamLanguage, IExamLanguage]) => {
        if (prev && next && prev.languageCode !== next.languageCode && this.isExamSelected) {
          this.dialog.open(CreateSessionWarningDialogComponent, this.createSessionDialogService.getExamLanguageWarningDialogData())
            .afterClosed()
            .subscribe((value: string) => {
              if (value && value.toLowerCase() === 'yes') {
                this.commonMethodsSerive.loadExamsNotify();
                this.isExamSelected = false;
              } else {
                this.getControl('examLanguage')?.reset();
                this.getControl('examLanguage')?.setValue(prev);
              }
            });
        } else if ((next && !this.isExamLanguageSelected) || (prev && next && prev.languageCode !== next.languageCode)) {
          this.isExamLanguageSelected = true;
          this.commonMethodsSerive.loadExamsNotify();
        }
      });

    this.addToSubscriptions(examLanguageChange$);
  }

  private subscribeTestChangeValue() {
    const testCenterChange$ = this.commonMethodsSerive.testCenterChange$.subscribe(() => {
      this.isExamLanguageSelected = false;
    });
    this.addToSubscriptions(testCenterChange$);
  }

  private addToSubscriptions(subscription: (Subscription | undefined)) {
    if (subscription) {
      this.subscriptions.push(subscription);
    }
  }

  getControl(name: string): AbstractControl | null {
    return this.form.get(name);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s?.unsubscribe());
  }

}
