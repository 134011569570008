import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ISession } from '../../../models/session';
import { TopazComponentCDKPortal } from '@pearsonvue/topaz-angular-ui';
import { IBannerData } from '../../../models/bannerdata';
import { EditViewSidesheetService } from '../../../services/edit-view-sidesheet.service';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';

@Component({
  selector: 'app-edit-session-exams',
  templateUrl: './edit-session-exams.component.html',
  styleUrls: ['./edit-session-exams.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class EditSessionExamsComponent implements OnInit {

  public icons = {
    faInfoCircle
  }

  constructor(
    private t: TranslocoService,
    private editViewSidesheetService: EditViewSidesheetService,
    private settingsCache: SettingsCacheService
  ) { }

  minCandidateCount = 0;
  maxCandidateCount = 0;
  bannerRefrence!: TopazComponentCDKPortal;
  @Input() session!: ISession;
  @Input() examCount!: number;
  @Output() onEditExamSessionsValidated = new EventEmitter();
  @Input() isUserAssignedPayment: boolean = false;

  ngOnInit(): void {
    this.editViewSidesheetService.onEditSaveClick().subscribe(
      () => this.validate()
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.minCandidateCount = this.settingsCache.minCandidateCount;
    this.maxCandidateCount = this.settingsCache.maxCandidateCount;
  }

  validate() {
    if (!this.isUserAssignedPayment) {
      let totalCandidateCount = this.session.sessionExams.reduce((total, obj) => total + obj.candidateCount, 0);
      if (this.session.sessionExams.filter(x => Number(x.candidateCount) == 0).length > 0) {
        this.showErrorBanner();
      }
      else if (this.session.sessionExams.filter(x => x.candidateCount > this.maxCandidateCount).length > 0) {
        this.showErrorBanner();
      }
      else if (totalCandidateCount > this.maxCandidateCount) {
        this.showErrorBannerMaxCount();
      }
      else {
        this.closeBanner();
        this.onEditExamSessionsValidated.emit();
      }
    }
    else {
      this.closeBanner();
      this.onEditExamSessionsValidated.emit();
    }
  }

  showErrorBanner() {
    let bannerData: IBannerData = {
      bannerType: 'warn',
      header: this.t.translate("editSession.errorBannerHeader"),
      contents: [],
      disableCloseButton: true
    };
    this.editViewSidesheetService.showErrorBanner(bannerData);
  }

  showErrorBannerMaxCount() {
    let bannerData: IBannerData = {
      bannerType: 'warn',
      header: this.t.translate('editSessionExam.candidateCountMinMaxSave', { maxCandidateCount: this.maxCandidateCount }),
      contents: [],
      disableCloseButton: true
    };
    this.editViewSidesheetService.showErrorBanner(bannerData);
  }

  closeBanner(): void {
    this.editViewSidesheetService.closeBanner();
  }

  removeExam(examSessionId: number) {
    var sessionExam = this.session.sessionExams.filter(x => x.sessionExamId == examSessionId)[0];
    this.session.sessionExams = this.session.sessionExams.filter(item => item !== sessionExam);
    this.examCount--;
  }
}
