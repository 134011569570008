<div class="mb-2" *ngIf="showBanner" id="divEditSessionBanner">
  <topaz-banner [bannerType]="bannerData.bannerType" useIcon disableScrolling [disableCloseButton]="bannerData.disableCloseButton">
    <div banner-content>
      <b>{{bannerData.header}}</b>
      <div *ngIf="!bannerData.metadata">
        <div *ngFor="let content of bannerData.contents">
          <span>{{ content }}</span>
        </div>
      </div>

      <div *ngIf="bannerData?.metadata?.isCheckPaymentError">
        <div *ngFor="let content of bannerData.contents; let i = index">
          <br *ngIf="content == 'linebreak'" />
          <span *ngIf="content != 'linebreak' && (i+1) < bannerContentLength " class="check-payment-error-data">{{ content }}</span>
          <span *ngIf="bannerContentLength === (i+1)">{{ content }}</span>
        </div>
      </div>

      <div *ngIf="bannerData?.metadata?.isExpiredSiteLicense">
        <div *transloco="let t">
          <div *ngIf="!bannerData?.metadata?.isMultipleErrors">
            <h5>
              {{ t('siteLicenseExpiredSLA.Header',
                {
                  programName: expiredSiteLicense[0].programName,
                  expirationDate: expiredSiteLicense[0].expirationDate | ctpDate:'MMMM d, y'
                }) }}
            </h5>
            <h6>{{ t('checkPaymentInsufficientPaymentErrorFooter') }}</h6>
          </div>
          <div *ngIf="bannerData?.metadata?.isMultipleErrors">
            <h5>
              {{bannerData.header}}
            </h5>
            <ul class="list-contents">
              <li *ngFor="let siteLicense of expiredSiteLicense">
                {{ t('siteLicenseExpiredSLA.Header',
                  {
                    programName: siteLicense.programName,
                    expirationDate:siteLicense.expirationDate| date:'MMMM d, y'
                }) }}
              </li>
            </ul>
      
            <h6>{{ t('checkPaymentInsufficientPaymentErrorFooter') }}</h6>
          </div>
        </div>
      </div>
    </div>
    <div banner-action *ngIf="bannerData.showActionButton">
      <button mat-button color="primary" (click)="actionEvent()">{{bannerData.actionName}}</button>
    </div>
  </topaz-banner>
</div>
