<div class="page-container">
  <div class="topaz-wrapper wrapper-extension">
    <div>
      <lib-header (logOutEvent)="logOut()" [userName]="getUserName()"></lib-header>
    </div>
  
    <!-- For diagnostics only. -->
    <div *ngIf="showLogoutTimer">{{millisecondsLeft | timeSpan}}</div>
    
    <div [attr.dir]="getTextDirection()">
      <div *transloco="let t;" class="mt-1">
        <lib-mb-side-nav [userName]="getUserName()" [heading]="(sidenav.heading ? t(sidenav.heading) : '')"
          [buttonName]="(sidenav.buttonName ? t(sidenav.buttonName) : '')" [showArrow]="sidenav.showArrow">
        </lib-mb-side-nav>
      </div>
  
      <div class="flex-container">
        <div class="leftnav" *ngIf="!isMobile">
          <lib-side-nav [userName]="getUserName()"></lib-side-nav>
        </div>
  
        <main class="topaz-content-wrapper content-wrapper-extension">
          <topaz-toaster></topaz-toaster>
          <router-outlet></router-outlet>
        </main>
      </div>
  
      <div id="webExChat"  *ngIf="showChatButton">
        <lib-webx-chat [webxChatURL]="webxChatURL"></lib-webx-chat>
      </div>
  
      <lib-loader></lib-loader>
    </div>
  </div>
  <div class="footer" [attr.dir]="getTextDirection()">
    <lib-footer [showCookiePreferences]="showCookiePreferences"></lib-footer>
  </div>
</div>
