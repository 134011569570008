<div [formGroup]="form" *transloco="let t">
    <mat-form-field appearance="outline">
      <mat-label>{{ t('sessiondetails.label.time_zone') }}</mat-label>
      <input type="text" placeholder="{{ t('sessiondetails.label.time_zone') }}" matInput
        formControlName="timeZone" [matAutocomplete]="autoTimeZone" required />
      <fa-icon [icon]="icons.faCaretDown" size="sm" matIconSuffix></fa-icon>

      <mat-autocomplete autoActiveFirstOption="true" hideSingleSelectionIndicator="true" #autoTimeZone="matAutocomplete" [displayWith]="displayTimeZoneName">
        <mat-option *ngFor="let timeZone of filteredTimeZones | async" [value]="timeZone">
          {{ timeZone.timeZoneName}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="form.get('timeZone')?.errors?.['required']">{{ t('sessiondetails.error.time_zone_required') }}</mat-error>
      <mat-error *ngIf="form.get('timeZone')?.errors?.['invalidAutocompleteObject']">{{ t('sessiondetails.error.time_zone_invalid') }}</mat-error>
    </mat-form-field>
  </div>
