import { Injectable } from '@angular/core';
import { UserDetailService } from '@certiport/login-library';
import { Subject } from 'rxjs';
import { CreatesessiontoastService } from '../create-session-services/createsessiontoast.service';

@Injectable({
  providedIn: 'root'
})
export class CommonmethodsService {

  private markTimepicker = new Subject<{isValid: boolean, status: string}>();
  private loadExams = new Subject<void>();
  private testCenterChange = new Subject<void>();
  
  markTimepickerNotify$ = this.markTimepicker.asObservable();
  loadExams$ = this.loadExams.asObservable();
  testCenterChange$ = this.testCenterChange.asObservable();

  constructor(private userDetailService: UserDetailService,
    private createsessiontoastService: CreatesessiontoastService) { }

  public getLoggedInUserId(): number {
    const userId = this.userDetailService.getUserDetail()?.userId ?? 0;

    if (userId) {
      return userId;
    }
    this.createsessiontoastService.showGenericErrorAndLogToConsole('Invalid UserId. Try logging in again.');
    return 0;
  }

  markTimepickerNotify(isValid: boolean, status: string) {
    this.markTimepicker.next({isValid: isValid, status: status});
  }

  loadExamsNotify() {
    this.loadExams.next();
  }

  testCenterChangeNotify() {
    this.testCenterChange.next();
  }
}
