import { TopazBannerType } from "@pearsonvue/topaz-angular-ui";

export interface IBannerData {
  bannerType: TopazBannerType,
  header: string,
  contents: string[],
  contentStyle?: undefined | 'list',
  disableCloseButton: boolean,
  actionName?: string,
  showActionButton?: boolean,
  actionData?: any,
  useIcon?: boolean,
  metadata?: any
}
