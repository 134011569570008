import { Observable, of, take, map } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ITimeZone } from '../models/timezone';
import { ISessionSchedule } from '../models/session-schedule';
import { IPatchSessionData } from '../models/patchSessionData';
import { ICreateSession, ISession, ISessionScheduleList, IUpdateSessionStatus, ICreateSessionOutput } from '../models/session';
import { LoginStorageTokens } from '@certiport/login-library';
import { IOutageResponse } from '../models/outage-response';
import { ITestCenterSignalR } from '../models/testcenter-signalr';
import { ConfigService } from './config-service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  environment: any;

  constructor(private httpClient: HttpClient, private configService: ConfigService) {    
  }

  getSession(sessionId: number): Observable<ISession> {
    const url = `${this.configService.schedulingServiceURL}/session/sessioninfo?sessionId=${sessionId}`;
    return this.httpClient.get<ISession>(url, { withCredentials: true });
  }

  getTimeZones(): Observable<ITimeZone[]> {
    const url = `${this.configService.schedulingServiceURL}/TimeZone`;
    return this.httpClient.get<ITimeZone[]>(url, { withCredentials: true });
  }

  saveSession(session: ICreateSession): Observable<ICreateSessionOutput> {
    const url = `${this.configService.schedulingServiceURL}/session`;
    return this.httpClient.post<ICreateSessionOutput>(url, session, { withCredentials: true });
  }

  editSession(session: ISession): Observable<ISession> {
    const url = `${this.configService.schedulingServiceURL}/session`;
    return this.httpClient.put<any>(url, session, { withCredentials: true });
  }

  canScheduleSession(sessionSchedule: ISessionSchedule): Observable<{ canScheduleSession: boolean }> {
    const url = `${this.configService.schedulingServiceURL}/Session/ValidateSessionSchedule`;
    return this.httpClient.post<{ canScheduleSession: boolean }>(url, sessionSchedule, { withCredentials: true });
  }

  getSessionSchedule(userID: number, startDate: string): Observable<ISessionScheduleList[]> {
    const url = `${this.configService.schedulingServiceURL}/session?userID=${userID}&startDate=${startDate}`;
    return this.httpClient.get<ISessionScheduleList[]>(url, { withCredentials: true });
  }

  getUpcomingOutages(): Observable<IOutageResponse[]> {
    const url = `${this.configService.schedulingServiceURL}/Outage`;
    return this.httpClient.get<IOutageResponse[]>(url, { withCredentials: true }).pipe(take(1));
  }

  patchEmailAddress(sessionId: number, emailAddress: string, sendEmail: boolean) {
    const url = `${this.configService.schedulingServiceURL}/Session/SessionInfo?sessionId=${sessionId}&sendEmail=${sendEmail}`;
    const patchData = {
      path: "/CreatorEmail",
      op: "replace",
      value: emailAddress
    } as IPatchSessionData;
    const body = "[" + JSON.stringify(patchData) + "]";
    let headers = new HttpHeaders();
    headers = headers.
      set('content-type', 'application/json');
    return this.httpClient.patch<any>(url, body, { withCredentials: true, 'headers': headers });
  }

  updateSessionStatus(updateSessionStatus: IUpdateSessionStatus): Observable<any> {
    const url = `${this.configService.schedulingServiceURL}/Session/SessionStatus`;
    const result = this.httpClient.post<any>(url, updateSessionStatus, { withCredentials: true });
    return result;
  }

  ResendCreateSessionEmails(sessionId: number): Observable<{ emails: string[] }> {
    const url = `${this.configService.schedulingServiceURL}/Session/ResendCreateSessionEmails?sessionId=${sessionId}`;
    return this.httpClient.post<{ emails: string[] }>(url, "", { withCredentials: true });
  }

  getProtorUiUrl(sessionId: number) {
    const refreshToken = localStorage?.getItem(LoginStorageTokens.Refresh);
    const url = `${this.configService.ProctorUIUrl}/proctor?sessionId=${sessionId}&from=scheduling&refreshToken=${refreshToken}`;
    return url;
  }

  getTestCenterSignalR(userId: number): Observable<ITestCenterSignalR[]> {
    const url = `${this.configService.schedulingServiceURL}/TestCenter/TestCenterSignalR/${userId}`;
    return this.httpClient.get<ITestCenterSignalR[]>(url, { withCredentials: true });
  }

  getWindowsTimeZoneByBrowserTimeZone(browserTimezone: string): Observable<ITimeZone> {
    const url = `${this.configService.schedulingServiceURL}/TimeZone/GetWindowsTimeZoneByBrowserTimeZone?browserTimezone=${browserTimezone}`;
    return this.httpClient.get<ITimeZone>(url, { withCredentials: true });
  }
}

