<topaz-banner [bannerType]="data.bannerType" [useIcon]="data.useIcon" disableScrolling [disableCloseButton]="data.disableCloseButton">
  <div banner-content>
    <b>{{data.header}}</b>
    <div *ngIf="!data.metadata && data.contentStyle !== 'list'">
      <div *ngFor="let content of data.contents">
        <span>{{ content }}</span>
      </div>
    </div>
    <ul *ngIf="!data.metadata && data.contentStyle == 'list'" class="list-contents">
      <li *ngFor="let content of data.contents">{{ content }}</li>
    </ul>

    <div *ngIf="data?.metadata?.isCheckPaymentError">
      <div *ngFor="let content of data.contents; let i = index">
        <br *ngIf="content == 'linebreak'" />
        <span *ngIf="content != 'linebreak' && (i+1) < bannerContentLength " class="check-payment-error-data">{{ content }}</span>
        <span *ngIf="bannerContentLength === (i+1)">{{ content }}</span>
      </div>
    </div>
  </div>
  <div banner-action *ngIf="data.showActionButton">
    <button mat-button color="primary" (click)="actionEvent()">{{data.actionName}}</button>
  </div>
</topaz-banner>
