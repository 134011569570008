import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { IConfirmDialogData } from '../models/confirmdialogdata';
import { SessionStatusUpdateService } from './session-status-update.service';

@Injectable({
  providedIn: 'root'
})
export class CancelSessionService {

  constructor(private transloco: TranslocoService, private dialog: MatDialog, private sessionStatusUpdateService: SessionStatusUpdateService) { }

  cancelSessionConfirmation(sessionInfo: any) {
    var dialogContents: string[] = [];
    dialogContents.push(this.transloco.translate("cancelSession.cancelSessionContent1"));
    dialogContents.push(this.transloco.translate("cancelSession.cancelSessionContent2"));
    dialogContents.push(this.transloco.translate("cancelSession.cancelSessionContent3", {
      sessionName: sessionInfo.sessionName
    }));
    var confirmDialogData = {
      header: this.transloco.translate("cancelSession.cancelSessionHeader"),
      contents: dialogContents,
      buttonType: "warn",
      isCancelDialog: true,
      showCloseButton: false
    } as IConfirmDialogData;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialogData
    });

    dialogRef.afterClosed().subscribe({
      next: (v) => {
        if (v === 'yes') {
          this.sessionStatusUpdateService.cancelSession(sessionInfo);
        }
      }
    });
  }
}
