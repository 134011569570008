import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { DialogFormData } from '../session-confirmation/session-confirmation.component';

@Component({
  selector: 'app-email-confirmation-dialog',
  templateUrl: './email-confirmation-dialog.component.html',
  styleUrls: ['./email-confirmation-dialog.component.scss']
})
export class EmailConfirmationDialogComponent implements OnInit {
  constructor(private formBuilder: FormBuilder, private t: TranslocoService,
    public dialogRef: MatDialogRef<EmailConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {

  }

  ngOnInit(): void {
 
  }

  public editEmailForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.maxLength(250), this.validateEmail.bind(this)]],
    emailConfirm: ['', [Validators.required, Validators.maxLength(250), this.validateValue.bind(this), this.validateEmail.bind(this)]]
  })

  validateValue(control: AbstractControl): { [key: string]: boolean } | null {
    if (!control.value) {
      return { invalidValue: true };
    }
    if (this.getControl('email')?.value !== this.getControl('emailConfirm')?.value) {
      return { invalidValue: true };
    }
    return null;
  }

  validateEmail(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value) {
      var reg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!reg.test(control.value)) {
        return { invalidMail: true };
      }
    }
    return null;
  }

  formControls = {
    email: this.getControl('email'),
    emailConfirm: this.getControl('emailConfirm')
  };

  private getControl(name: string) {
    return this.editEmailForm.get(name);
  }

  closeWindow() {
    this.dialogRef.close();
  }

  saveEmail() {
    if (this.validate()) {
      const returnData: DialogFormData = {
        email: this.editEmailForm.value.email ?? '',
        emailConfirm: this.editEmailForm.value.emailConfirm ?? '',
      }

      this.dialogRef.close(returnData);
    };
  }

  validate() {
    this.editEmailForm.markAllAsTouched();
    return !this.editEmailForm.invalid;
  }
}
