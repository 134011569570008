import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class PaginatorIntlService extends MatPaginatorIntl {
  constructor(private t: TranslocoService) {
    super();
    // Customizing the range label and text
    this.getRangeLabel = this.getCustomRangeLabel;
  }

  // Customizing range label function
  getCustomRangeLabel(page: number, pageSize: number, length: number): string {
    const minPage = page + 1;
    const maxPage = Math.ceil(length / pageSize);
    return this.t.translate('examTablePaginator.pageXofY', { minPage, maxPage });
  }
}
