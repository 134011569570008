import { HttpClient } from '@angular/common/http';
import {Injectable } from '@angular/core';
import { UserDetailService } from '@certiport/login-library';
import { Observable } from 'rxjs';
import { AllowedRoles, ISessionScheduleList } from '../models/session';
import { ConfigService } from './config-service';

@Injectable({
  providedIn: 'root'
})
export class SessionListService {

  private readonly allowedRoles: any =Object.values(AllowedRoles).filter((v) => !isNaN(Number(v)));
  
  constructor(private httpClient: HttpClient, private configService: ConfigService,private userDetailService:UserDetailService) {
    
  }
  
  getSessions(userID: number, startDate?: string): Observable<ISessionScheduleList[]> {
    let url = `${this.configService.schedulingServiceURL}/session?userID=${userID}${startDate ? '&startDate=' + startDate.toString() : ''}`;
    return this.httpClient.get<ISessionScheduleList[]>(url, { withCredentials: true });
  }

  isAllowedRole(): boolean{
    let userRoles: number[] = this.userDetailService.getUserDetail()?.userRoles;
    return userRoles.some((val: number) => this.allowedRoles.includes(val));
  }
}
