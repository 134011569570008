<div class="container" *transloco="let t">
  <div cdkTrapFocus
       aria-modal="true"
       role="dialog"
       aria-labelledby="paymentMethodDefinitionTitle"
       aria-describedby="paymentMethodDefinitionTitle">
    <div class="topaz-dialog-header">
      <h1 topazTitle="3" id="modal-title">{{t('paymentMethodDefinition.title')}}</h1>
      <button mat-icon-button mat-dialog-close aria-label="closeLabel" (click)="this.dialogRef.close()">
        <fa-icon title="closeLabel" size="lg" [icon]="icons.faXmark"></fa-icon>
      </button>
    </div>
    <div mat-dialog-content class="content">
      <div>
        <b>{{t('paymentMethodDefinition.siteLicenseLabel')}}:</b> {{t('paymentMethodDefinition.siteLicenseDesc')}}
      </div>
      <div>
        <b>{{t('paymentMethodDefinition.inventoryLabel')}}:</b> {{t('paymentMethodDefinition.inventoryDesc')}}
      </div>
      <div>
        <b>{{t('paymentMethodDefinition.userLicenseLabel')}}:</b> {{t('paymentMethodDefinition.userLicenseDesc')}}
      </div>
      <div>
        <b>{{t('paymentMethodDefinition.userAssignedVoucherLabel')}}:</b>&nbsp;<span [innerHTML]="userAssignedVoucherDesc"></span>
      </div>
      <div>
        <b>{{t('paymentMethodDefinition.centerAssignedVoucherLabel')}}:</b>&nbsp;<span [innerHTML]="centerAssignedVoucherDesc"></span>
      </div>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-flat-button color="primary" class="topaz-button-small" aria-label="primary" (click)="this.dialogRef.close()">
        {{t('generic.close')}}
      </button>
    </div>
  </div>
</div>
