import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISettingsInfo } from '../models/settingsInfo';
import { ConfigService } from './config-service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  environment: any;
  constructor(private httpClient: HttpClient, private configService: ConfigService) {
   }

  getSettings(): Observable<ISettingsInfo[]> {
    const url = `${this.configService.schedulingServiceURL}/Settings`;
    return this.httpClient.get<ISettingsInfo[]>(url, { withCredentials: true });
  }
}
