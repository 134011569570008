import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { TextMsgPreferencesViewComponent } from '../text-msg-preferences-view/text-msg-preferences-view.component';
import { TranslocoService } from '@jsverse/transloco';
import { CC_ConfirmationTextId, CommunicationPreferenceRequestService, CommunicationPreferences_GetDto } from '../services/communication-preference-request.service';
import { SchedulingErrorCodeService } from 'src/app/services/scheduling-error-code.service';

export enum CommunicationPreferenceStrings {
  emptyPhone = '',
};

@Component({
  selector: 'app-communication-preferences-view',
  standalone: true,
  imports: [
    TranslocoRootModule,
    MatCardModule,
    MatButtonModule,
    MatTooltipModule,
    FontAwesomeModule,
    TextMsgPreferencesViewComponent,
  ],
  templateUrl: './communication-preferences-view.component.html',
  styleUrl: './communication-preferences-view.component.scss'
})
export class CommunicationPreferencesViewComponent implements OnInit {
  readonly icons = { faCircleInfo };

  @Input() firstConfirmationTextMinutes: number = 0;
  @Input() secondConfirmationTextMinutes: number = 0;

  inEditMode: boolean = false;
  scopeNote: string = '';
  communicationPreferences: CommunicationPreferences_GetDto | null = null;
  mobilePhone: string = CommunicationPreferenceStrings.emptyPhone;
  textOptIn: boolean | null = null;
  confirmationOptIn: boolean = false;

  constructor(
    transloco: TranslocoService
    , private communicationService: CommunicationPreferenceRequestService
    , private schedulingErrorCodeService: SchedulingErrorCodeService
  ) {
    const location = transloco.translate('pref.portalLocation');
    this.scopeNote = transloco.translate('pref.changeScopeNote', { location: location });
  }


  ngOnInit(): void {
    this.communicationService.preferencesObservable.subscribe(x => {
      this.communicationPreferences = x;
      if (x) {
        this.mobilePhone = this.communicationPreferences?.cellPhone?.toString() ?? CommunicationPreferenceStrings.emptyPhone;
        this.textOptIn = this.communicationPreferences?.compassCloudTextoptIn ?? null;
        var opt = this.communicationPreferences?.smsOptInOptionSelections.find((item) => item?.smsOptInOptionId === CC_ConfirmationTextId);
        this.confirmationOptIn = opt?.optIn ?? false;
      }
    });

    this.communicationService.errorObservable.subscribe(x => {
      if (x != null) {
        this.schedulingErrorCodeService.showErrorCodeToast(x?.errorCode, x?.traceId);
      }
    });

    this.communicationService.getPreferencesEx();

  }

  handleClickEdit(): void {
    alert("Edit mode is temporarily disabled.");
    //this.inEditMode = true;
  }

  handleClickMainCancel(): void {
    this.inEditMode = false;
  }
}
