import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISessionScheduleList, SessionStatusIds } from '../models/session';
import { TimeProviderService } from '@certiport/login-library';
import { SettingsCacheService } from './settings-cache.service';
import { IntervalService } from './interval.service';
import { SessionValidationService } from './session-validation.service';

//Note: The intent of this service is to be a manager of the state of the exam session list, and that components,
// rather than managing their own copy of the session state, would subscribe to observables published by this class.
// However, while components continue to manage their own copy of session state, we have methods (e.g. getNextSession) here for those
// components to call as well.

@Injectable({
  providedIn: 'root'
})
export class SessionsManagerService {
  private _sessions: ISessionScheduleList[] = [];
  private intervalId: number = 0;
  private readonly FrequencyInMilliseconds = 5000;
  private _nextSession: ISessionScheduleList | null = null;

  //////////////////////////////////////////////////////////
  //Public
  //////////////////////////////////////////////////////////

  constructor(
    private timeProvider: TimeProviderService,
    private settings: SettingsCacheService,
    private intervalService: IntervalService,
    private sessionValidationService: SessionValidationService) { }

  nextSession: BehaviorSubject<ISessionScheduleList | null> = new BehaviorSubject<ISessionScheduleList | null>(null);
  nextStartableSessionChanged: BehaviorSubject<ISessionScheduleList | null> = new BehaviorSubject<ISessionScheduleList | null>(null);
  sessionActionProsChange: BehaviorSubject<ISessionScheduleList | null> = new BehaviorSubject<ISessionScheduleList | null>(null);

  set sessions(value: ISessionScheduleList[]) {
    this._sessions = value;
    this.updateNextSession();
  }

  initiate(): void {
    this.sessionValidationService.minConfirmSessionMinutes = this.settings.minConfirmationLeadTime;
    this.sessionValidationService.maxConfirmSessionMinutes = this.settings.maxConfirmSessionMinutes;
    this.sessionValidationService.minCancellationLeadTime = this.settings.minCancellationLeadTime;
    this.sessionValidationService.minStartSessionLeadTime = this.settings.minStartSessionLeadTime;
    this.sessionValidationService.maxStartSessionLateTime = this.settings.maxStartSessionLateTime;
    this.sessionValidationService.maxEditTime = this.settings.maxEditTime;

    this.intervalId = this.intervalService.setInterval(() => this.handleTick(), this.FrequencyInMilliseconds);
  }

  uninitiate(): void {
    if (this.intervalId !== 0) {
      const id = this.intervalId;
      this.intervalId = 0;
      this.intervalService.clearInterval(id);
    }
    this.nextSession.next(null);
    this.nextStartableSessionChanged.next(null);
    this._nextSession = null;
  }

  //////////////////////////////////////////////////////////
  //Private
  //////////////////////////////////////////////////////////

  private handleTick(): void {
    const prevNextSession: ISessionScheduleList | null = this._nextSession;
    const prevDisabledStart: boolean = prevNextSession ? prevNextSession.disableStartSession : true;

    this.updateSessionStates();
    this.updateNextSession();

    //If the session has changed OR the disableStartSession state of the next item has changed...
    if (this._nextSession?.sessionId != prevNextSession?.sessionId
      || (this._nextSession
        && prevNextSession
        && this._nextSession.disableStartSession !== prevDisabledStart)) {
      this.nextStartableSessionChanged.next(this._nextSession);
    }
  }

  private updateNextSession(): void {
    const nextSession: ISessionScheduleList | null = this.calcNextSession(this._sessions);
    if (nextSession?.sessionId != this._nextSession?.sessionId || this.checkIsNextSessionActionProsChanged(nextSession)) {
      this._nextSession = nextSession;
      this.nextSession.next(nextSession);    
    }
  }

  private checkIsNextSessionActionProsChanged(currentNextSession: ISessionScheduleList | null) {
    if (this._nextSession?.sessionId == currentNextSession?.sessionId && this._nextSession?.disableConfirmSession !== currentNextSession?.disableConfirmSession) {
      return true;
    }
    if (this._nextSession?.sessionId == currentNextSession?.sessionId && this._nextSession?.disableStartSession !== currentNextSession?.disableStartSession) {
      return true;
    }
    if (this._nextSession?.sessionId == currentNextSession?.sessionId && this._nextSession?.disableResumeSession !== currentNextSession?.disableResumeSession) {
      return true;
    }
    if (this._nextSession?.sessionId == currentNextSession?.sessionId && this._nextSession?.disableCancelSession !== currentNextSession?.disableCancelSession) {
      return true;
    }
    if (this._nextSession?.sessionId == currentNextSession?.sessionId && this._nextSession?.disableEditSession !== currentNextSession?.disableEditSession) {
      return true;
    }
    if (this._nextSession?.sessionId == currentNextSession?.sessionId && this._nextSession?.sessionName !== currentNextSession?.sessionName) {
      return true;
    }
    if (this._nextSession?.sessionId == currentNextSession?.sessionId && this._nextSession?.sessionStatusId !== currentNextSession?.sessionStatusId) {
      return true;
    }
    if (this._nextSession?.sessionId == currentNextSession?.sessionId && this._nextSession?.proctorId !== currentNextSession?.proctorId) {
      return true;
    }
    if (this._nextSession?.sessionId == currentNextSession?.sessionId && this._nextSession?.startDateTime !== currentNextSession?.startDateTime) {
      return true;
    }
    return false;
  }

  private calcNextSession(sessions: ISessionScheduleList[]): ISessionScheduleList | null {
    const sessionList: ISessionScheduleList[] = sessions.filter(
      s => s.sessionStatusId < SessionStatusIds.inprogress
      && this.timeProvider.now <= s.startDateTime.valueOf() + this.settings.maxStartSessionLateTime * 60 * 1000
      && (
            s.sessionStatusId == SessionStatusIds.confirmed
            || this.timeProvider.now <= s.startDateTime.valueOf() - this.settings.minConfirmationLeadTime
         )
      );

    return sessionList.length > 0 ? sessionList[0] : null;
  }

  private updateSessionStates(): void {
    for (let s of this._sessions) {
      this.updateSessionState(s);
    }
  }

  private updateSessionState(session: ISessionScheduleList) {
    let isValueChange = false;
    const disableEditSessionAction = this.sessionValidationService.shouldEditSessionBeDisabled(session.sessionStatus, session.startDateTime);
    if (session.disableEditSession !== disableEditSessionAction) {
      session.disableEditSession = disableEditSessionAction;
      isValueChange = true;
    }

    const disableStartSessionAction = this.sessionValidationService.shouldStartSessionBeDisabled(session);
    if (session.disableStartSession !== disableStartSessionAction) {
      session.disableStartSession = disableStartSessionAction;
      isValueChange = true;
    }

    const disableCancelSessionAction = this.sessionValidationService.shouldCancelSessionBeDisabled(session);
    if (session.disableCancelSession !== disableCancelSessionAction) {
      session.disableCancelSession = disableCancelSessionAction;
      isValueChange = true;
    }

    const disableConfirmSessionAction = this.sessionValidationService.shouldConfirmSessionBeDisabled(session);
    if (session.disableConfirmSession !== disableConfirmSessionAction) {
      session.disableConfirmSession = disableConfirmSessionAction;
      isValueChange = true;
    }

    if(isValueChange){
      this.sessionActionProsChange.next(session);
    }
  }
}
