import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { Subject } from "rxjs";

export interface ISignalRHub {
    initialize(url: string): void;
    start(): Promise<void>;
    on(methodName: string, newMethod: () => void): void;
    invoke(methodName: string, ...args: any[]): Promise<void>;
    NotifyReInvokeListener(): void;
}

@Injectable({
    providedIn: 'root'
})
export class SignalRHub implements ISignalRHub {
    private hubConnection!: signalR.HubConnection;
    private reInvokeListenerEvent = new Subject<void>();

    constructor() { }

    initialize(url: string): void {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(url)
            .withAutomaticReconnect() // reconnect will trigger with interval of 0, 2000, 10000, 30000 milliseconds, max 4 attempts.
            .configureLogging(signalR.LogLevel.Debug)
            .build();

        this.hubConnection.onreconnected(() => {
            this.reInvokeListenerEvent.next();
        });
    }

    start(): Promise<void> {
        return this.hubConnection.start();
    }

    stop(): Promise<void> {
        return this.hubConnection.stop();
    }

    on(methodName: string, newMethod: (...args: any[]) => void): void {
        this.hubConnection.on(methodName, newMethod);
    }

    off(methodName: string, method: (...args: any[]) => void): void {
        this.hubConnection.off(methodName, method);
    }

    invoke(methodName: string, ...args: any[]): Promise<void> {
        console.log("");
        return this.hubConnection.invoke(methodName, ...args)
            .then(
                (x) => console.log("invoke success: " + x), //fulfilled
                (x) => console.error("invoke rejected: " + x) //reject
            )
            .catch(error => console.error("invoke catch: " + error));
    }

    NotifyReInvokeListener() {
        return this.reInvokeListenerEvent.asObservable();
    }

    logID(): void {
        console.log("ConnectionID: " + this.hubConnection.connectionId + " at url: " + this.hubConnection.baseUrl + ". status: " + this.hubConnection.state);
    }
}
