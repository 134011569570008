import { Injectable } from '@angular/core';
import { ToastService } from '@pearsonvue/topaz-angular-ui';
import { TopazToastType } from '@pearsonvue/topaz-angular-ui/lib/components/toast/types/toast.type';
import { ToastCustomComponent } from '../components/toast-custom/toast-custom.component';
import { IToastData } from '../models/toastdata';

//NOTE: This service is simply a wrapper to facilitate unit tests for client classes. This class does not need to be unit tested.

@Injectable({
  providedIn: 'root'
})
export class SchedulerToastServiceService {

  constructor(private toastService: ToastService<IToastData>) { }

  public open(toastDisplayType: TopazToastType, toastHeader: string, toastContent: string): void {
    this.toastService.open(ToastCustomComponent, {
      header: toastHeader,
      contents: [toastContent],
      toastType: toastDisplayType
    });
  }

  public clear(): void {
    this.toastService.clear();
  }
}
