import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective, ValidationErrors } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { TopazTimepickerComponent } from '@pearsonvue/topaz-angular-ui';
import { CommonmethodsService } from '../../create-session-services/commonmethods.service';

@Component({
  selector: 'app-time-selection',
  templateUrl: './time-selection.component.html',
  styleUrls: ['./time-selection.component.scss']
})
export class TimeSelectionComponent implements OnInit, AfterViewInit {

  form!: FormGroup
  is24hrFormat = false;
  timePickerLabel = '';
  @ViewChild('timepicker') timepicker!: TopazTimepickerComponent;
  constructor(private t: TranslocoService, private rootFormGroup: FormGroupDirective, private commonmethodsService: CommonmethodsService) {

    this.t.langChanges$.subscribe(time => {
      setTimeout(() => {
        this.timePickerLabel = this.t.translate('timePicker.time');
      }, 500);

    });
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;

    this.commonmethodsService.markTimepickerNotify$.subscribe((m) =>
      this.markTimepickerFormControlAs(m.isValid, m.status)
    );
    this.timePickerLabel = this.t.translate("timePicker.time");
    this.t.langChanges$.subscribe(time => {

      setTimeout(() => {
        this.timePickerLabel = this.t.translate('timePicker.time');
      }, 500);

    });
  }

  ngAfterViewInit() {
    this.timepickerFormCtrl?.disable();    
  }

  onTimepickerClick(): void {
    if (!this.timepickerFormCtrl?.touched && !this.timepickerFormCtrl?.touched) {
      this.markTimepickerFormControlAs(false, 'touched');
      this.timepickerFormCtrl?.markAsTouched();
    }
  }

  onTimepickerInput(): void {
    if (this.timepickerFormCtrl?.invalid) {
      this.timepickerFormCtrl?.setErrors({ 'invalid': true });
    }
  }

  setError(error: string) {
    const customError : ValidationErrors = {};
    customError[error] = true;
    this.timepickerFormCtrl?.setErrors(customError);
  }

  private markTimepickerFormControlAs(isValid: boolean, statusText: string): void {
    let status = (isValid && this.timepickerFormCtrl?.touched ? 'touched' : 'untouched')
    status = statusText != '' ? statusText : status;

    if (isValid && !statusText)
      this.timepickerFormCtrl?.enable();
    else if (!isValid && !statusText)
      this.timepickerFormCtrl?.disable();

    switch (status.toLowerCase()) {
      case 'reset':
        this.timepickerFormCtrl?.reset(null);
        break;

      case 'touched':
        this.timepickerFormCtrl?.markAsTouched();
        break;

      case 'untouched':
        this.timepickerFormCtrl?.markAsUntouched();
        break;
    }
  }

  public get timepickerFormCtrl(): AbstractControl | null {
    return this.timepicker?.timeFormControl;
  }

  public get invalidFormat(): boolean {
    return (this.timepickerFormCtrl?.touched && this.timepickerFormCtrl?.hasError('invalid-format')) ?? false;
  }

}
