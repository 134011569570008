import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeProviderService {

  currentTime(): Date {
    return new Date(); //retunrs local datetime
  }

  UTCDateTime(): Date {
    const localDateTime = this.currentTime();
    var utcDateTime = new Date(localDateTime.getTime() + localDateTime.getTimezoneOffset() * 60000);
    return utcDateTime;
  }

  convertToUTCDateTime(date: Date) {
    var utcDateTime = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return utcDateTime;
  }
}
