import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-text-msg-preferences-view',
  standalone: true,
  imports: [
    TranslocoModule,
    MatCardModule,
    MatSlideToggleModule,
  ],
  templateUrl: './text-msg-preferences-view.component.html',
  styleUrl: './text-msg-preferences-view.component.scss'
})
export class TextMsgPreferencesViewComponent {
  @Input() inEditMode: boolean = false;
  @Input() firstConfirmationTextMinutes: number = 0;
  @Input() secondConfirmationTextMinutes: number = 0;
  @Input() confirmationOptIn: boolean = false;

  get firstConfirmationTextHours() : number {
    return this.firstConfirmationTextMinutes / 60;
  }

  get secondConfirmationTextHours() : number {
    return this.secondConfirmationTextMinutes / 60;
  }
}
