import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { add, startOfToday } from 'date-fns';

@Component({
  selector: 'app-date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.css']
})
export class DateSelectionComponent implements OnInit, OnChanges {

  form!: FormGroup
  @Input() maxDays!: number;
  minDate!: Date;
  maxDate!: Date;
  isTouchUi = false;

  constructor(private t: TranslocoService, private rootFormGroup: FormGroupDirective) {
    this.minDate = startOfToday();
    this.maxDate = add(Date.now(), { days: this.maxDays });
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.maxDate != changes['maxDays'].currentValue) {
      this.maxDate = add(Date.now(), { days: changes['maxDays'].currentValue });
    }
  }

  get dateControl(): AbstractControl | null {
    return this.form.get('date');
  }
}
