export interface IConfirmDialogData {
  header: string,
  contents: string[],
  headerMarginBottom: string,
  contentMarginBottom: string,
  buttonMarginTop: string,
  showCloseButton: boolean,
  isCancelDialog: boolean,
  buttonType: string;
}
