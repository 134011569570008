import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { ToastService } from '@pearsonvue/topaz-angular-ui';
import { ToastCustomComponent } from '../../toast-custom/toast-custom.component';
import { TopazToastData } from '@pearsonvue/topaz-angular-ui/lib/components/toast/types/toast-data.interface';
import { InsufficientPaymentDetails, InsufficientPaymentOutput, IToastData, PaymentValidationType } from '@cbb/models';
import { EMPTY } from 'rxjs';
import { InsufficientPaymentToastComponent } from '../insufficient-payment-toast/insufficient-payment-toast.component';
import { ICheckSlaExpirationsResponse } from 'src/app/models/check-slaexpirations';
import { IExpiredSiteLicense } from '../../../models/expired-sitelicense';
import { ExpiredSiteLicenseToastComponent } from '../expired-site-license-toast/expired-site-license-toast.component';
import { DatePipe } from '@angular/common';
import { ToastConfigService } from '../../../services/toast-config.service';
import { CtpDatePipe } from 'certiport-layout-library';

@Injectable({
  providedIn: 'root'
})
export class CreatesessiontoastService {

  constructor(private t: TranslocoService,
    private toastService: ToastService,
    private toastConfigService: ToastConfigService,
    private ctpDatePipe: CtpDatePipe) { }

  public showGenericErrorAndLogToConsole(consoleErrorMessage: string | { headerTag: string, contentTag: string }): void {
    const commonToastProperties = {
      toastType: 'warn',
      disableCloseButton: false
    };
    const toastContents = typeof consoleErrorMessage === 'object' ?
      {
        header: this.t.translate(consoleErrorMessage.headerTag),
        contents: [this.t.translate(consoleErrorMessage.contentTag)],
        ...commonToastProperties
      } :
      {
        header: this.t.translate('genericErrorHeader'),
        contents: [this.t.translate('genericErrorContent')],
        ...commonToastProperties
      };

    this.toastService.open(ToastCustomComponent, toastContents as TopazToastData & IToastData);
  }

  public openSessionDateTimeToast(maxHours: number) {
    this.toastService.open(ToastCustomComponent, {
      header: '',
      contents: [this.t.translate('sessiondetails.error.session_schedule_maxhours_range', { maxHours: maxHours })],
      toastType: 'warn'
    } as TopazToastData & IToastData);
  }

  public openSystemOutagesToast() {
    this.showGenericErrorAndLogToConsole(
      {
        headerTag: 'createsession.outages.header',
        contentTag: 'createsession.outages.content'
      }
    );
  }

  public genericErrorToast(error: any) {
    this.toastService.open(ToastCustomComponent, {
      toastType: 'warn',
      header: this.t.translate('genericErrorHeader'),
      contents: [this.t.translate('genericErrorContent')],
      disableCloseButton: false
    } as TopazToastData & IToastData);
    console.error(error);
    return EMPTY;
  }

  public showWarnToast(contents: string[]) {
    this.toastService.open(ToastCustomComponent, {
      header: '',
      contents: contents,
      toastType: 'warn'
    } as TopazToastData & IToastData);
  }

  public showAddAnotherExamWarnToast(header: string) {
    this.toastService.open(ToastCustomComponent, {
      header: header,
      contents: [],
      toastType: 'warn',
      disableCloseButton: false
    } as TopazToastData & IToastData);
  }

  public showSuccessToast(contents: string[], header: string = '') {
    this.toastService.open(ToastCustomComponent, {
      header: header,
      contents: contents,
      toastType: 'success'
    } as TopazToastData & IToastData);
  }

  public showInsufficientPaymentToast(paymentMethodName: string, paymentValidatonType: PaymentValidationType, insufficientPayments: InsufficientPaymentOutput[]) {
    if (insufficientPayments.length > 0) {
      this.toastService.config.enableAutoDismiss = false;
      const pmyMethod = paymentMethodName.toLowerCase();
      this.toastService.open(InsufficientPaymentToastComponent, {
        toastType: 'warn',
        paymentMethodName: pmyMethod,
        insufficientPayments: insufficientPayments,
        paymentValidationType: PaymentValidationType[paymentValidatonType],
      } as TopazToastData & InsufficientPaymentDetails);

      this.toastService.config.enableAutoDismiss = true;
    }
  }

  public showExpiredSiteLicenseToast(expiredSiteLicense: IExpiredSiteLicense[]) {

    this.toastService.config.enableAutoDismiss = false;

    const expirationDate = this.ctpDatePipe.transform(expiredSiteLicense[0].expirationDate, 'MMMM d, y');

    this.toastService.open(ExpiredSiteLicenseToastComponent, {
      toastType: 'warn',
      programName: expiredSiteLicense[0].programName,
      expirationDate: expirationDate,
    } as TopazToastData & IExpiredSiteLicense);

    this.toastService.config.enableAutoDismiss = true;
  }

  public showCandidateMaxExceededToast(maxCandidateCount: number) {
    this.showWarnToast([this.t.translate('createsession.examdetails.candidate_max_exceeded', { maxCandidates: maxCandidateCount })]);
  }

  public getRemoveExamWarningDialogData() {
    return {
      data: {
        header: this.t.translate('createsessionlayout.warningdialog.examremoval.header'),
        contents: [
          this.t.translate('createsessionlayout.warningdialog.examremoval.do_you_want_to_discard_exam')
        ],
        headerMarginBottom: '1rem',
        buttonMarginTop: '1.5rem'
      }
    };
  }

  public slaNotAcceptedToast(header: string) {
    this.toastService.open(ToastCustomComponent, {
      header: this.t.translate('SlaNotAcceptedHeader', { ProgramName: header }),
      contents: [this.t.translate('checkPaymentInsufficientPaymentErrorFooter')],
      toastType: 'warn',
      disableCloseButton: false
    } as TopazToastData & IToastData);
  }

  public genericServerErrorToast() {
    this.toastService.open(ToastCustomComponent, {
      toastType: 'warn',
      header: this.t.translate('genericErrorHeader'),
      contents: [this.t.translate('serverError.contactAdmin')],
      disableCloseButton: false
    } as TopazToastData & IToastData);
    return EMPTY;
  }
}

