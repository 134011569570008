import { AfterViewInit, Component, EventEmitter, Input, OnInit, OnChanges, Output, ViewChild, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IExam, IExamSelectionInfo, IProgram } from '@cbb/models';
import { TranslocoService } from '@jsverse/transloco';
import { PaginatorIntlService } from '../../../services/paginator-intl.service';

@Component({
  selector: 'app-exams-list',
  templateUrl: './exams-list.component.html',
  styleUrls: ['./exams-list.component.scss'],
})
export class ExamsListComponent implements OnInit, AfterViewInit {

  constructor(private t: TranslocoService, private paginatorIntlService: PaginatorIntlService) {

    t.langChanges$.subscribe(lang => {

      setTimeout(() => {

        this.paginatorIntlService.nextPageLabel = this.t.translate('examTablePaginator.tooltip.nextPage');
        this.paginatorIntlService.previousPageLabel = this.t.translate('examTablePaginator.tooltip.previousPage');
        this.paginatorIntlService.itemsPerPageLabel = this.t.translate('examTablePaginator.rowsPerPage');

        if (this.paginator != undefined) {
          this.paginator._intl = this.paginatorIntlService;
        }
        this.examsListData.paginator = this.paginator;
        this.examsListData.sort = this.sort;
      }, 500);

    });
  }


  @Input() allExamsAndPrograms!: { exams: IExam[]; programs: IProgram[] };
  @Input() set selectedExam(value: IExamSelectionInfo | null) {
    if (value) {
      // Clear existing selections
      const curSel = this.examsListData.data.find(exam => exam.selected);

      if (curSel) {
        curSel.selected = false;
      }

      // Set current selection
      value.selected = true;
    }
  };
  @Output() selectedExamChange = new EventEmitter<IExamSelectionInfo>();

  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  @ViewChild(MatSort) public sort!: MatSort;


  public programSelect = new FormControl(0);
  public searchInput = new FormControl('');

  public examsListData = new MatTableDataSource<IExamSelectionInfo>([]);
  public displayedColumns: string[] = ['program', 'exam', 'selectExam'];

  readonly allProgramOption = {
    programID: 0,
    programAcronym: '',
    programName: 'All Programs',
    sponsorID: 0,
  } as IProgram;

  readonly examsSelectionList: IExamSelectionInfo[] = [];
  readonly programs: IProgram[] = [];


  ngOnInit() {
    const examProgramIds = this.allExamsAndPrograms.exams.map(function (e) { return e.programID; });
    this.programs.push(...this.allExamsAndPrograms.programs.filter(p => examProgramIds.includes(p.programID)));

    this.examsListData.filterPredicate = (data: IExamSelectionInfo, filter: string) =>
      (data.exam?.examName.toLowerCase().indexOf(filter?.toLowerCase()) != -1);

    this.populateExams();
    this.filterExamsByProgramId();
  }

  ngAfterViewInit() {

    this.paginatorIntlService.nextPageLabel = this.t.translate('examTablePaginator.tooltip.nextPage');
    this.paginatorIntlService.previousPageLabel = this.t.translate('examTablePaginator.tooltip.previousPage');
    this.paginatorIntlService.itemsPerPageLabel = this.t.translate('examTablePaginator.rowsPerPage');

    if (this.paginator != undefined) {
      this.paginator._intl = this.paginatorIntlService;
    }
    this.examsListData.paginator = this.paginator;
    this.examsListData.sort = this.sort;
  }



  onSearchFilter(value: string): void {
    this.examsListData.filter = value?.trim()?.toLowerCase();
  }

  public onSearchClear() {
    this.examsListData.filter = '';
  }

  filterExamsByProgramId(programId = 0): void {
    const filteredList: IExamSelectionInfo[] = [];

    if (programId === 0) {
      filteredList.push(...this.examsSelectionList);
      this.examsListData.data = filteredList;
      return;
    }
    filteredList.push(...this.examsSelectionList.filter(p => p.program?.programID === programId));
    this.examsListData.data = filteredList;
  }

  private populateExams(): void {
    const el = [] as IExamSelectionInfo[];

    el.push(
      ...this.allExamsAndPrograms.exams.map((exam: IExam) => ({
        exam,
        program: this.programs.find(p => p.programID === exam.programID) ?? ({} as IProgram),
        selected: false,
      }))
    );
    this.examsSelectionList.push(...el.sort(this.sortExamsByProgram));
  }

  private sortExamsByProgram(a: IExamSelectionInfo, b: IExamSelectionInfo) {
    return a.program === b.program
      ? a.exam < b.exam ? -1 : 1
      : a.program < b.program ? -1 : 1;
  }

  isExamExist() {
    return this.examsListData.data.length && this.examsListData.filteredData.length;
  }
}
