<div [formGroup]="form" *transloco="let t">
  <mat-form-field appearance="outline">
    <mat-label>{{ t('sessiondetails.label.exam_language') }}</mat-label>
    <mat-select panelClass="topaz-select" formControlName="examLanguage" required>
      <mat-option *ngFor="let examLanguage of examLanguages$ | async" [value]="examLanguage">
        {{ examLanguage.languageName }}
      </mat-option>
    </mat-select>
    <mat-error>{{ t('sessiondetails.error.exam_language_required') }}</mat-error>
  </mat-form-field>
</div>
