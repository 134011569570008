import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastComponent, ToastService, ToastServiceConfig } from '@pearsonvue/topaz-angular-ui';
import { IToastData } from '../../models/toastdata';
import { ToastConfigService } from '../../services/toast-config.service';

@Component({
  selector: 'app-toast-custom',
  templateUrl: './toast-custom.component.html',
  styleUrls: ['./toast-custom.component.scss']
})
export class ToastCustomComponent implements OnInit {

  @ViewChild(ToastComponent, { static: true }) public toast!: ToastComponent;

  data!: IToastData;
  isSessionAdded = false;

  constructor(private toastService: ToastService<IToastData>, private toastConfigService: ToastConfigService) {}

  ngOnInit(): void {
    this.toastConfigService.setDefaultConfig();
    this.data = this.toastService.getToastData(this.toast);
    if (this.data.disableCloseButton == undefined) {
      this.data.disableCloseButton = true;
    }
    this.isSessionAdded = this.data.metadata?.isSessionAdded
  }
}
