import { IExam } from "./exam";
import { IProgram } from "./program";

export interface IExamSelectionInfo {
  program: IProgram;
  exam: IExam;
  selected: boolean;
}

export interface ISelectedExam {
  tab: string;
  examInfo: IExamSelectionInfo;
  candidateCount: number;
}
