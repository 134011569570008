import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, map, Observable } from 'rxjs';
import { ConfigService } from 'src/app/services/config-service';


export const CC_ConfirmationTextId =  1;
 
export interface CommunicationPreferences_GetDto {
  communicationPreferencesId: number;
  phone: string;
  phoneCountryCode: number;
  cellPhone: number;
  cellPhoneCountryCode: number;
  marketingOptedIn: boolean;
  compassCloudTextoptIn: boolean;
  compassCloudSMSAgreementDate: string;
  compassCloudTextoptInDate: string;
  compassCloudTextoptOutDate: string;
  marketingOptInDate: string;
  marketingOptOutDate: string;
  smsOptInOptionSelections: SmsOptInOptionSelection_GetDto[];
}

export interface SmsOptInOptionSelection_GetDto {
  smsOptInOptionSelectionId: number;
  optionName: string;
  communicationPreferencesId: number;
  smsOptInOptionId: number;
  optIn: boolean;
}

export interface ICommunicationPreferenceRequestService {
  preferencesObservable: BehaviorSubject<CommunicationPreferences_GetDto|null>;
  errorObservable: BehaviorSubject<any>;
  getPreferencesEx(): void;
}

@Injectable({
  providedIn: 'root'
})
export class CommunicationPreferenceRequestService implements ICommunicationPreferenceRequestService {

  constructor(
      private httpClient: HttpClient
    , private configService: ConfigService
  ) { }

  preferencesObservable = new BehaviorSubject<CommunicationPreferences_GetDto|null>(null);
  errorObservable = new BehaviorSubject<any>(null);

  getPreferencesEx(): void {
    const url = `${this.configService.userManagementServiceUrl}/MyCommunicationPreferences`;
    this.httpClient.get<CommunicationPreferences_GetDto>(url, { withCredentials: true }).pipe(
      map(x => {
        this.preferencesObservable.next(x);
        this.errorObservable.next(null);
      }),
      catchError(error => {
        this.preferencesObservable.next(null);
        this.errorObservable.next(error.error);
        return EMPTY;
      })
    ).subscribe();
  }
}
