<div [formGroup]="form" *transloco="let t">
  <topaz-timepicker [label]="timePickerLabel" interval="5" [is24hrFormat]="is24hrFormat"
                    formControlName="timepicker" required="true" (click)="onTimepickerClick()"
                    (input)="onTimepickerInput()" #timepicker></topaz-timepicker>
  <mat-error class="timpicker-error" *ngIf="invalidFormat">
    {{ t('sessiondetails.error.time_invalid_format') }}
  </mat-error>
  <mat-error class="timpicker-error"
             *ngIf="!invalidFormat && timepickerFormCtrl?.touched && (timepickerFormCtrl?.status === 'INVALID' || timepickerFormCtrl?.hasError('required'))">
    {{ t('sessiondetails.error.time_required') }}
  </mat-error>
  
  <mat-radio-group class="topaz-radio-group-row" (change)="is24hrFormat = !is24hrFormat" [disabled]="timepickerFormCtrl?.status === 'DISABLED'">
    <mat-radio-button value="12" [checked]="!is24hrFormat && timepickerFormCtrl?.status !== 'DISABLED'">
      {{ t('sessiondetails.time.format.12hrFormat') }}
    </mat-radio-button>

    <mat-radio-button [value]="24">
      {{ t('sessiondetails.time.format.24hrFormat') }}
    </mat-radio-button>
  </mat-radio-group>
</div>
