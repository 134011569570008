import { Injectable } from '@angular/core';
import { IOutageInfo, ITimeConversionInput } from '../models/outage';
import { Observable, map } from 'rxjs';
import { OutagesService } from './outages.service';

@Injectable({
  providedIn: 'root'
})
export class OutageLogicService {

  constructor(private outagesService: OutagesService) { }

   isSessionTimeAllowed(sessionTimeInput: ITimeConversionInput, catcId: number, outageInfo: IOutageInfo): Observable<boolean> {
    return this.outagesService.ConvertTimeToUTC(sessionTimeInput)
      .pipe(
        
        map(tc => {
          const isWhitelistedCatc: boolean = outageInfo?.whitelist?.filter(
            catc =>
              catc.testCenterId === catcId.toString()
              && catc.isActive
          ).length > 0;

          if (isWhitelistedCatc)
            return true;

          return outageInfo?.outages?.filter(
            (o) =>
              o.outageStartDateTime <= tc.utcDateTime &&
              o.outageEndDateTime > tc.utcDateTime
          ).length == 0;
        })
      );
  }
}
