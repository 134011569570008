import { Injectable } from '@angular/core';

//Note: The purpose of this service is to wrap window.interval for the purpose of testing. This class does not need a unit test.

@Injectable({
  providedIn: 'root'
})
export class IntervalService {

  constructor() { }

  setInterval(handler: TimerHandler, timeout: number): number {
    return window.setInterval(handler, timeout);
  }

  clearInterval(intervalId: number): void {
    window.clearInterval(intervalId);
  }
}
