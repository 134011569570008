import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, AutoLogoutTimerService, AutoLogoutWarningDlgService, EnvironmentVariablesService, IUserDetail, TimeProviderService, UserDetailService } from '@certiport/login-library';
import { TranslocoService, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { TopazToastType } from '@pearsonvue/topaz-angular-ui/lib/components/toast/types/toast.type';
import { LanguagePickerService, SessionStorageService, Sidenav, SidenavService, UserService } from 'certiport-layout-library';
import { catchError, EMPTY, map, of, Subscription } from 'rxjs';
import { AutoLogoutRefreshService } from './services/auto-logout-refresh.service';
import { EditViewSidesheetService } from './services/edit-view-sidesheet.service';
import { SchedulerToastServiceService } from './services/scheduler-toast-service.service';
import { ToastConfigService } from './services/toast-config.service';
import { Title } from "@angular/platform-browser";
import { BreakpointObserver } from '@angular/cdk/layout';
import { LanguageAccessService } from './services/language-access.service';
import buildData from "build-data.json"
import { ConfigService } from './services/config-service';
import { AppInsightsService } from './services/app-insights.service';
import { SettingsCacheService } from './services/settings-cache.service';
import { SchedulingErrorCodeService } from './services/scheduling-error-code.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{
    provide: TRANSLOCO_SCOPE,
    useValue: 'layoutlib'
  }]
})
export class AppComponent implements OnInit, OnDestroy {

  title = 'SchedulingUI';
  displayName: string = '';
  timeoutSubscription: Subscription | null = null;
  warningSubscription: Subscription | null = null;
  tickSubscription: Subscription | null = null;
  logoutRequestSubscription: Subscription | null = null;
  newUserDetailSubscription: Subscription | null = null;
  millisecondsLeft: number = 0;//Diagnostics,
  showLogoutTimer: boolean = false;
  translocoSubscription: Subscription | null = null;
  showCookiePreferences!: boolean;
  webxChatURL!: string;
  showChatButton = true;
  settingsLoaded = false;
  private settingsCacheServiceSubscription: Subscription | null = null;
  sidenav: Sidenav = {
    heading: '',
    buttonName: '',
    showArrow: false
  };

  isMobile: boolean = false;
  constructor(
    private authService: AuthenticationService,
    environmentVariablesService: EnvironmentVariablesService,
    toastConfigService: ToastConfigService,
    private router: Router,
    private toastService: SchedulerToastServiceService,
    private userService: UserService,
    private autoLogoutTimerService: AutoLogoutTimerService,
    private autoLogoutWarningDlgService: AutoLogoutWarningDlgService,
    private t: TranslocoService,
    private editViewSidesheetService: EditViewSidesheetService,
    private userDetailService: UserDetailService,
    private timeProvider: TimeProviderService,
    private autoLogoutRefreshService: AutoLogoutRefreshService,
    private titleService: Title,
    private sidenavService: SidenavService,
    private breakpointObserver: BreakpointObserver,
    private languagePickerService: LanguagePickerService,
    private languageAccessService: LanguageAccessService,
    private activatedRoute: ActivatedRoute,
    private sessionStorage: SessionStorageService,
    private configService: ConfigService,
    private appInsightsService: AppInsightsService,
    private settingsCache: SettingsCacheService,
    private schedulingErrorCodeService: SchedulingErrorCodeService
  ) {

    environmentVariablesService.setRootUrl(this.configService.authenticationServiceUrl);
    environmentVariablesService.setPortalRootUrl(this.configService.portalRootUrl);
    environmentVariablesService.setAutoLogoutTickInterval(this.configService.autoLogoutTickInterval);

    toastConfigService.setDefaultConfig();
    this.showLogoutTimer = this.configService.showLogoutTimer;
    const languageCode: string | null = this.languagePickerService.getLanguageSelectedByUser();
    if (languageCode) {
      this.languageAccessService.setActiveLang(languageCode);
      this.languagePickerService.setLocale(languageCode);
    }

    this.showHideChatButton();

    this.translocoSubscription = this.t.selectTranslate('generic.schedulesession').subscribe(title => this.titleService.setTitle(title));
    this.showCookiePreferences = this.configService.showCookiePreferencesLink;
    this.webxChatURL = this.configService.webxChatURL;
    this.t.langChanges$.subscribe(language => {
      this.loadLoginLibraryTranslations(language);
      this.showHideChatButton();
      //uncomment this when schedulingUI needs to pass accountRecoveryUrl
      //environmentVariablesService.setAccountRecoveryUrl(`${this.configService.accountRecoveryUrl}?defaultlang=${language}`);
    });// need to optimize this after release of April 2024.
  }

  showHideChatButton() {

    this.showChatButton = true;

    const japanBrowserLanguageCodes = ['ja', 'ja-JP'];
    const selectedlanguage = this.sessionStorage.getItem('languageCode');

    if (selectedlanguage) { //user selected language takes precedence
      if (selectedlanguage == 'jpn') {
        this.showChatButton = false;
      }
    }
    else if (japanBrowserLanguageCodes.includes(navigator.language)) {
      this.showChatButton = false;
    }
  }

  ngOnInit(): void {
    // this.settingsCache.load().pipe(
    //   map(x => {
    //     this.settingsLoaded = x;
    //   }),
    //   catchError(x => {
    //     if (x != null) {
    //       this.schedulingErrorCodeService.showErrorCodeToast(x?.error?.errorCode, x?.error?.traceId);
    //     }
    //     return EMPTY;
    //   })
    // ).subscribe();


    console.log(`Build number: ${buildData.buildNumber}`);
    this.appInsightsService.logEvent(`Build number: ${buildData.buildNumber}`);

    this.breakpointObserver
      .observe('(max-width: 37.49rem)')
      .subscribe(value => this.isMobile = value.matches);

    this.sidenavService.sidenavSubject
      .subscribe(value => this.sidenav = value);

    this.userService.getUserName().subscribe(name => { this.displayName = name });
    this.setupAuthentication();
    this.storeSessionId();
  }

  ngOnDestroy(): void {
    this.translocoSubscription?.unsubscribe();
    this.tearDownAuthentication();
  }

  storeSessionId() {
    this.activatedRoute.queryParams.subscribe(p => {
      var sessionid = p['sessionid'];
      if (sessionid) {
        this.sessionStorage.setItem('sessionid', sessionid);
      }
    });
  }

  @HostListener('click', ['$event'])
  @HostListener('mouseover', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('scroll', ['$event'])
  @HostListener('window:focus', ['$event'])
  onEvent(event: any) {
    this.autoLogoutRefreshService.pokeUserEvent();
  }

  private setupAuthentication(): void {
    this.autoLogoutRefreshService.setup();

    this.timeoutSubscription = this.autoLogoutTimerService.timeout.subscribe(() => {
      this.toastService.clear();
      this.openWarnLogoutToast('warn', '', this.t.translate('logout.inactivity'));
      this.logOut();
    });

    this.logoutRequestSubscription = this.autoLogoutWarningDlgService.logoutRequest.subscribe(() => {
      this.logOut();
    });

    //Diagnostics
    if (this.showLogoutTimer) {
      this.tickSubscription = this.autoLogoutTimerService.tick.subscribe(() => {
        this.millisecondsLeft = this.autoLogoutTimerService.timeRemaining;
      });
    }
  }

  private tearDownAuthentication(): void {
    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe();
    }

    if (this.logoutRequestSubscription) {
      this.logoutRequestSubscription.unsubscribe();
    }

    //Diagnostics
    if (this.tickSubscription) {
      this.tickSubscription.unsubscribe();
    }

    this.autoLogoutRefreshService.teardown();
  }

  logOut() {
    this.autoLogoutTimerService.stop();

    this.authService.logout().pipe(
      map(() => {
        this.userDetailService.setUserDetail({ userId: 0, userName: '', userRoles: [], loginExpiry: new Date(0), portalUserSessionId: '' });
        localStorage.clear();
        this.editViewSidesheetService.closeSideSheets();
        this.router.navigate(['/login']);
      }),
      catchError(error => {
        this.openWarnLogoutToast('warn', this.t.translate('logout.logoutfailedheader'), this.t.translate('logout.logoutfailedcontent'));
        return of();
      }))
      .subscribe();
  }

  getUserName() {
    return this.userDetailService.getUserDetail() ? this.userDetailService.getUserDetail().userName : '';
  }

  public openWarnLogoutToast(toastDisplayType: TopazToastType, toastHeader: string, toastContent: string): void {
    this.toastService.open(toastDisplayType, toastHeader, toastContent);
  }

  getTextDirection(): string {
    return this.languagePickerService.getLanguageSelectedByUser() === 'ara' ? 'rtl' : 'ltr';
  }

  loadLoginLibraryTranslations(language: string) {
    this.t.load(`cptlogin/${language}`).subscribe();
  }
  getPrefrencesEnabled() {
    this.settingsCacheServiceSubscription = this.settingsCache.load().pipe(
      map(loaded => {
        this.settingsLoaded = loaded;
      }),
      catchError(error => {
        throw error;
      })
    ).subscribe();
    return this.settingsCache.smsPreferenceEnabled;
  }

}
