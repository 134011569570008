import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { ConfigService } from '../../../services/config-service';

@Component({
  selector: 'app-payment-definition',
  templateUrl: './payment-definition.component.html',
  styleUrl: './payment-definition.component.scss'
})
export class PaymentDefinitionComponent {

  closeLabel = "";
  userAssignedVoucherDesc = "";
  centerAssignedVoucherDesc = "";
  paymentMethodDefinitionTitle = "";

  public readonly icons = {
    faXmark
  };

  constructor(
    private config: ConfigService,
    public transloco: TranslocoService,
    public dialogRef: MatDialogRef<PaymentDefinitionComponent>) {

    this.setTranslocoTexts();
  }

  private setTranslocoTexts() {
    this.closeLabel = this.transloco.translate('generic.close');
    const clickHere = this.transloco.translate('generic.clickHere');
    this.paymentMethodDefinitionTitle = this.transloco.translate('paymentMethodDefinition.title');

    const userAssignedVoucherInfoUrl = `<a href="${this.config.userAssignedVoucherInfoUrl}" target="_blank">${clickHere}</a>`;
    const centerAssignedVoucherInfoUrl = `<a href="${this.config.centerAssignedVoucherInfoUrl}" target="_blank">${clickHere}</a>`;

    this.userAssignedVoucherDesc = this.transloco.translate('paymentMethodDefinition.userAssignedVoucherDesc', { userAssignedVoucherInfoUrl: userAssignedVoucherInfoUrl });
    this.centerAssignedVoucherDesc = this.transloco.translate('paymentMethodDefinition.centerAssignedVoucherDesc', { centerAssignedVoucherInfoUrl: centerAssignedVoucherInfoUrl });
  }
}
