import { Component } from '@angular/core';

@Component({
  selector: 'app-communication-preferences-edit',
  standalone: true,
  imports: [],
  templateUrl: './communication-preferences-edit.component.html',
  styleUrl: './communication-preferences-edit.component.scss'
})
export class CommunicationPreferencesEditComponent {

}
