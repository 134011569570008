import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { catchError, EMPTY, forkJoin, map, Subscription } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';

import { faArrowLeftFromLine, faArrowRightFromLine, IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import { TranslocoService } from '@jsverse/transloco';
import { BannerService, ToastService, TopazBannerData, TopazComponentCDKPortal } from '@pearsonvue/topaz-angular-ui';

import { ToastCustomComponent } from '../../toast-custom/toast-custom.component';
import { EmailConfirmationDialogComponent } from '../email-confirmation-dialog/email-confirmation-dialog.component';

import { IToastData } from '../../../models/toastdata';

import { SessionService } from 'src/app/services/session.service';
import { ToastConfigService } from '../../../services/toast-config.service';
import { SESSION_STATUS_CONFIRMED_ID } from '../../../app.constants';
import { HttpStatusCode } from '@angular/common/http';
import { SchedulingErrorCodeService } from '../../../services/scheduling-error-code.service';
import { PaymentService } from 'src/app/services/payment.service';
import { CreatesessiontoastService } from '../../create-session/create-session-services/createsessiontoast.service';
import { ICheckSlaExpirationsResponse } from 'src/app/models/check-slaexpirations';
import { LanguagePickerService } from 'certiport-layout-library';
import { PaymentTypesConstants } from 'src/app/payment-type-constants';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';
import { ExpiringSlaProgramsBannerComponent } from '../expiring-sla-programs-banner/expiring-sla-programs-banner.component';

export interface DialogFormData {
  email: string;
  emailConfirm: string;
}

export interface SessionMatCardSequence {
  recievesessionemailsSection: number;
  reviewconfirmationemailsSection: number;
  confirmsessionSection: number;
  startSessionSection: number;
  useraccessCodeSection?: number;
}

@Component({
  selector: 'app-session-confirmation',
  templateUrl: './session-confirmation.component.html',
  styleUrls: ['./session-confirmation.component.scss']
})

export class SessionConfirmationComponent implements OnInit, OnDestroy {

  public readonly icons = { faArrowLeftFromLine, faArrowRightFromLine };

  public dialogFormData?: DialogFormData;

  subscriptions: Subscription[] = [];

  sessionId = 0;
  sessionName = "";
  email = "";
  emailSucessfullySent = "";
  isEmailUpdated = false;
  showIcon:boolean=true;
  isSessionAddedToastShow: boolean = false;

  minStartSessionLeadTime!: number;
  isUserAssignedPayment: boolean = false;
  userAssignedSessionConfirmationMatCardSequence= {recievesessionemailsSection: 1, reviewconfirmationemailsSection: 2, useraccessCodeSection: 3, confirmsessionSection: 4, startSessionSection: 5} as SessionMatCardSequence
  sessionConfirmationMatCardSequence= {recievesessionemailsSection: 1, reviewconfirmationemailsSection: 2, confirmsessionSection: 3, startSessionSection: 4} as SessionMatCardSequence
  settingsLoaded: boolean = false;
  slaExpirations: ICheckSlaExpirationsResponse = { expirations: [] };
  public banners: TopazComponentCDKPortal[] = [];

  constructor(private dialog: MatDialog,
    private router: Router,
    private t: TranslocoService,
    private sessionService: SessionService,
    private toastService: ToastService<IToastData>,
    private toastConfigService: ToastConfigService,
    private settingsCache: SettingsCacheService,
    private schedulingErrorCodeService: SchedulingErrorCodeService,
    private paymentService: PaymentService,
    private createsessiontoastService: CreatesessiontoastService,
    private bannerService: BannerService,
    private languagePickerService: LanguagePickerService,
    ) {
  }

  ngOnInit(): void {
    this.initDirectBannerRoot();

    const innerWidth = window.innerWidth;
    if (innerWidth < 600) {
      this.showIcon = false;
    }
    this.initialize();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s?.unsubscribe());
  }

  initialize() {
    this.subscriptions.push(this.settingsCache.load().pipe(
      map(loaded => {
        this.minStartSessionLeadTime = this.settingsCache.minStartSessionLeadTime;
        this.settingsLoaded = loaded;
      })
    ).subscribe());

    this.sessionId = this.getSessionId();
    if (this.sessionId > 0) {
      const combinedSubscription$ = this.sessionService.getSession(this.sessionId)
        .pipe(
          map(session => {
            this.sessionName = session.sessionName;
            this.email = session.creatorEmail;
  
            this.setEmailSucessfullySentMessage();
  
            if (session.sessionStatusId == SESSION_STATUS_CONFIRMED_ID) {
              this.showSuccessToast(this.t.translate("sessionConfirmation.confirmedSessionAdded"), [], {});
              this.isSessionAddedToastShow = true;
            }
            else {
              this.showSuccessToast(
                this.t.translate("sessionConfirmation.Sessionadded"),
                [this.t.translate("sessionConfirmation.sessionsaved", { sessionName: this.sessionName })],
                { isSessionAdded: true });
              this.isSessionAddedToastShow = true;
            }
            if (this.isSessionAddedToastShow) {
              setTimeout(()=>{
                this.CheckSlaExpiration(session.testCenterId);
              },1000);
            }
            this.isUserAssignedPayment = session.paymentMethodName === PaymentTypesConstants.USER_ASSIGNED_VOUCHER ? true : false;
            this.sessionConfirmationMatCardSequence = this.isUserAssignedPayment ? this.userAssignedSessionConfirmationMatCardSequence : this.sessionConfirmationMatCardSequence;
          }),
          catchError((error) => {
            return this.handleError(error)
          })
        )
        .subscribe();

      this.subscriptions.push(combinedSubscription$);
    } else {
      this.showErrorToast();
    }
  }

  private showSuccessToast(header: string, contents: string[], metadata: any) {
    this.toastService.open(ToastCustomComponent, {
      toastType: 'success',
      header: header,
      contents: contents,
      metadata: metadata
    });
  }

  private getSessionId(): number {
    return +localStorage.getItem('sessionId')!;
  }

  private removeSessionId(): void {
    localStorage.removeItem('sessionId');
  }

  setEmailSucessfullySentMessage() {
    this.emailSucessfullySent = this.t.translate('session-confirmation.Emailsuccessfullysent', { email: this.email });
  }

  changeEmail() {
    const dialogRef = this.dialog.open(EmailConfirmationDialogComponent, {
      data: this.email,
      autoFocus: '#first-focus'
    });

    const dialogRefSubscription$ = dialogRef.afterClosed().subscribe((value: DialogFormData) => {
      if (value) {
        this.dialogFormData = value;
        this.email = this.dialogFormData.email;
        //set the email to update
        this.saveEmail();
      }
    });

    this.subscriptions.push(dialogRefSubscription$);
  }

  saveEmail() {
    const patchEmailSubscription$ = this.sessionService.patchEmailAddress(this.sessionId, this.email, true)
      .pipe(
        catchError((error) => {
          return this.handleError(error)
        })
      )
      .subscribe(_ => this.isEmailUpdated = true);

    this.subscriptions.push(patchEmailSubscription$);
  }

  resendConfirmationEmail() {
    this.sessionService.ResendCreateSessionEmails(this.sessionId)
      .pipe(
        catchError((error) => {
          return this.handleError(error)
        })
      )
      .subscribe(_ => {
        this.toastService.open(ToastCustomComponent, {
          toastType: 'success',
          header: this.t.translate("sessionConfirmation.EmailSent", { email: this.email }),
          contents: []
        });
      });
  }

  private CheckSlaExpiration(testCenterId: number) {
    this.paymentService.CheckSlaExpiration(testCenterId).subscribe({
      next: (response: ICheckSlaExpirationsResponse) => {
        this.slaExpirations = response;
        this.addSlaExpiryBanner();
      },
      error: (error) => {
        if (error.status >= HttpStatusCode.InternalServerError) {
          this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
        }
        else {
          this.createsessiontoastService.genericErrorToast;
        }
      }
    });
  }

  returnDashboard() {
    this.router.navigate(['/dashboard']);
  }

  private handleError(error: any) {
    if (error?.status == HttpStatusCode.InternalServerError) {
      this.schedulingErrorCodeService.showErrorCodeToast(error.error.errorCode, error.error.traceId);
    }
    else {
      this.showErrorToast();
    }

    return EMPTY;
  }

  private showErrorToast() {
    this.toastConfigService.setAutoDismissOff();

    this.toastService.open(ToastCustomComponent, {
      toastType: 'warn',
      header: this.t.translate("genericErrorHeader"),
      contents: [this.t.translate("genericErrorContent")],
      disableCloseButton: false
    });
  }

  private initDirectBannerRoot() {
    //this allows us to close the banners added to the direct topaz-banner-root
    this.subscriptions.push(
      this.bannerService.afterClosed.subscribe((b) => {
        const containsCustomBanner = this.banners.some((banner) => banner.id === b.id);
        if (containsCustomBanner) {
          this.onCustomBannerClose(b.id);
        }
      }));
  }
  private addSlaExpiryBanner() {
    if (this.slaExpirations?.expirations?.length > 0) {
      /**This is essentially creating a new ExpiringSlaProgramsBannerComponent but since we need to set the data, the code isn't straightforward.
       * We set the typeof data as an intersection of TopazBannerData and ICheckSlaExpirationsResponse.
       * This is then used to set the property or type ICheckSlaExpirationsResponse in the ExpiringSlaProgramsBannerComponent's ngOnInit.
       * Alternatively it could be set here like this: (newBanner.component as unknown as ExpiringSlaProgramsBannerComponent).slaExpirationsResponse = this.slaExpirations;
       * */
      let newBanner = new TopazComponentCDKPortal<TopazBannerData & ICheckSlaExpirationsResponse>(ExpiringSlaProgramsBannerComponent,
        { bannerType: 'alert', expirations: this.slaExpirations.expirations } as TopazBannerData & ICheckSlaExpirationsResponse);
      this.banners = [...this.banners, newBanner];
    }
  }
  private onCustomBannerClose(id?: string): void {
    this.banners = this.banners.filter((b) => b.id !== id);
  }

  isJapanese(): boolean {
    return this.t.getActiveLang().toLocaleUpperCase() === 'JPN' ? true : false;
  }

  getArrowIcon(): IconDefinition {
    return this.languagePickerService.getLanguageSelectedByUser() === 'ara' ? this.icons.faArrowRightFromLine : this.icons.faArrowLeftFromLine;
  }
}
