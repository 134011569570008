import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetailService } from '@certiport/login-library';
import { TranslocoService } from '@jsverse/transloco';
import { BannerService, ToastService, TopazBannerData, TopazBannerType, TopazComponentCDKPortal } from '@pearsonvue/topaz-angular-ui';
import { CtpDatePipe, LoaderService, SidenavService } from 'certiport-layout-library';
import { EMPTY, Subscription, catchError, map, tap } from 'rxjs';
import { IBannerData } from 'src/app/models/bannerdata';
import { AllowedRoles, ISession, ISessionScheduleList, SessionStatus } from 'src/app/models/session';
import { SessionStatusUpdateService } from 'src/app/services/session-status-update.service';
import { SessionValidationService } from 'src/app/services/session-validation.service';
import { SessionService } from 'src/app/services/session.service';
import { StartSessionService } from 'src/app/services/start-session.service';

import { IToastData } from '../../models/toastdata';
import { ToastConfigService } from '../../services/toast-config.service';
import { BannerCustomComponent } from '../banner-custom/banner-custom.component';
import { ToastCustomComponent } from '../toast-custom/toast-custom.component';
import { IOutageResponse } from 'src/app/models/outage-response';
import { format } from 'date-fns';
import { DateFormats } from 'src/app/app.constants';
import { TimeProviderService } from 'src/app/services/time-provider.service';
import { SchedulingErrorCodeService } from 'src/app/services/scheduling-error-code.service';
import { HttpStatusCode } from '@angular/common/http';
import { SessionsManagerService } from 'src/app/services/sessions-manager.service';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';
import { ITestCenterSignalR } from 'src/app/models/testcenter-signalr';
import { SignalRService } from 'src/app/services/signal-r.service';
import { SessionScheduleTableComponent } from './session-schedule-table/session-schedule-table.component';
import { SharedStateService } from 'src/app/services/shared-state.service';
import { SmsFeatureService } from '../preferences/services/sms-feature.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  sessionScheduleDataSource: ISessionScheduleList[] = [];
  nextSessionDataSource: ISessionScheduleList[] = [];
  private readonly allowedRoles: any = Object.values(AllowedRoles).filter((v) => !isNaN(Number(v)));
  userRoles: number[] = [];
  sessionName: string = '';
  loadSessionDataSubscription: Subscription | null = null;
  minStartSessionLeadTime: number = 0;
  maxStartSessionLateTime: number = 0;
  maxExamMinutes: number = 0;
  timeOutTrigger: any;
  bannerRefrence!: TopazComponentCDKPortal;
  isAnySessionExits: boolean = false;
  sidenavSubscription!: Subscription;
  settingsLoaded: boolean = false;
  private nextSessionSubscription: Subscription | null = null;
  private settingsCacheSubscription: Subscription | null = null;
  testCenterSignalR: ITestCenterSignalR[] = [];
  signalRIds: string[] = [];
  @ViewChild(SessionScheduleTableComponent) sessionScheduleTableComponent!: SessionScheduleTableComponent;

  constructor(private sessionService: SessionService,
    private toastService: ToastService<IToastData>,
    private transloco: TranslocoService,
    private route: Router,
    private sessionValidationService: SessionValidationService,
    private sessionStatusUpdateService: SessionStatusUpdateService,
    private toastConfigService: ToastConfigService,
    private loaderService: LoaderService,
    private timeProvider: TimeProviderService,
    private bannerService: BannerService<IBannerData>,
    private startSessionService: StartSessionService,
    private userDetailService: UserDetailService,
    private sidenavService: SidenavService,
    private schedulingErrorCodeService: SchedulingErrorCodeService,
    private sessionsManagerService: SessionsManagerService,
    private settingsCacheService: SettingsCacheService,
    private signalR: SignalRService,
    private ctpDatePipe: CtpDatePipe,
    private sharedStateService: SharedStateService,
    private smsFeatureService: SmsFeatureService) {
  }

  ngOnInit(): void {
    this.initSidenav();
    this.subscribeToSidenav();
    this.smsFeatureService.resetDialogRef();

    this.loaderService.showLoader();
    this.settingsCacheSubscription = this.settingsCacheService.load().pipe(
      map(loaded => {
        this.loaderService.hideLoader();
        this.settingsLoaded = loaded;
        this.initWithSettings();
        if (this.sharedStateService.getInvokeSmsSignupPopup()) {
          this.smsFeatureService.CheckIsUserOptedSmsFeature();
        }
      }),
      catchError(error => {
        this.loaderService.hideLoader();
        throw error;
      })
    ).subscribe();

    this.nextSessionSubscription = this.sessionsManagerService.nextSession.pipe(
      tap((session) => {
        if (session) {
          this.nextSessionDataSource = [session];
          this.SetNotificationForUpcomingSession(session);
        }
        else{
          this.nextSessionDataSource = [];
        }
      })
    ).subscribe();

    this.sessionsManagerService.nextStartableSessionChanged.pipe(
      tap((session) => {
        if (this.nextSessionDataSource.length > 0 && this.nextSessionDataSource[0].sessionId === session?.sessionId) {
          this.closeBanner();
          if (session && !session.disableStartSession) {
            this.openWarnBanner(session.sessionId);
          }
        }
      })
    ).subscribe();
    this.getTestCenterSignalR();
  }

  private initWithSettings(): void {
    this.getSettingsAndLoadData();
    this.loadSessionDataSubscription =
      this.sessionStatusUpdateService.loadSessionData.subscribe(() => this.getSessionScheduleData());

    this.sessionsManagerService.initiate();
  }

  ngAfterViewInit(): void {
    // Required to let translate service load.
    setTimeout(() => this.checkUpcomingOutages(), 500);
  }

  handleStartSession(sessionId: number) {
    this.loaderService.showLoader();
    let subscription: Subscription = this.startSessionService.startSession(sessionId).subscribe((session) => {
      this.markSessionStarted(session);
      subscription.unsubscribe();
    });
  }

  private markSessionStarted(session: ISession): void {
    for (let s of this.sessionScheduleDataSource) {
      if (s.sessionId == session.sessionId) {
        s.sessionStatus = SessionStatus.inprogress;
      }
    }
    this.loaderService.hideLoader();
  }

  initSidenav() {
    this.sidenavService.sidenavSubject
      .next({
        heading: 'session.dashboard',
        buttonName: 'nextSessionTableButton.createSession',
        showArrow: false
      });
  }

  subscribeToSidenav() {
    this.sidenavSubscription = this.sidenavService
      .buttonClickSubject
      .subscribe(_ => this.createSession());
  }

  checkUpcomingOutages() {
    const useIcon = (screen.width >= 425)? true : false;

    this.sessionService.getUpcomingOutages()
      .pipe(catchError((error) => {
        if (error.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
          this.schedulingErrorCodeService.showErrorCodeToast(error?.error?.errorCode, error?.error?.traceId);
        }
        return EMPTY
      }))
      .subscribe((resp: IOutageResponse[]) => {
        if (Array.isArray(resp) && resp.length > 0) {
          const formatFn = (strDate: string) => this.ctpDatePipe.transform(Date.parse(strDate), DateFormats.MONTH_DATE_TIME_TIMEZONE);
          const contents = resp.map(({ outageStartDateTime, outageEndDateTime }) =>
            (formatFn(outageStartDateTime) + ' - ' + formatFn(outageEndDateTime)));

          this.bannerService.open(BannerCustomComponent, {
            bannerType: 'primary',
            contents,
            contentStyle: 'list',
            header: this.transloco.translate('dashboard.outageBannerHeader'),
            useIcon: useIcon
          } as TopazBannerData & IBannerData);
        }
      });
  }

  getSessionScheduleData() {
    clearTimeout(this.timeOutTrigger);
    this.closeBanner();
    this.isAnySessionExits = false;
    let userId = this.userDetailService.getUserDetail().userId;
    this.userRoles = this.userDetailService.getUserDetail().userRoles;
    const isAllowedRole = this.userRoles.some((val) => this.allowedRoles.includes(val));
    let currentDateTime = new Date();
    currentDateTime.setMinutes(currentDateTime.getMinutes() - this.maxExamMinutes);
    const startDate = currentDateTime.toUTCString();
    if (isAllowedRole && userId) {
      this.getSessionSchedule(userId, startDate);
    }
  }

  getSessionSchedule(userID: number, startDate: string) {
    this.loaderService.showLoader();

    //TODO: Fix the deprecated subscribe call.
    this.sessionService.getSessionSchedule(userID, startDate).subscribe(responseData => {
      //Note: This would be better moved to a service--maybe the service that calls the server.
      this.cleanupDates(responseData);

      this.sessionScheduleDataSource = responseData.filter(c => c.sessionStatus !== SessionStatus.cancelled && c.sessionStatus !== SessionStatus.complete);
      this.MapSessionActivites();
      this.sessionsManagerService.sessions = this.sessionScheduleDataSource;

      if (this.sessionScheduleDataSource.length > 0)
        this.isAnySessionExits = true;

      this.loaderService.hideLoader();
    }, (error) => {
      if (error.status >= HttpStatusCode.InternalServerError && error?.error?.errorCode) {
        this.schedulingErrorCodeService.showErrorCodeBanner(error?.error?.errorCode, error?.error?.traceId);
      }
      else {
        this.showToast();
      }
      this.loaderService.hideLoader();
    });
  }

  cleanupDates(responseData: ISessionScheduleList[]): void {
    responseData.forEach((item: ISessionScheduleList) => {
      item.startDateTime = this.cleanupDate(item.startDateTime);
    });
  }

  cleanupDate(date: Date): Date {
    if (typeof date === 'string') {
      let t: string = date as unknown as string;
      if (!t.includes('Z')) {
        t += 'Z';
        date = new Date(Date.parse(t))
      }
      else {
        date = new Date(Date.parse(t))
      }
    }
    return date;
  }

  createSession() {
    this.route.navigate(['/new-session']);
  }

  private showToast() {
    this.toastConfigService.setAutoDismissOff();
    this.toastService.open(ToastCustomComponent, {
      toastType: 'warn',
      header: this.transloco.translate("genericErrorHeader"),
      contents: [this.transloco.translate("genericErrorContent")],
      disableCloseButton: false
    });
  }

  getSettingsAndLoadData() {
    this.MapSettingDataToValidationService();
    this.getSessionScheduleData();
  }

  MapSessionActivites() {
    this.sessionScheduleDataSource.forEach((item) => {
      item.disableCancelSession = this.sessionValidationService.shouldCancelSessionBeDisabled(item);
      item.disableConfirmSession = this.sessionValidationService.shouldConfirmSessionBeDisabled(item);
      item.disableStartSession = this.sessionValidationService.shouldStartSessionBeDisabled(item);
      item.disableEditSession = this.sessionValidationService.shouldEditSessionBeDisabled(item.sessionStatus, item.startDateTime)
      item.disableResumeSession = this.sessionValidationService.shouldResumeSessionBeDisabled(item);
    })
  }

  MapSettingDataToValidationService() {
    this.minStartSessionLeadTime = this.settingsCacheService.minStartSessionLeadTime;
    this.maxStartSessionLateTime = this.settingsCacheService.maxStartSessionLateTime;
    this.sessionValidationService.minConfirmSessionMinutes = this.settingsCacheService.minConfirmationLeadTime;
    this.sessionValidationService.maxConfirmSessionMinutes = this.settingsCacheService.maxConfirmSessionMinutes;
    this.sessionValidationService.minCancellationLeadTime = this.settingsCacheService.minCancellationLeadTime;
    this.sessionValidationService.minStartSessionLeadTime = this.settingsCacheService.minStartSessionLeadTime;
    this.sessionValidationService.maxStartSessionLateTime = this.settingsCacheService.maxStartSessionLateTime;
    this.sessionValidationService.maxEditTime = this.settingsCacheService.maxEditTime;
    this.maxExamMinutes = this.settingsCacheService.maxExamMinutes;
  }

  private SetNotificationForUpcomingSession(session: ISessionScheduleList) {

    let currentUctTime = this.timeProvider.currentTime();
    let sessionTiggerDateTime = new Date(session.startDateTime.valueOf() - this.minStartSessionLeadTime * 60 * 1000);
    const loginUserId = this.userDetailService.getUserDetail().userId;

    if (currentUctTime < sessionTiggerDateTime) {
      if (this.IsSessionStartDateToday(currentUctTime, sessionTiggerDateTime)) {
        const minutesToMillSeconds = this.minStartSessionLeadTime * 60 * 1000;
        this.timeOutTrigger = setTimeout(() => {
          this.getSessionScheduleData();
        }, minutesToMillSeconds)
      }
    } else if (session.sessionStatus === SessionStatus.confirmed && loginUserId == session.proctorId)
      this.openWarnBanner(session.sessionId);
  }

  private openWarnBanner(sessionId: number): void {
    this.bannerRefrence = this.bannerService.open(BannerCustomComponent, {
      bannerType: 'alert',
      header: this.transloco.translate("startSession.bannerHeader", { minStartSessionLeadTime: this.minStartSessionLeadTime }),
      contents: [],
      disableCloseButton: false,
      actionName: this.transloco.translate("startSession"),
      showActionButton: true,
      actionData: sessionId
    })
    this.startSession();
  }

  private startSession() {
    this.bannerService.afterClosed.subscribe({
      next: (response) => {
        if (response.id === 'startSession') {
          this.handleStartSession(response.data?.actionData);
        }
      },
      error: () => {
        this.showToast()
      }
    })
  }

  private closeBanner(): void {
    if (this.bannerRefrence) {
      this.bannerService.close(this.bannerRefrence.id);
    }
  }

  private IsSessionStartDateToday(currentUctTime: Date, sessionTiggerDateTime: Date) {
    return (currentUctTime.getDate() == sessionTiggerDateTime.getDate()
      && currentUctTime.getMonth() == sessionTiggerDateTime.getMonth()
      && currentUctTime.getFullYear() == sessionTiggerDateTime.getFullYear());
  }

  isLoggedInUserTheSessionProctor(proctorId: number) {
    this.userRoles = this.userDetailService.getUserDetail().userRoles;
    const loggedInUserId = this.userDetailService.getUserDetail().userId;
    const isLoggedInUserHasProctorRole = this.userRoles.includes(AllowedRoles.proctor);

    if (!isLoggedInUserHasProctorRole || loggedInUserId != proctorId) {
      return false;
    }

    return true;
  }

  private getTestCenterSignalR() {
    const userId = this.userDetailService.getUserDetail().userId;
    this.sessionService.getTestCenterSignalR(userId).subscribe({
      next: (response) => {
        this.testCenterSignalR = response;
        this.signalRIds = this.testCenterSignalR.map(t => t.signalRId);
        this.InvokeSignalR(this.signalRIds);
      },
      error: (errorResponse) => {
        if (errorResponse.status >= HttpStatusCode.InternalServerError && errorResponse?.error?.errorCode) {
          this.schedulingErrorCodeService.showErrorCodeToast(errorResponse?.error?.errorCode, errorResponse?.error?.traceId);
        }
        else {
          this.showToast();
        }
      }
    });
  }

  private InvokeSignalR(testCenterSignalRId: string[]) {
    this.signalR.startGroupIds(testCenterSignalRId)
      .then(() => {
        this.signalR.addCreateSessionListener((msg) => {
          this.onCreateSession(msg);
        }, testCenterSignalRId);
      });
  }

  private onCreateSession(msg: string): void {
    var sessionInfoString = JSON.stringify(msg);
    var sessionDetails: ISessionScheduleList = JSON.parse(sessionInfoString);;
    sessionDetails.startDateTime = this.cleanupDate(sessionDetails.startDateTime);
    this.sessionScheduleDataSource.push(sessionDetails);
    this.sessionScheduleDataSource.sort((a, b) => a.startDateTime.getTime() - b.startDateTime.getTime());
    this.MapSessionActivites();
    this.sessionScheduleTableComponent.getSessionSchedule();
  }

  ngOnDestroy(): void {
    this.sessionsManagerService.uninitiate();
    this.loadSessionDataSubscription?.unsubscribe();
    this.sidenavSubscription?.unsubscribe();
    this.settingsCacheSubscription?.unsubscribe();
    clearTimeout(this.timeOutTrigger);
    this.nextSessionSubscription?.unsubscribe();
    this.signalR.removeCreateSessionListener(this.onCreateSession)
    this.signalR.stop();
  }
}
