export interface IStepperInfo {
  stepper: Stepper,
  actionType: ActionType
}

export enum Stepper {
  SessionDetails,
  ExamDetails,
  ReviewAndSubmit
}

export enum ActionType {
  Next,
  Previous,
  Submit,
  CancelSession
}
