import { Component, OnInit, ViewChild } from '@angular/core';

import { ToastComponent, ToastService } from '@pearsonvue/topaz-angular-ui';

import { ToastConfigService } from 'src/app/services/toast-config.service';

import { InsufficientPaymentDetails } from 'src/app/models/insufficientpaymentdetails.toast';

@Component({
  selector: 'app-insufficient-payment-toast',
  templateUrl: './insufficient-payment-toast.component.html',
  styleUrls: ['./insufficient-payment-toast.component.scss']
})
export class InsufficientPaymentToastComponent implements OnInit {

  @ViewChild(ToastComponent, { static: true }) public toast!: ToastComponent;

  insufficientPaymentDetails!: InsufficientPaymentDetails;

  constructor(
    private toastService: ToastService<InsufficientPaymentDetails>,
    private toastConfigService: ToastConfigService) {
  }

  ngOnInit(): void {
    this.toastConfigService.setDefaultConfig();
    this.insufficientPaymentDetails = this.toastService.getToastData(this.toast);
  }

}