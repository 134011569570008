<div *transloco="let t">
  <header>
    <div>
      <h2 class="mainHeader">{{ t('emailConfirmationDialog.editEmailHeader') }}</h2>
    </div>
  </header>
  <main>
    <p class="mainParagraph">{{ t('emailConfirmationDialog.currentEmailAddress') }}&nbsp;&nbsp;<b>{{data}}</b></p>
    <p class="mainParagraph">
      {{ t('emailConfirmationDialog.changeYourEmail') }}
    </p>
    <form [formGroup]="editEmailForm">
      <mat-form-field appearance="outline">
        <mat-label class="mat-label">{{ t('emailConfirmationDialog.newEmail') }}</mat-label>
        <input type="text" matInput formControlName="email" placeholder="new-user@gmail.com" />
        <mat-error *ngIf="formControls.email?.errors?.['invalidMail'] && formControls.email?.errors?.['maxlength']">
          {{ t('emailConfirmationDialog.maxLengthError') }}
        </mat-error>
        <mat-error *ngIf="formControls.email?.errors?.['invalidMail'] && !formControls.email?.errors?.['maxlength']">
          {{ t('emailConfirmationDialog.validEmailError') }}
        </mat-error>
        <mat-error *ngIf="formControls.email?.errors?.['maxlength'] && !formControls.email?.errors?.['invalidMail']">
          {{ t('emailConfirmationDialog.maxLengthError') }}
        </mat-error>
        <mat-error *ngIf="formControls.email?.errors?.['required']">
          {{ t('emailConfirmationDialog.newEmailError') }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label class="mat-label">{{ t('emailConfirmationDialog.confirmEmail') }}</mat-label>
        <input type="text" matInput formControlName="emailConfirm" placeholder="new-user@gmail.com" />
        <mat-error *ngIf="formControls.emailConfirm?.errors?.['required'] && !formControls.emailConfirm?.errors?.['invalidValue'] && !formControls.emailConfirm?.errors?.['invalidMail'] && !formControls.emailConfirm?.errors?.['maxlength']">
          {{ t('emailConfirmationDialog.confirmEmailError') }}
        </mat-error>
        <mat-error *ngIf="!formControls.emailConfirm?.errors?.['required'] && formControls.emailConfirm?.errors?.['invalidValue'] && !formControls.emailConfirm?.errors?.['invalidMail'] && !formControls.emailConfirm?.errors?.['maxlength']">
          {{ t('emailConfirmationDialog.confirmEmailError') }}
        </mat-error>
        <mat-error *ngIf="!formControls.emailConfirm?.errors?.['required'] && !formControls.emailConfirm?.errors?.['invalidValue'] && formControls.emailConfirm?.errors?.['invalidMail'] && !formControls.emailConfirm?.errors?.['maxlength']">
          {{ t('emailConfirmationDialog.validEmailError') }}
        </mat-error>
        <mat-error *ngIf="!formControls.emailConfirm?.errors?.['required'] && formControls.emailConfirm?.errors?.['invalidValue'] && formControls.emailConfirm?.errors?.['invalidMail'] && !formControls.emailConfirm?.errors?.['maxlength']">
          {{ t('emailConfirmationDialog.confirmEmailError') }}
        </mat-error>
        <mat-error *ngIf="formControls.emailConfirm?.errors?.['required'] && !formControls.emailConfirm?.errors?.['invalidValue'] && formControls.emailConfirm?.errors?.['invalidMail'] && !formControls.emailConfirm?.errors?.['maxlength']">
          {{ t('emailConfirmationDialog.confirmEmailError') }}
        </mat-error>
        <mat-error *ngIf="formControls.emailConfirm?.errors?.['required'] && formControls.emailConfirm?.errors?.['invalidValue'] && !formControls.emailConfirm?.errors?.['invalidMail'] && !formControls.emailConfirm?.errors?.['maxlength']">
          {{ t('emailConfirmationDialog.confirmEmailError') }}
        </mat-error>
        <mat-error *ngIf="formControls.emailConfirm?.errors?.['maxlength']">
          {{ t('emailConfirmationDialog.maxLengthError') }}
        </mat-error>
      </mat-form-field>
      <div matDialogActions [align]="'end'" class="button">
        <button mat-button (click)="closeWindow()">{{ t('emailConfirmationDialog.close') }}</button>
        <button mat-flat-button color="primary" (click)="saveEmail()">{{ t('emailConfirmationDialog.sendEmail') }}</button>
      </div>
    </form>
  </main>
</div>

