import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfirmDialogData } from '../../models/confirmdialogdata';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  public readonly icons = {
    faXmark
  };

  yesButtonText!: string;
  noButtonText!: string;
  modaltitle!: string;
  modalDescription!:string
  constructor(public t: TranslocoService,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData) {
  }

  ngOnInit(): void {
    this.yesButtonText = this.t.translate("generic.yes");
    this.noButtonText = this.t.translate("generic.no");
  }
}
