import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { TopazBannerModule } from '@pearsonvue/topaz-angular-ui';
import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { CommunicationPreferencesViewComponent } from './communication-preferences-view/communication-preferences-view.component';
import { TextMsgPreferencesViewComponent } from "./text-msg-preferences-view/text-msg-preferences-view.component";
import { CommunicationPreferencesEditComponent } from './communication-preferences-edit/communication-preferences-edit.component';
import { SettingsCacheService } from 'src/app/services/settings-cache.service';
import { catchError, EMPTY, map } from 'rxjs';
import { SchedulingErrorCodeService } from 'src/app/services/scheduling-error-code.service';

@Component({
  selector: 'app-preferences',
  standalone: true,
  imports: [
    TranslocoRootModule,
    TopazBannerModule,
    MatCardModule,
    CommonModule,
    MatCardModule,
    MatButtonModule,
    CommunicationPreferencesViewComponent,
    CommunicationPreferencesEditComponent,
    TextMsgPreferencesViewComponent,
],
 templateUrl: './preferences.component.html',
  styleUrl: './preferences.component.scss'
})
export class PreferencesComponent implements OnInit {
  firstConfirmationTextMinutes = 0;
  secondConfirmationTextMinutes = 0;
  settingsLoaded = false;

  constructor(
    private settingsCache: SettingsCacheService,
    private schedulingErrorCodeService: SchedulingErrorCodeService
  ) {
  }

  ngOnInit(): void {
    this.settingsCache.load().pipe(
      map(x => {
        this.firstConfirmationTextMinutes = this.settingsCache.maxConfirmSessionMinutes;
        this.secondConfirmationTextMinutes = this.settingsCache.secondSessionConfirmationLeadTimeMinutes;
        this.settingsLoaded = x;
      }),
      catchError(x => {
        if (x != null) {
          this.schedulingErrorCodeService.showErrorCodeToast(x?.error?.errorCode, x?.error?.traceId);
        }
        return EMPTY;
      })
    ).subscribe();
  }

  inEditMode: boolean = false;

  handleClickEdit(): void {
    alert("Edit mode is temporarily disabled.");
    //this.inEditMode = true;
  }

  handleClickMainCancel(): void {
    this.inEditMode = false;
  }
}
