import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { catchError, map } from 'rxjs/operators'
import { AuthenticationService, AutoLogoutTimerService, IUserDetail, LoginStorageTokens, RefreshService, UserDetailService } from "@certiport/login-library";
import { EditViewSidesheetService } from "../../services/edit-view-sidesheet.service";
import { LanguagePickerService, SessionStorageService } from "certiport-layout-library";
import { TranslocoService } from "@jsverse/transloco";


@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    private refreshService: RefreshService,
    private authenticationService: AuthenticationService,
    private autoLogoutTimerService: AutoLogoutTimerService,
    private userDetailService: UserDetailService,
    private editViewSidesheetService: EditViewSidesheetService,
    private languagePickerService: LanguagePickerService,
    private transloco: TranslocoService,
    private sessionStorage: SessionStorageService
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    const sid = route.queryParamMap.get('sid');
    const langCode = route.queryParamMap.get('languagecode');
    if (sid) {
      if (langCode) {
        this.languagePickerService.setLocale(langCode.toLocaleLowerCase());
        this.transloco.setActiveLang(langCode.toLocaleLowerCase());
        this.sessionStorage.setItem('languageCode', langCode.toLocaleLowerCase());
      }
      else {
        this.languagePickerService.setLocale('enu');
        this.transloco.setActiveLang('enu');
      }
      return this.loginWithSid(sid, route);
    }
    return this.refreshService.RefreshToken().pipe(
      map(() => {
        return true;
      }),
      catchError((e, caught) => {
        localStorage.clear();
        this.userDetailService.setUserDetail(this.buildEmptyuserDetail())
        this.logout();
        this.router.navigate(['login']);
        return of(false);
      })
    )
  }

  private logout() {
    this.autoLogoutTimerService.stop();
    this.authenticationService.logout().pipe(
      map(() => {
        this.editViewSidesheetService.closeSideSheets();
        this.router.navigate(['/login']);
      }),
      catchError(error => {
        return of();
      }))
      .subscribe();
  }

  private buildEmptyuserDetail(): IUserDetail{
    return {
      userName: '',
      userId: 0,
      userRoles: [],
      loginExpiry: new Date(0)
    }
  }

  loginWithSid(sid: string, route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authenticationService.AuthenticateWithSid(sid).pipe(
      map(
        result => {
          if (result?.body?.RefreshToken) {
            localStorage.setItem(LoginStorageTokens.Refresh , result.body?.RefreshToken!)
            this.router.navigate([route.routeConfig?.path], {
              queryParams: { 'sid': null },
              queryParamsHandling: 'merge'
            });
            return true;
          }
          this.router.navigate(['login']);
          return false;
        }),
      catchError(() => {
        this.router.navigate(['login']);
        return of(false);
      }))
  }
}
