import { Pipe, PipeTransform } from '@angular/core';

//NOTE: This pipe is currently only used for diagnostic purposes. If it is to be used for more than diagnostic purposes, then it needs localization.
@Pipe({
  name: 'timeSpan'
})
export class TimeSpanPipe implements PipeTransform {

  transform(milliseconds: number): string {
    const hours = Math.floor(milliseconds / 3600000); //No modulus for hours. If over 24, don't convert to days' 
    const hoursText = (hours < 10) ? '0' + hours : hours;
    const minutes = Math.floor(milliseconds / 60000 % 60);
    const minutesText = ('00' + minutes).slice(-2);

    const seconds = Math.floor(milliseconds / 1000 % 60);
    const secondsText = ('00' + seconds).slice(-2);

    return `${hoursText}:${minutesText}:${secondsText}`;
  }
}
