<div class="pref-container" *transloco="let t;">

	<h1>{{ t("pref.preferences") }}</h1>

	@if (settingsLoaded)
	{
		@if (inEditMode) {
			<div>
				<topaz-banner bannerType="primary" useIcon disableScrolling>
					<div banner-content>
						<p>TODO: Enable the close button. (Use the service to show the banner.)</p>
						<b>{{ t("pref.phoneAndoptInMsg")}}</b>
					</div>
				</topaz-banner>
			</div>
		}
	
		@if (!inEditMode) {
			<app-communication-preferences-view
				[firstConfirmationTextMinutes]=firstConfirmationTextMinutes
				[secondConfirmationTextMinutes]=secondConfirmationTextMinutes></app-communication-preferences-view>
		}
		@else {
			<app-communication-preferences-edit></app-communication-preferences-edit>
		}
	}
</div>
