import { HttpClient, provideHttpClient } from '@angular/common/http';
import {
  Translation,
  TranslocoLoader,
  TranslocoModule,
  TranslocoTestingOptions,
  TranslocoTestingModule,
  provideTransloco
} from '@jsverse/transloco';
import { Inject, Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import enu from '../assets/i18n/enu.json';
import { APP_BASE_HREF } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, @Inject(APP_BASE_HREF) private baseHref: string) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`${this.baseHref}assets/i18n/${lang}.json`);
  }
}
export function getTranslocoModule(options: TranslocoTestingOptions = {}) {
  return TranslocoTestingModule.forRoot({
    langs: {
		enu
    },
    translocoConfig: {
      availableLangs: [
			'enu',
			'esm'
		],
      defaultLang: 'enu',
    },
    preloadLangs: true,
    ...options
  });
}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: ['ara', 'ell', 'enu', 'chs', 'ind', 'cht', 'fra', 'deu', 'jpn', 'kor', 'ptb', 'rom', 'esm', 'tha', 'trk', 'vie'],
        defaultLang: 'enu',
        fallbackLang: 'enu',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        interpolation: ['~{', '~}']
      },
      loader: TranslocoHttpLoader
    })
  ]
})
export class TranslocoRootModule {}
