import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastComponent, ToastService } from '@pearsonvue/topaz-angular-ui';
import { IExpiredSiteLicense } from '../../../models/expired-sitelicense';
import { ToastConfigService } from '../../../services/toast-config.service';

@Component({
  selector: 'app-expired-site-license-toast',
  templateUrl: './expired-site-license-toast.component.html',
  styleUrls: ['./expired-site-license-toast.component.scss']
})
export class ExpiredSiteLicenseToastComponent implements OnInit {
  @ViewChild(ToastComponent, { static: true }) public toast!: ToastComponent;

  expiredSiteLicense!: IExpiredSiteLicense;

  constructor(
    private toastService: ToastService<IExpiredSiteLicense>,
    private toastConfigService: ToastConfigService) {
  }

  ngOnInit(): void {
    this.toastConfigService.setDefaultConfig();
    this.expiredSiteLicense = this.toastService.getToastData(this.toast);
  }
}
