import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
  providedIn: 'root'
})
export class LanguageAccessService {

  constructor(private translocoService: TranslocoService) { }

  setActiveLang(lang: string): void {
    this.translocoService.setActiveLang(lang);
  }
}
