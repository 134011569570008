<div class="stepper-content" *transloco="let t">
  <div id="session-review-container">
    <h3 class="h3-header mt-1_4" autofocus>{{ t('createsession.review.session_summary') }}</h3>
    <p class="subtitle mb-1">{{ t('createsession.review.review_your_session_details') }}</p>

    <topaz-banner bannerType="alert" useIcon disableScrolling disableCloseButton>
      <div banner-content>
        <b>{{ bannerHeader }}</b>
        <br />
        <span class="topaz-banner-subcontent">{{ bannerContent }}</span>
      </div>
    </topaz-banner>

    <div>
      <mat-divider class="divider-1"></mat-divider>
    </div>

    <div id="session-review-session-details-container">
      <mat-card class="mat-elevation-z0">
        <mat-card-title>
          <div class="exam-details-title">
            <h5>{{ t('createsession.review.header.session_details') }}</h5>
            <div class="flex">
              <button mat-button color="primary" class="topaz-button-small" (click)="onSessionEdit()">{{ t('generic.edit') }}</button>
            </div>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div id="session-review-session-details-grid">
            <div>
              <h2 class="h2-header word-wrap">{{ t('generic.session_name') }}</h2>
              <p>{{ sessionForm.get('sessionName')?.value }}</p>
            </div>

            <div>
              <h2 class="h2-header word-wrap">{{ t('generic.test_center') }}</h2>
              <p>{{ sessionForm.get('testCenter')?.value?.testCenterID }} - {{ sessionForm.get('testCenter')?.value?.testCenterName }}</p>
            </div>

            <div>
              <h2 class="h2-header word-wrap">{{ t('generic.payment_method') }}</h2>
              <p>{{ sessionForm.get('paymentMethod')?.value?.displayName }}</p>
            </div>

            <div>
              <h2 class="h2-header word-wrap">{{ t('generic.exam_group_id') }}</h2>
              <p>{{ sessionForm.get('examGroupId')?.value }}</p>
            </div>

            <div>
              <h2 class="h2-header word-wrap">{{ t('generic.language') }}</h2>
              <p>{{ sessionForm.get('examLanguage')?.value?.languageName }}</p>
            </div>

            <div>
              <h2 class="h2-header word-wrap">{{ t('generic.proctor') }}</h2>
              <p>
                {{ sessionForm.get('proctor')?.value?.firstName }} {{ sessionForm.get('proctor')?.value?.lastName }}
                <span *ngIf="sessionForm.get('proctor')?.value?.userID === loggedInUserId"> ({{ t('createsession.sessiondetails.you') }})</span>
              </p>
            </div>

            <div>
              <h2 class="h2-header word-wrap">{{ t('generic.date_time') }}</h2>
              <p *ngIf="sessionForm.get('date')?.value">{{ sessionForm.get('date')?.value | ctpDate:'MMMM d, y' }} {{ getStartDateTime() | ctpDate:'h:mm a' }}</p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div>
      <mat-divider class="divider-1"></mat-divider>
    </div>

    <div id="session-review-exam-details-container">
      <h5 class="h5-header">{{ t('createsession.review.header.exam_details') }}</h5>

      <div id="session-review-exam-details-card-grid">
        <mat-card *ngFor="let eForm2 of examForms.controls; index as k" class="mat-elevation-z0">
          <mat-card-title>
            <div class="exam-details-title">
              <h6 class="h6-header">{{ t('createsession.examdetails.exam') }} {{ k + 1 }}</h6>
              <div class="flex">
                <button mat-button color="primary" class="topaz-button-small" (click)="onExamEdit(k)">{{ t('generic.edit') }}</button>
              </div>
            </div>
          </mat-card-title>
          <mat-card-content>
            <div class="session-review-exam-details-content-grid">
              <p>{{ eForm2.get('examSelection')?.value?.exam.examName }} {{ eForm2.get('examSelection')?.value?.exam.autoDeskVersion }}</p>
              <p>
                <span class="h2-header">{{ t('generic.no_of_candidates') }}: </span>
                <span *ngIf="isUserAssignedPaymentSelected">{{t('generic.not_applicable')}}</span>
                <span *ngIf="!isUserAssignedPaymentSelected">{{ eForm2.get('numCandidates')?.value }}</span>
              </p>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  </div>
