<div class="sms-container" *transloco="let t">

	<h2>{{ t("pref.textMsgPrefs") }}</h2>

	<div class="sms-table">
		<div class="sms-table-data-row">
			<div class="sms-table-data-cell">
				<div class="sms-reminder-heading">{{ t("pref.sessionConfirmReminderHeading") }}</div>
				<ul>
					<li>{{ t("pref.confirmationTimingNote", {confirmMsgHours: firstConfirmationTextHours}) }}</li>
					<li>{{ t("pref.lastConfirmationTimingNote", {lastConfirmMsgHours: secondConfirmationTextHours}) }}</li>
				</ul>
			</div>

			<div class="sms-table-data-cell">
				<mat-slide-toggle [aria-label]="t('pref.optInAria')" [disabled]="!inEditMode" [checked]="confirmationOptIn"></mat-slide-toggle>
			</div>
		</div>
	</div>

</div>
