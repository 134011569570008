import { Component, Input, ViewChild } from '@angular/core';
import { TimeSelectionComponent } from './time-selection/time-selection.component';

@Component({
  selector: 'app-exam-date-time-region',
  templateUrl: './exam-date-time-region.component.html',
  styleUrls: ['./exam-date-time-region.component.scss']
})
export class ExamDateTimeRegionComponent {
  @Input() maxDays!: number;
  @ViewChild(TimeSelectionComponent) timeSelectionComponent!: TimeSelectionComponent;
  constructor() { } 

}
